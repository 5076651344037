
import { defineComponent } from 'vue'
import { getConversionsColumns } from '@/pages/payments/config/columns'
import {
  request,
  getTableProperties,
  Table,
  TableRequestProps,
} from '@/utils/request'
import { mapMutations, mapState } from 'vuex'
import { FormatMoney } from 'format-money-js'
import { Currency } from '@/enums/Currency'
import { formatValue } from '@/utils'
import { heroOutline24ArrowDownTray } from 'quasar-extras-svg-icons/hero-icons-v2'
import { exportToCsv } from '@/utils/export'

export default defineComponent({
  name: 'PayoutLeadsDialog',
  props: ['open', 'close', 'payoutId'],
  setup() {
    return {
      heroOutline24ArrowDownTray,
    }
  },
  mounted() {
    if (!this.payoutLeads.leadIds.length) {
      this.update({ amount: 0 })
    }
    this.onRequest({ pagination: this.pagination })
  },
  computed: {
    ...mapState('payoutLeadsModule', ['payoutLeads']),
    showDialog(): boolean {
      return this.open
    },
  },
  data: function() {
    const moneyFormatter = new FormatMoney({
      decimals: 2,
      separator: ' ',
    })
    return {
      ...getTableProperties('createdAt', 10),
      loading: false,
      filters: {},
      selected: [],
      amount: 0,
      columns: getConversionsColumns(),
      moneyFormatter,
    }
  },
  methods: {
    ...mapMutations('payoutLeadsModule', ['update']),
    async onRequest(
      props: TableRequestProps | Omit<TableRequestProps, 'pagination'> = {},
    ) {
      const options = { pagination: this.pagination, ...props }
      await request(
        options,
        this as Table,
        `/api/payouts/${this.payoutId}/leads?currency=${this.payoutLeads.currency}`,
      )
    },
    saveSelectedLeadsAndClose() {
      this.update({
        leadIds: this.selected.map((l: any) => l.id),
        amount: this.amount,
      })
      this.$emit('close')
    },
    getCurrencyFromRow(currency: number) {
      return ` ${Currency[currency]}`
    },
    formatMoney(value: number) {
      return this.moneyFormatter.from(
        Number(formatValue(Number(value), 2, true)),
        {
          decimals: 2,
          separator: ' ',
        },
      )
    },
    onSelectLead(val: any) {
      if (val.added) {
        for (const row of val.rows) {
          this.amount += row.amount
        }
      } else {
        for (const row of val.rows) {
          this.amount -= row.amount
        }
      }
    },
    exportCsv() {
      exportToCsv(this.rows, this.columns)
    },
  },
})
