import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "column" }
const _hoisted_2 = { class: "flex justify-between items-center q-mb-lg" }
const _hoisted_3 = { class: "page-subtitle" }
const _hoisted_4 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_AddDomainDialog = _resolveComponent("AddDomainDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('domain')), 1),
        _createVNode(_component_q_btn, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showAddDomainDialog = !_ctx.showAddDomainDialog)),
          class: "q-btn__main-size text-weight-bold",
          label: "Add",
          color: "primary",
          unelevated: "",
          "icon-right": "add",
          "no-caps": ""
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_q_select, {
          "model-value": _ctx.campaign.redirectDomainId,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = redirectDomainId => _ctx.update({ redirectDomainId })),
          outlined: "",
          label: "Domain",
          "stack-label": "",
          dense: "",
          class: "col-12",
          options: _ctx.redirectDomains,
          onFilter: _ctx.getRedirectDomains,
          "option-value": "id",
          "option-label": "name",
          "emit-value": "",
          "map-options": "",
          "use-input": "",
          error: !!_ctx.errors?.redirectDomainId,
          "error-message": _ctx.$t(_ctx.errors?.redirectDomainId)
        }, null, 8, ["model-value", "options", "onFilter", "error", "error-message"])
      ])
    ]),
    _createVNode(_component_AddDomainDialog, {
      open: _ctx.showAddDomainDialog,
      onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showAddDomainDialog = false))
    }, null, 8, ["open"])
  ], 64))
}