import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "page-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_file = _resolveComponent("q-file")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.showDialog,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showDialog) = $event)),
    persistent: "",
    "full-height": "",
    position: "right",
    maximized: "",
    "full-width": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, { style: {"min-width":"350px"} }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, { class: "row justify-between items-center q-pa-32" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('create_ticket')), 1),
              _createVNode(_component_q_icon, {
                name: "close",
                onClick: _ctx.onClose,
                size: "20px",
                class: "cursor-pointer"
              }, null, 8, ["onClick"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, { class: "q-py-none" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_input, {
                label: _ctx.$t('theme'),
                "stack-label": "",
                dense: "",
                outlined: "",
                modelValue: _ctx.title,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.title) = $event)),
                placeholder: "theme...",
                autofocus: "",
                onKeyup: _withKeys(_ctx.onClose, ["esc"]),
                error: !!_ctx.errors?.name,
                "error-message": _ctx.errors?.name
              }, null, 8, ["label", "modelValue", "onKeyup", "error", "error-message"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, { class: "q-py-none" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_input, {
                label: _ctx.$t('message_text'),
                "stack-label": "",
                dense: "",
                outlined: "",
                class: "text-area-square",
                type: "textarea",
                modelValue: _ctx.message,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.message) = $event)),
                placeholder: "message...",
                autofocus: "",
                onKeyup: _withKeys(_ctx.onClose, ["esc"]),
                error: !!_ctx.errors?.name,
                "error-message": _ctx.errors?.name
              }, null, 8, ["label", "modelValue", "onKeyup", "error", "error-message"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_file, {
                modelValue: _ctx.file,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.file) = $event)),
                rounded: "",
                outlined: "",
                dense: "",
                clearable: "",
                "clear-icon": "close",
                class: "attach-file full-width q-mb-sm",
                label: _ctx.$t('attach_file'),
                "stack-label": ""
              }, {
                label: _withCtx(() => [
                  _createElementVNode("div", null, _toDisplayString(_ctx.$t('attach_file')), 1),
                  _createVNode(_component_q_icon, {
                    name: "add",
                    class: "on-right",
                    size: "16px"
                  })
                ]),
                _: 1
              }, 8, ["modelValue", "label"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_actions, {
            align: "center",
            class: "q-px-32 q-mt-md"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                disable: _ctx.loading,
                loading: _ctx.loading,
                "no-caps": "",
                unelevated: "",
                color: "primary",
                label: _ctx.$t('create'),
                onClick: _ctx.add,
                class: "full-width q-btn__main-size text-weight-bold"
              }, null, 8, ["disable", "loading", "label", "onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}