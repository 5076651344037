import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "page-title" }
const _hoisted_2 = { class: "page-subtitle" }
const _hoisted_3 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_vue_feather = _resolveComponent("vue-feather")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.showDialog,
    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.showDialog) = $event)),
    persistent: "",
    "full-height": "",
    position: "right",
    maximized: "",
    "full-width": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, {
        class: "campaign-url-card",
        style: {}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, { class: "row justify-between items-center q-pa-32" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('add_sids')), 1),
              _createVNode(_component_q_icon, {
                name: "close",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
                size: "20px",
                class: "cursor-pointer"
              })
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, { class: "q-pt-none q-mb-lg" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_input, {
                class: "q-py-xs",
                outlined: "",
                modelValue: _ctx.sid1,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sid1) = $event)),
                placeholder: "sub1"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_q_input, {
                class: "q-py-xs",
                outlined: "",
                modelValue: _ctx.sid2,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.sid2) = $event)),
                placeholder: "sub2"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_q_input, {
                class: "q-py-xs",
                outlined: "",
                modelValue: _ctx.sid3,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.sid3) = $event)),
                placeholder: "sub3"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_q_input, {
                class: "q-py-xs",
                outlined: "",
                modelValue: _ctx.sid4,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.sid4) = $event)),
                placeholder: "sub4"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_q_input, {
                class: "q-py-xs",
                outlined: "",
                modelValue: _ctx.sid5,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.sid5) = $event)),
                placeholder: "sub5"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_q_input, {
                class: "q-py-xs",
                outlined: "",
                modelValue: _ctx.sid6,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.sid6) = $event)),
                placeholder: "sub6"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_q_input, {
                class: "q-py-xs",
                outlined: "",
                modelValue: _ctx.sid7,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.sid7) = $event)),
                placeholder: "sub7"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, { class: "q-mb-md" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('link_campaign')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, { class: "q-pt-none" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_q_input, {
                  style: {"width":"400px"},
                  for: "campaign_url",
                  class: "link-text",
                  outlined: "",
                  readonly: "",
                  modelValue: _ctx.fullUrl,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.fullUrl) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", {
                class: "copy-btn",
                onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.copyUrl && _ctx.copyUrl(...args)))
              }, [
                _createVNode(_component_vue_feather, { type: "copy" }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('copy')), 1)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}