import { getOptions } from '@/utils'
import { Geo, Promo } from '@/store/types'
import axios from 'axios'
import { SemanticType } from '@/enums/SemanticType'

export async function lookupOffers(
  ref: any,
  value: string,
  update: Function,
  ids: number[] = [],
) {
  const offers = await getOptions(
    ref.offers as [],
    value,
    '/api/offers/list/',
    ids,
  )
  update(() => {
    ref.offers = offers
  })
}

export async function lookupTrafficProperties(
  ref: any,
  value: string,
  update: Function,
  ids: number[] = [],
) {
  const sids = await getOptions(
    ref.trafficProperties as [],
    value,
    '/api/traffic-properties',
    ids,
  )
  update(() => {
    ref.trafficProperties = sids
  })
}

export async function lookupGeos(
  ref: any,
  value: string,
  update: Function,
  filtering = false,
  byRewards = false,
  forLanding = false,
) {
  let geos = [] as unknown
  const apiPath = forLanding ? '/api/geos/main-landing' : '/api/geos'
  if (filtering) {
    geos = ref.defaultOptions.geos.filter((g: Geo) =>
      g.name.toLowerCase().includes(value.toLowerCase()),
    )
  } else if (ref.$route.path.includes('offers') || byRewards) {
    geos = await getOptions(ref.geos as [], value, apiPath, [], {
      byRewards: true,
    })
  } else {
    geos = await getOptions(ref.geos as [], value, apiPath)
  }
  update(() => {
    ref.geos = geos
  })
}

export async function lookupTrafficSources(
  ref: any,
  value: string,
  update: Function,
) {
  const params: { offerId: number; search?: string } = {
    offerId: ref.campaign.offerId,
  }
  if (value) {
    params.search = value
  }

  const trafficSources = (await axios.get('/api/traffic-sources', { params }))
    .data

  update(() => {
    ref.trafficSources = trafficSources
  })
}

export async function lookupRedirectDomains(
  ref: any,
  value: string,
  update: Function,
) {
  const redirectDomains = await getOptions(
    ref.redirectDomains as [],
    value,
    '/api/redirect-domains',
    [ref.campaign.redirectDomainId],
  )
  update(() => {
    ref.redirectDomains = redirectDomains.filter(
      (d: any) => d.id !== 1 && d.id !== 5,
    )
    if (!ref.campaign.redirectDomainId) {
      ref.campaign.redirectDomainId = redirectDomains[0]?.id
    }
  })
}

export async function lookupCampaigns(
  ref: any,
  value: string,
  update: Function,
) {
  const campaigns = await getOptions(
    ref.campaigns as [],
    value,
    '/api/campaigns',
  )
  update(() => {
    ref.campaigns = campaigns
  })
}

export async function lookupOfferCategories(
  ref: any,
  value: string,
  update: Function,
) {
  const categories = await getOptions(
    ref.offerCategories as [],
    value,
    '/api/offer-categories',
    [],
    { vertical: ref.filters.vertical },
  )
  update(() => {
    ref.offerCategories = categories
  })
}

export async function lookupSids(
  ref: any,
  value: string,
  update: Function,
  sidField: string,
) {
  const sids = await getOptions(ref[sidField] as [], value, '/api/sids', [], {
    sid: sidField,
  })
  update(() => {
    ref[sidField] = sids
  })
}

export async function lookupLandings(
  ref: any,
  value: string,
  update: Function,
  isLandingFilter: boolean,
) {
  const promos = await getOptions(ref.geos as [], value, '/api/promos')
  if (!isLandingFilter) {
    update(() => {
      ref.preLandings = promos.filter(
        (promo: Promo) => promo.semanticType === SemanticType.Prelanding,
      )
    })
    return
  }
  update(() => {
    ref.landings = promos.filter(
      (promo: Promo) => promo.semanticType === SemanticType.Landing,
    )
  })
}

export async function lookupMobileApps(
  ref: any,
  value: string,
  update: Function,
  ids: number[] = [],
) {
  let route = ref.$route.path.includes('apps') ? '/api/apps' : '/api/apps/list'
  const isSharing = ref.$route.path.includes('sharing')
  if (isSharing) {
    route += '?sharing=true'
  }
  const apps = await getOptions(ref.apps as [], value, route, ids)
  update(() => {
    ref.apps = apps
  })
}

export async function lookupAppsGeos(
  ref: any,
  value: string,
  update: Function,
  filtering = false,
  extraParams = {},
) {
  let geos = [] as unknown
  if (filtering) {
    geos = ref.defaultOptions.geos.filter((g: Geo) =>
      g.name.toLowerCase().includes(value.toLowerCase()),
    )
  } else if (ref.$route.path.includes('apps')) {
    geos = await getOptions(ref.geos as [], value, '/api/geos/apps', [], {
      byRewards: true,
    })
  } else {
    geos = await getOptions(
      ref.geos as [],
      value,
      '/api/geos/apps',
      [],
      extraParams,
    )
  }
  update(() => {
    ref.geos = geos
  })
}

export async function lookupAppsTrafficSources(
  ref: any,
  value: string,
  update: Function,
) {
  const params: any = {}
  if (ref.campaign?.mobileAppId) {
    params.mobileAppId = ref.campaign.mobileAppId
  }
  if (value) {
    params.search = value
  }

  const trafficSources = (
    await axios.get('/api/traffic-sources/apps', { params })
  ).data

  update(() => {
    ref.trafficSources = trafficSources
  })
}

export async function lookupAppsCampaigns(
  ref: any,
  value: string,
  update: Function,
) {
  const campaigns = await getOptions(
    ref.campaigns as [],
    value,
    '/api/apps-campaigns',
  )
  update(() => {
    ref.campaigns = campaigns
  })
}

export async function lookupTrafficSourceNames(
  ref: any,
  value: string,
  update: Function,
) {
  const params: any = {}
  if (value) {
    params.search = value
  }

  const trafficSources = (
    await axios.get('/api/traffic-sources/list', { params })
  ).data

  update(() => {
    ref.trafficSourceNames = trafficSources
  })
}
