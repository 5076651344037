
import { defineComponent } from 'vue'
import DatePicker from '../date-picker/DatePicker.vue'
import FiltersDialog from '../dialogs/FiltersDialog.vue'
import axios from 'axios'
import {
  lookupCampaigns,
  lookupGeos,
  lookupLandings,
  lookupOfferCategories,
  lookupOffers,
  lookupSids,
  lookupTrafficProperties,
} from '@/utils/lookups'
import {
  getFiltersData,
  initFilterOptions,
  initOptionsByRouteParams,
} from '@/utils/filters'
import { groupByOptions } from '@/pages/statistics/config/groupByOptions'
import { lookupTrafficSourceNames } from '@/utils/lookups'
import {
  heroOutline24ChevronDown,
  heroOutline24ArrowPath,
  heroOutline24AdjustmentsVertical,
  heroOutline24Check,
} from 'quasar-extras-svg-icons/hero-icons-v2'

export default defineComponent({
  name: 'Filters',
  components: { DatePicker, FiltersDialog },
  props: ['reset', 'currentFilters', 'change', 'changeAll', 'export', 'tab'],
  setup() {
    return {
      heroOutline24ChevronDown,
      heroOutline24ArrowPath,
      heroOutline24AdjustmentsVertical,
      heroOutline24Check,
    }
  },
  async created() {
    if (this.$route.query.campaignId) {
      this.campaigns = (await axios.get('/api/campaigns')).data
    }
    if (this.$q.screen.width < 600) {
      await initFilterOptions(this)
    } else {
      await initOptionsByRouteParams(this)
    }
  },
  computed: {
    getGroupByOptions() {
      return groupByOptions()
    },
    hideForStatistics(): boolean {
      return !this.$route.path.includes('statistics')
    },
    hideForPayments(): boolean {
      return !this.$route.path.includes('payments')
    },
    hideForConversions(): boolean {
      return !this.$route.path.includes('conversions')
    },
    hideForCampaigns(): boolean {
      return !this.$route.path.includes('campaigns')
    },
    hideForOffers(): boolean {
      return !this.$route.path.includes('offers')
    },
    hideForPostbacks(): boolean {
      return !this.$route.path.includes('postbacks')
    },
    hideForClickLogs(): boolean {
      return this.tab !== 'clicks'
    },
    hideForLogs(): boolean {
      return !this.$route.path.includes('logs')
    },
  },
  data: function() {
    return {
      filters: { ...this.currentFilters },
      showFiltersDialog: false,
      ...getFiltersData(),
    }
  },
  watch: {
    search() {
      this.onChangeFilters('name', this.search)
    },
    currentFilters() {
      this.filters = { ...this.currentFilters }
      this.search = this.currentFilters.name
    },
  },
  methods: {
    resetFilters() {
      this.search = null
      this.$emit('reset')
    },
    onChangeFilters(field: string, value: string | null) {
      this.filters[field] = value
    },
    submitAll(mobileFilters?: any) {
      if (mobileFilters?.dateFrom) {
        this.filters = { ...mobileFilters }
      }
      this.$emit('changeAll', this.filters)
    },
    async getOffers(value: string, update: Function) {
      await lookupOffers(this, value, update)
    },
    async getTrafficProperties(value: string, update: Function) {
      await lookupTrafficProperties(this, value, update)
    },
    async getCampaigns(value: string, update: Function) {
      await lookupCampaigns(this, value, update)
    },
    async getGeos(value: string, update: Function) {
      await lookupGeos(this, value, update)
    },
    async getOfferCategories(value: string, update: Function) {
      await lookupOfferCategories(this, value, update)
    },
    async getSids(value: string, update: Function, sidField: string) {
      await lookupSids(this, value, update, sidField)
    },
    async getTrafficSources(value: string, update: Function) {
      await lookupTrafficSourceNames(this, value, update)
    },
    async getLandings(
      value: string,
      update: Function,
      isLandingFilter: boolean,
    ) {
      await lookupLandings(this, value, update, isLandingFilter)
    },
    onChangeDate(date: { startDate: string; endDate: string }) {
      this.filters.dateFrom = date.startDate
      this.filters.dateTo = date.endDate
    },
  },
})
