import { formatDate } from '@/utils'
import i18n from '@/locales/i18n'

export default function getColumns() {
  return [
    { name: 'id', label: 'ID', field: 'id', align: 'left', sortable: true },
    {
      name: 'createdAt',
      label: i18n.t('date'),
      field: 'createdAt',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'offerName',
      label: i18n.t('offer'),
      field: 'offerName',
      align: 'left',
    },
    {
      name: 'name',
      label: i18n.t('campaign_name'),
      field: 'name',
      align: 'left',
      sortable: true,
    },
    { name: 'url', label: i18n.t('link'), field: 'url', align: 'left' },
    {
      name: 'actions',
      label: i18n.t('actions'),
      field: 'actions',
      align: 'left',
    },
  ]
}
