
import { defineComponent } from 'vue'

import {
  heroOutline24Bell,
  heroOutline24CheckCircle,
} from 'quasar-extras-svg-icons/hero-icons-v2'
import { NotificationEventType } from '@/enums/NotificationEventType'
import { NotificationType } from '@/enums/NotificationType'
import axios from 'axios'
import { formatDate } from '@/utils'

export default defineComponent({
  name: 'HeaderNotifications',
  setup() {
    return {
      heroOutline24Bell,
      heroOutline24CheckCircle,
    }
  },
  data: () => {
    return {
      notificationEventType: NotificationEventType,
      notificationType: NotificationType,
      notifications: [],
      closeNotificationsMenu: false,
    }
  },
  async created(): Promise<void> {
    this.notifications = (await axios.get('/api/notifications')).data
  },
  methods: {
    async readOne(notificationId: number) {
      await axios.post('/api/notifications/' + notificationId)
      this.notifications = (await axios.get('/api/notifications')).data
    },
    async readAll() {
      await axios.post('/api/notifications/all')
      this.notifications = (await axios.get('/api/notifications')).data
    },
    closeMenu() {
      this.closeNotificationsMenu = true
      setTimeout(() => {
        this.closeNotificationsMenu = false
      }, 400)
    },
    format(val: string) {
      return formatDate(val)
    },
  },
})
