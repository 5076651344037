import { PushNotificationState } from '@/store/types'

function getDefaultData(): PushNotificationState {
  return {
    pushNotification: {
      title: null,
      message: null,
      mobileAppId: null,
      sendingRules: [],
      individualRules: [],
      isTurnedOn: true,
      geoIds: [],
      backgroundImage: null,
      previewImage: null,
      smallIcon: null,
    },
    errors: {},
  }
}

export default getDefaultData
