
import { defineComponent } from 'vue'
import {
  getTableProperties,
  request,
  Table,
  TableRequestProps,
} from '@/utils/request'
import getColumns from './getColumns'
import AddDomainDialog from '@/components/dialogs/AddDomainDialog.vue'
import AddDomain from '@/pages/redirect-domains/components/AddDomain.vue'
import { formatDate } from '@/utils'
export default defineComponent({
  name: 'RedirectDomains',
  components: { AddDomain },
  async created() {
    await this.onRequest({ pagination: this.pagination })
  },
  computed: {
    columns(): any {
      return getColumns()
    },
  },
  data() {
    return {
      ...getTableProperties('id'),
      showAddDomainDialog: false,
    }
  },
  methods: {
    async onRequest(props: TableRequestProps) {
      const options = props || { pagination: this.pagination }
      await request(options, this as Table, '/api/redirect-domains/affiliate/')
    },
    format(val: string) {
      return formatDate(val)
    },
  },
})
