import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SignUpHeader = _resolveComponent("SignUpHeader")!
  const _component_SignUpContentHeader = _resolveComponent("SignUpContentHeader")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_page = _resolveComponent("q-page")!
  const _component_q_page_container = _resolveComponent("q-page-container")!

  return (_openBlock(), _createBlock(_component_q_page_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_SignUpHeader),
      _createVNode(_component_q_page, { class: "q-layout-container column justify-center items-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_q_form, {
            onSubmit: _ctx.submit,
            class: "sign-form column"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_SignUpContentHeader),
              _createVNode(_component_q_input, {
                outlined: "",
                dense: "",
                label: "E-mail",
                "stack-label": "",
                "bg-color": "white",
                modelValue: _ctx.email,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                type: "email",
                onFocus: _ctx.resetErrors,
                error: _ctx.loginError
              }, null, 8, ["modelValue", "onFocus", "error"]),
              _createVNode(_component_q_input, {
                outlined: "",
                dense: "",
                "bg-color": "white",
                type: _ctx.hidePassword ? 'password' : 'text',
                class: "q-mt-xs",
                label: _ctx.$t('password'),
                "stack-label": "",
                onFocus: _ctx.resetErrors,
                error: _ctx.loginError,
                "error-message": _ctx.$t('validation_text_email_password'),
                modelValue: _ctx.password,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.password) = $event))
              }, {
                append: _withCtx(() => [
                  _createVNode(_component_q_icon, {
                    name: _ctx.hidePassword ? _ctx.heroOutline24EyeSlash : _ctx.heroOutline24Eye,
                    class: "cursor-pointer",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hidePassword = !_ctx.hidePassword))
                  }, null, 8, ["name"])
                ]),
                _: 1
              }, 8, ["type", "label", "onFocus", "error", "error-message", "modelValue"]),
              _createVNode(_component_router_link, {
                to: "/reset-password",
                class: "col forgot-password-text"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('forgot_password')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_q_btn, {
                class: "text-weight-bold q-btn__main-size",
                label: _ctx.$t('enter2'),
                type: "submit",
                color: "primary",
                "no-caps": "",
                loading: _ctx.loading,
                unelevated: "",
                rounded: ""
              }, null, 8, ["label", "loading"])
            ]),
            _: 1
          }, 8, ["onSubmit"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}