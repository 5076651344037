
import axios from 'axios'
import { defineComponent } from 'vue'
import { showErrorMessageSnackBar } from '@/utils'
import i18n from '@/locales/i18n'

export default defineComponent({
  name: 'CreateTicketDialog',
  props: ['open', 'close', 'refresh'],
  computed: {
    showDialog(): boolean {
      return this.open
    },
  },
  data: function() {
    return {
      title: null,
      message: null,
      file: null,
      errors: {},
      loading: false,
    }
  },
  methods: {
    async add() {
      this.loading = true
      try {
        const messageId = (
          await axios.post('/api/tickets', {
            ticket: { title: this.title },
            message: { text: this.message },
          })
        ).data
        if (this.file) {
          const form = new FormData()
          form.append('image', this.file as any)
          await axios.post(`/api/tickets/${messageId}/image`, form)
        }
        this.onClose()
        this.$emit('refresh')
      } catch (error) {
        showErrorMessageSnackBar(i18n.t('text_message_specify'))
      }
      this.loading = false
    },
    onClose() {
      this.title = null
      this.message = null
      this.file = null
      this.errors = {}
      this.$emit('close')
    },
  },
})
