
import { defineComponent } from 'vue'
import Filters from '@/components/filters/Filters.vue'
import ConversionsTable from './ConversionsTable.vue'
import { FiltersType, getDefaultFilters } from '@/utils/request'
import { Vertical } from '@/enums/Vertical'
import { heroOutline24ArrowDownTray } from 'quasar-extras-svg-icons/hero-icons-v2'

export default defineComponent({
  name: 'Conversions',
  components: { Filters, ConversionsTable },
  created() {
    if (Object.keys(this.$route.params).length) {
      const paramsFilters = this.$route.params
      for (const [key, value] of Object.entries(paramsFilters)) {
        if (Number(value) || Number(value) === 0) {
          paramsFilters[key] = (Number(value) as unknown) as string
        }
      }
      this.filters = { ...this.filters, ...paramsFilters }
    }
  },
  setup() {
    return {
      heroOutline24ArrowDownTray,
    }
  },
  data: function() {
    const statsTab =
      Number(this.$route.params.vertical) === Vertical.Gambling
        ? 'gambling'
        : Number(this.$route.params.vertical) === Vertical.Crypto
        ? 'crypto'
        : 'general'
    return {
      exportCsv: false,
      statsTab,
      filters: { ...getDefaultFilters() },
    }
  },
  watch: {
    statsTab() {
      this.resetFilters()
    },
  },
  methods: {
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters } as any
    },
    resetFilters() {
      this.filters = { ...getDefaultFilters() }
    },
    onExport() {
      this.exportCsv = !this.exportCsv
    },
    backToStats() {
      this.$router.push({
        name: 'statistics',
        params: {
          ...JSON.parse(this.$route.params.filters as any),
          ...('vertical' in this.$route.params
            ? { vertical: this.$route.params.vertical }
            : {}),
        },
      })
    },
  },
})
