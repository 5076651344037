import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between background-gray section-container q-py-lg" }
const _hoisted_2 = { class: "page-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_AddPaymentSystemDialog = _resolveComponent("AddPaymentSystemDialog")!
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_Payouts = _resolveComponent("Payouts")!
  const _component_q_tab_panel = _resolveComponent("q-tab-panel")!
  const _component_Transactions = _resolveComponent("Transactions")!
  const _component_PaymentSystemAccounts = _resolveComponent("PaymentSystemAccounts")!
  const _component_RevShare = _resolveComponent("RevShare")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!
  const _component_CreatePayoutDialog = _resolveComponent("CreatePayoutDialog")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-mb-lg" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('payouts')), 1),
        (_ctx.paymentsTab === 'systems')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_q_btn, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showAddPaymentSystemDialog = true)),
                "no-caps": "",
                unelevated: "",
                label: _ctx.$t('add_pay_method'),
                color: "primary",
                class: "q-btn__main-size text-weight-bold"
              }, null, 8, ["label"]),
              _createVNode(_component_AddPaymentSystemDialog, {
                open: _ctx.showAddPaymentSystemDialog,
                onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showAddPaymentSystemDialog = false))
              }, null, 8, ["open"])
            ], 64))
          : (_openBlock(), _createBlock(_component_q_btn, {
              key: 1,
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showCreatePayoutDialog = true)),
              disable: _ctx.isPayoutDisabled,
              "no-caps": "",
              unelevated: "",
              label: _ctx.$t('order_payout'),
              color: "primary",
              class: "q-btn__main-size text-weight-bold"
            }, null, 8, ["disable", "label"]))
      ]),
      _createVNode(_component_q_tabs, {
        modelValue: _ctx.paymentsTab,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.paymentsTab) = $event)),
        "inline-label": "",
        breakpoint: 0,
        align: "justify",
        class: "q-py-lg section-container"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_tab, {
            "no-caps": "",
            name: "payouts",
            label: _ctx.$t('payouts')
          }, null, 8, ["label"]),
          _createVNode(_component_q_tab, {
            "no-caps": "",
            name: "transactions",
            label: _ctx.$t('history')
          }, null, 8, ["label"]),
          _createVNode(_component_q_tab, {
            "no-caps": "",
            name: "systems",
            label: _ctx.$t('pay')
          }, null, 8, ["label"]),
          _createVNode(_component_q_tab, {
            "no-caps": "",
            name: "revshare",
            label: "RevShare"
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_q_tab_panels, {
        modelValue: _ctx.paymentsTab,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.paymentsTab) = $event)),
        animated: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_tab_panel, {
            name: "payouts",
            class: "q-pa-none"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_component_Payouts, {
                key: _ctx.payoutsComponentKey,
                onPayoutDisabledStatusChanged: _ctx.onPayoutDisabledStatusChanged
              }, null, 8, ["onPayoutDisabledStatusChanged"]))
            ]),
            _: 1
          }),
          _createVNode(_component_q_tab_panel, { name: "transactions" }, {
            default: _withCtx(() => [
              _createVNode(_component_Transactions)
            ]),
            _: 1
          }),
          _createVNode(_component_q_tab_panel, { name: "systems" }, {
            default: _withCtx(() => [
              _createVNode(_component_PaymentSystemAccounts)
            ]),
            _: 1
          }),
          _createVNode(_component_q_tab_panel, { name: "revshare" }, {
            default: _withCtx(() => [
              _createVNode(_component_RevShare)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      (_ctx.showCreatePayoutDialog)
        ? (_openBlock(), _createBlock(_component_CreatePayoutDialog, {
            key: 0,
            open: _ctx.showCreatePayoutDialog,
            onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showCreatePayoutDialog = false)),
            onRefresh: _ctx.refreshPayouts
          }, null, 8, ["open", "onRefresh"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}