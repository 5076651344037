import { CampaignState } from '@/store/types'
import { CampaignStatus } from '@/enums/CampaignStatus'

function getDefaultData(): CampaignState {
  return {
    campaign: {
      name: null,
      affiliateId: null,
      offerId: null,
      offer: null,
      trafficSourceId: null,
      redirectDomainId: null,
      targetPromoIds: [],
      geoId: null,
      metrics: [],
      trafficProperties: [],
      status: CampaignStatus.Active,
      postbacks: [],
    },
    errors: {},
  }
}

export default getDefaultData
