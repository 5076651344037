const locales = {
  ru: {
    select_leads_for_payout: 'Выбор лидов для выплаты',
    all_sum: 'Вся сумма',
    select_leads: 'Выбрать лиды',
    payout_sum: 'Сумма выплаты',
    landing_name: 'Название лендинга',
    open_link: 'Открыть ссылку',
    conditions: 'Условия',
    terms: 'Описание',
    dashboard: 'Дашборд',
    timezone: 'Часовой пояс',
    enter3: 'Начать',
    marketing_network: 'ЭФФЕКТИВНАЯ ПАРТНЕРСКАЯ СЕТЬ',
    show_more2: 'Подробнее',
    best_offers_diff_geo: 'Лучшие Офферы, Разнообразные Гео',
    mindep: 'миндеп',
    baseline: 'бейслайн',
    fact_stats: 'Фактические данные',
    fact_stats_tooltip:
      'Фактическая статистика показывает депозиты в дни когда они реально были сделаны. Историческая статистика показывает регистрации и депозиты на день клика.',
    choose_offer: 'Выбор оффера',
    choose_campaign: 'Выбор кампании',
    choose_geo: 'Выбор страны',
    choose_sid: 'Выбор метки',
    choose_landing: 'Выбор ленда',
    apply: 'Применить',
    today: 'Сегодня',
    yesterday: 'Вчера',
    current_week: 'Текущая неделя',
    last_week: 'Прошлая неделя',
    current_month: 'Текущий месяц',
    last_month: 'Прошлый месяц',
    current_year: 'Текущий год',
    last_year: 'Прошлый год',
    all_time: 'Все время',
    january: 'Январь',
    february: 'Февраль',
    march: 'Март',
    april: 'Апрель',
    may: 'Май',
    june: 'Июнь',
    july: 'Июль',
    august: 'Август',
    september: 'Сентябрь',
    october: 'Октябрь',
    november: 'Ноябрь',
    december: 'Декабрь',

    ref_program: 'Реф. программа',
    referral_program: 'Реф. программа',
    your_ref_link: 'Ваша реферальная ссылка',
    referral_program_off: 'Реферальная программа отключена',
    your_referrals: 'Ваши рефералы',
    referrals: 'Рефералы',
    referral_payments: 'Реферальные начисления',
    webmaster: 'Вебмастер',
    profit_approve: 'Профит Аппрув',
    profit_hold: 'Профит Холд',
    ref_link_desc1: 'привлекайте партнеров и получайте',
    ref_link_desc2: 'от суммы их заработка',
    offers_count: 'офферов',

    registration_date: 'Дата регистрации',
    conversion_date: 'Дата конверсии',
    click_id: 'Клик ID',
    tds_click_id: 'TDS Клик ID',
    operating_system: 'Операционная система',
    device: 'Устройство',
    browser: 'Браузер',
    campaign_url: 'Ссылка кампании',
    offer_redirect: 'Редирект на оффер',
    request_to_tracker: 'Запрос в трекер',
    sended_postbacks: 'Отправленные постбеки',
    validation_payment_account: 'Пожалуйста, выберите номер счета',
    validation_min_amount: 'Минимальная сумма выплаты составляет ',
    validation_payout_currency: 'Пожалуйста, выберите валюту выплаты',
    decline_payout:
      'Отказано в запросе на выплату. Пожалуйста, обратитесь к Вашему менеджеру.',
    offer_access: 'Доступ к офферу',
    opened: 'открыт!',
    closed: 'закрыт!',
    payout_complete: 'Выплата произведена!',
    payout_rejected: 'Выплата отменена!',
    ticket_reply: 'Новое сообщение в тикете поддержки.',
    Валерий: 'Валерий',
    Богдан: 'Богдан',
    Евгений: 'Евгений',
    Дмитрий: 'Дмитрий',
    Александр: 'Александр',
    uniqueless: 'Уникальный, шт.',
    double_dep: 'Повторные депозиты',
    our_apps_traffic_hint:
      'Добавьте этот параметр, если будете лить трафик через свой трекер на наши офферы, используя наши приложения',
    only_my_offers: 'Только мои офферы',
    select_image_error: `Изображение должно иметь расширение .jpg, .png либо .gif`,
    id_for_apps:
      'Используйте этот ID для авторизации в Telegram боте аренды приложений Gambeat APPS BOT',
    network: 'Сеть',
    availables: 'Доступные',
    rows_per_page: 'Записей на странице:',
    finished: 'Завершенные',
    resolved: 'Завершенный',
    create_ticket: 'Создать тикет',
    theme: 'Тема',
    all: 'Все',
    new: 'Новый',
    waiting_for_admin: 'Ожидает ответа администрации',
    waiting_for_affiliate: 'Ожидает ответа вебмастера',
    author: 'Автор',
    ticket_number: 'Тикет номер',
    message_text: 'Текст сообщения',
    messages_text: 'Текст сообщений',
    new_message: 'Новое сообщение',
    enter_message_text: 'Введите текст сообщения',
    send: 'Отправить',
    attach_file: 'Прикрепить файл',
    notifications: 'Оповещения',
    mark_read_all: 'Отметить все как прочитанные',
    close_window: 'Свернуть окно',
    export_data: 'Экспорт данных',
    overview: 'Обзор',
    offer_closed: 'Доступ к офферу закрыт! Обратитесь к личному менеджеру.',
    super_offers: 'Топ офферы',
    create: 'Создать',
    total: 'Всего',
    text_message_specify: 'Тема и сообщение должны быть заполнены!',
    error: 'Произошла ошибка. Пожалуйста обратитесь к Вашему менеджеру.',

    id_offer: 'ID оффера',
    offer_name: 'Название оффера',
    bid: 'Ставка',
    yevhenii: 'Евгений',
    conversion_id: 'ID конверсии',
    country: 'Страна',
    available: 'Доступно',
    traffic_type: 'Тип трафика',
    condition: 'Условие',
    payout: 'Выплата',
    add_your_domain: 'Добавить свой домен',
    created_at: 'Дата создания',
    add_new_naming: 'Добавить новый нейминг',
    geo_organic: 'Гео (органика):',
    cancel: 'Отменить',

    status_changed: 'Статус выплаты обновлен',
    reject_reason: 'Причина реджекта',
    welcome: 'Приветствуем! Для сброса пароля перейдите по этой ссылке',
    offerta: 'Оферта',
    rules: 'Правила',
    manager: 'Ваш менеджер',
    offers: 'Офферы',
    statistics: 'Статистика',
    conversions: 'Конверсии',
    campaigns: 'Кампании',
    postbacks: 'Глобальный Postback',
    tools: 'Инструменты',
    parking: 'Парковка доменов',
    payouts: 'Выплаты',
    api: 'API',
    history: 'История',
    pay: 'Реквизиты',
    income_hold: 'Доход Холд',
    income_revshare: 'Доход RevShare (Холд)',
    pass_balance: 'Переведено на баланс',
    balance_hold: 'Баланс Холд',
    offer_id: 'Оффер ID',
    support: 'Поддержка',
    question: 'Задать вопрос',
    get_questions: 'У Вас остались еще вопросы? Напишите Вашему менеджеру.',
    balance: 'Баланс',
    hold: 'Холд',
    approve: 'Апрув',
    access: 'Запросить доступ',
    had_access: 'Доступ запрошен',
    create_campaign: 'Создать кампанию',
    name: 'Название',
    geos: 'Страны',
    vertical: 'Вертикаль',
    category: 'Категория',
    reward_type: 'Тип конверсии',
    campaign: 'Кампания',
    offer: 'Оффер',
    date: 'Дата',
    groupBy: 'Группировать по',
    general: 'Общая',
    gambling: 'Гемблинг',
    landings: 'Лендинги',
    profile: 'Профиль',
    exit: 'Выход',
    postback_tools: 'Настройки Глобального Postback',
    reference: 'Справка',
    add_postback: 'Добавить Postback',
    save: 'Сохранить',
    approved_lead: 'Подтвержденный лид',
    reg: 'Регистрация',
    dep: 'Депозит',
    revshare: 'Ревшара',
    rejected: 'Отклоненный',
    trash: 'Трэш',
    settings: 'Настройки',
    logs: 'Логи',
    log_sended: 'Лог отправленных постбеков',
    event: 'Событие',
    status: 'Статус',
    link: 'Ссылка',
    datetime: 'Дата / Время',
    fullname: 'Имя',
    change: 'Изменить',
    edit: 'Редактировать',
    email: 'Email',
    download_new: 'Загрузить новое фото',
    download_photo: 'Загрузите сюда изображение весом не больше 5МБ',
    own_data: 'Личные данные',
    password: 'Пароль',
    pay_data: 'Платежные данные',
    cur_password: 'Текущий пароль',
    new_password: 'Новый пароль',
    add_pay_method: 'Добавить новый метод вывода',
    add_new_method: 'Новый метод вывода',
    payment_system: 'Платежная система',
    currency: 'Валюта',
    billing: 'Номер счета',
    add: 'Добавить',
    edit_profile: 'Редактировать профиль',
    messenger: 'Мессенджер',
    base_currency: 'Базовая валюта аккаунта',
    nick: 'Ник',
    labels: 'Метки',
    income: 'Доход',
    campaign_name: 'Название кампании',
    actions: 'Действия',
    create_new_campaign: 'Создать новую кампанию',
    active: 'Активные',
    stopped: 'Остановленные',
    new_campaign: 'Новая кампания',
    source: 'Источник',
    domain: 'Домен',
    your_domain: 'Ваш домен',
    geo: 'Гео',
    traffic: 'Трафик',
    clicks: 'Hit',
    uniqs: 'Host',
    leads: 'Лиды',
    finances: 'Финансы',
    approved: 'Принято',
    rejects: 'Отменено',
    postback_setting_info: 'Информация по настройке Postback',
    params: 'Параметры:',
    event_type:
      'Тип события (reg - при регистрации, first_dep - при первом депозите, revshare - при доходе по ревшаре)',
    id_campaign: 'ID кампании',
    all_status: 'Статус (approved, pending, hold, rejected, trash)',
    amount_sum:
      'Сумма поступления (CPA - сумма дохода, RevShare доход за каждый час)',
    general_income_revshare: 'Общий доход по RevShare',
    amount_currency: 'Валюта поступления (usd, eur, rub, uah)',
    deposit_id: 'уникальный ID депозита либо другого целевого события',
    unix_timestamp: 'unix timestamp генерации действия',
    copy: 'Копировать',
    instruction_parking: 'Инструкция по настройке парковки',
    for_add_domain: 'Для добавления домена вам необходимо:',
    first_inst: '1) зарегистрировать домен на любом регистраторе',
    second_inst: '2) перейти в раздел редактирования DNS',
    third_inst: '3) прописать для домена или его поддомена CNAME запись:',
    four_inst: '4) в качестве Target цели для редиректа выбрать:',
    fifth_inst:
      '5) добавить домен в интерфейсе ниже либо при создании кампании',
    dns:
      'Когда DNS записи вашего домена обновятся, Вы сможете использовать его в ссылках для рекламных материалов.',
    update_dns:
      'Обновление DNS записей обычно занимает от нескольких минут до 12 часов.',
    your_balance: 'Ваш баланс',
    payment_history: 'История выплат',
    payment_method: 'Метод вывода',
    wallet: 'Кошелек',
    sum: 'Сумма',
    no_data: 'Нет данных',
    get_link: 'Получить ссылку',
    records_count: 'Количество записей',
    enter: 'Войти в платформу',
    forgot_password: 'Забыли пароль?',
    enter2: 'Войти',
    no_account: 'Нет аккаунта?',
    go_reg: 'Зарегистрируйтесь',
    registration: 'Регистрация',
    telegram_nick: 'Никнейм в Telegram',
    skype_nick: 'Никнейм в Skype',
    email_address: 'Адрес электронной почты',
    have_account: 'Есть аккаунт?',
    have_account_enter: 'Войдите',
    onreg: 'Зарегистрироваться',
    reset_password: 'Восстановление пароля',
    send_request: 'Отправить запрос',
    check_mail:
      'Проверьте почтовый ящик, ссылка для восстановления пароля отправлена.',
    order_payout: 'Заказать выплату',
    show_more: 'Показать еще...',
    add_sids: 'Добавить сиды:',
    link_campaign: 'Ссылка кампании:',
    get_access: 'Получить доступ',
    copied: 'Скопировано',
    your_domain_small: 'ваш_домен',
    regs: 'Регистрации',
    deps: 'Депозиты',
    first_dep: 'Первый депозит',
    count: 'Количество, шт',
    installs: 'Инсталлы',
    rate: 'Расход',
    rates: 'Показатели',
    days: 'Дни',
    weeks: 'Недели',
    landing: 'Лендинг',

    app: 'Приложения',
    apps: 'Приложение:',
    traffic_source: 'Источник трафика:',
    sharing: 'Расшарка',
    pushes: 'Пуши',
    transactions: 'Транзакции',
    refill: 'Пополнить',
    texting: 'Написать',
    refill_balance: 'Пополнение баланса',
    message_for_refill: 'Для пополнения баланса напишите Вашему менеджеру.',
    general_naming: 'Универсальный нейминг',
    your_naming: 'Ваш нейминг',
    offer_link: 'Ссылка на оффер',
    naming: 'Нейминг',
    advert_cabinets: 'Рекламные кабинеты:',
    pass_your_ad_id:
      'Введите ID рекламных кабинетов, каждый с новой строки без запятых',
    delete: 'Удалить',
    delete_all: 'Удалить все',
    accounts: 'Аккаунты',
    operation: 'Операция',
    sharing_date: 'Дата расшарки',
    problem_accounts: 'Проблемные аккаунты (ошибки)',
    comment: 'Комментарий',
    play_market_url: 'Ссылка в Google Play Market',
    autopush: 'Автопуши',
    create_new_push: 'Создать новый пуш',
    title: 'Заголовок',
    time: 'Время',
    incl: 'Вкл/Выкл',
    text: 'Текст',
    events: 'События',
    install: 'Инсталл',
    pass_title: 'Введите заголовок',
    notification_text: 'Текст оповещения',
    for_example: 'Например',
    preview_image: 'Preview изображение',
    back_image: 'Фоновое изображение',
    monday: 'Пн',
    tuesday: 'Вт',
    wednesday: 'Ср',
    thursday: 'Чт',
    friday: 'Пт',
    saturday: 'Сб',
    sunday: 'Вс',
    sending_time: 'Время отправки',
    gmt_sending_time:
      'Время отправки по GMT+1, учитывайте часовой пояс для Вашего гео',
    square:
      'Изображение, которое будет размещено справа от текста и отображается в preview уведомления. Размер - квадрат 1:1',
    square2:
      'Изображение, которое отображается в открытом (расширенном) уведомлении под текстом уведомления. Размер - прямоугольник 2:1',
    url_camp_tooltip:
      'URL кампании с ПП или с Вашего трекера либо ПП. Параметр {click_id} нужен для оптимизации за события. Пример:',
    url_track_camp_tooltip: 'URL кампании с ПП или с Вашего трекера. Пример:',
    url_track_camp_tooltip2:
      'URL кампании с нашей ПП или с трекера веба. Если это ссылка от веба - то в какое то из полей обязательно нужно подставить ${click_id}, чтобы веб отправлял нам постбеки на TDS. Для передачи сабов из нейминга использовать ${sub1}, ${sub2}, ${sub3}, ${sub4}, ${sub5}, ${sub6}, ${sub7}. Пример:',
    naming_tooltip:
      'В нейминге вместо sub1, sub2, ..., sub5 можно подставить СВОИ значения. В сабах нельзя использовать нижнее подчеркивание _',
    first_naming_tooltip: 'Первую часть нейминга до _ менять нельзя!!!',
    subs:
      'Для передачи сабов с нейминга в ссылку на оффер необходимо подставить доступные сабы.',
    available_subs: 'Доступные сабы:',
    param: 'Параметр',
    need_click_id:
      'необходим для передачи вам в трекер нашего клик ID, чтобы Вы смогли присылать на него события (регистрация и депозит).',
    add_new_rule: 'Добавить новое правило',
    optimization: 'Постбеки (оптимизация):',
    for_optimization:
      'Для оптимизации по событиям и просмотра статистики Вам необходимо настроить постбеки с Вашего трекера на нашу TDS.',
    postback_available_params: 'Доступные параметры в постбеке:',
    click_id_tds: 'ID клика в нашей TDS',
    deposit_sum: 'Сумма депозита. Если равно 0 - значит была Регистрация',
    deposit_currency: 'Валюта депозита (например: usd)',
    postback_reg_example: 'Пример постбека на Регистрацию:',
    postback_dep_example: 'Пример постбека на Депозит:',
    audience_text:
      'Мы автоматически переносим аудиторию пользователей, которые внесли депозит, на ВСЕ доступные вам ГЕО органики по выбранному приложению.',
    validation_text: 'Поле должно быть заполнено',
    validation_text_url: 'Поле должно быть валидным URL адресом',
    validation_text_email_password: 'Неверный e-mail или пароль',
    validation_text_email: 'Неверный e-mail',
    validation_text_password: 'Пароль должен содержать 6 или больше символов',
    unknown_error_occured: 'Упс! Произошла неизвестная ошибка.',
    validation_text_email_exists: 'Данный e-mail уже зарегистрирован',
    validation_text_payment_system: 'Выберите платежную систему',
    validation_text_payment_system_network: 'Выберите сеть платежной системы',
    validation_text_payment_currency: 'Выберите валюту',
    validation_text_payment_account: 'Укажите номер счета либо карты',
    validation_text_tds_rule_url:
      'Ссылка должна начинаться с http:// или https://',
    validation_text_app_campaign_name: 'Укажите название кампании',
    validation_text_app_campaign_app: 'Выберите приложение',
    validation_text_app_campaign_source: 'Выберите источник трафика',
    validation_text_campaign_name: 'Укажите название кампании',
    validation_text_campaign_offer: 'Выберите оффер',
    validation_text_campaign_source: 'Выберите источник трафика',
    validation_text_campaign_domain: 'Выберите домен для редиректа',
    validation_text_campaign_geo: 'Выберите гео',
    validation_text_campaign_promo: 'Выберите лендинг',
    validation_text_push_app: 'Выберите приложение',
    validation_text_email_push_title: 'Укажите заголовок пуша',
    validation_text_email_push_message: 'Укажите текст пуша',
    validation_text_email_push_geo: 'Выберите гео',
  },
  ua: {
    select_leads_for_payout: 'Вибір лідів для виплати',
    all_sum: 'Вся сума',
    select_leads: 'Вибрати ліди',
    payout_sum: 'Сума виплати',
    landing_name: 'Назва лендінгу',
    open_link: 'Переглянути посилання',
    conditions: 'Умови',
    terms: 'Опис',
    dashboard: 'Дашборд',
    timezone: 'Часовий пояс',
    id_offer: 'ID офера',
    enter3: 'Приєднатися',
    marketing_network: 'ЕФЕКТИВНА ПАРТНЕРСЬКА МЕРЕЖА',
    show_more2: 'Детальніше',
    best_offers_diff_geo: 'Кращі Оффери, Різноманітні Гео',
    mindep: 'міндеп',
    baseline: 'бейслайн',

    fact_stats: 'Фактичні дані',
    fact_stats_tooltip:
      'Фактична статистика показує депозити у дні, коли вони реально були зроблені. Історична статистика показує реєстрації та депозити на день кліка.',

    choose_offer: 'Вибір офера',
    choose_campaign: 'Вибір кампанії',
    choose_geo: 'Вибір країни',
    choose_sid: 'Вибір мітки',
    choose_landing: 'Вибір ленда',
    apply: 'Застосувати',
    today: 'Сьогодні',
    yesterday: 'Вчора',
    current_week: 'Текущая неделя',
    last_week: 'Минула неділя',
    current_month: 'Поточний місяць',
    last_month: 'Минулий місяць',
    current_year: 'Поточний рік',
    last_year: 'Минулий рік',
    all_time: 'Весь час',
    january: 'Січень',
    february: 'Лютий',
    march: 'Березень',
    april: 'Квітень',
    may: 'Травень',
    june: 'Червень',
    july: 'Липень',
    august: 'Серпень',
    september: 'Вересень',
    october: 'Жовтень',
    november: 'Листопад',
    december: 'Грудень',

    ref_program: 'Реф. програма',
    referral_program: 'Реф. програма',
    your_ref_link: 'Ваше реферальне посилання',
    referral_program_off: 'Реферальна програма вимкнена',
    your_referrals: 'Ваші реферали',
    referrals: 'Реферали',
    referral_payments: 'Реферальні нарахування',
    webmaster: 'Вебмайстер',
    profit_approve: 'Профіт Апрув',
    profit_hold: 'Профіт Холд',
    ref_link_desc1: 'залучайте партнерів та отримуйте',
    ref_link_desc2: 'від суми їх заробітку',
    offers_count: 'оферів',

    registration_date: 'Дата реєстрації',
    conversion_date: 'Дата конверсії',
    click_id: 'Клік ID',
    tds_click_id: 'TDS Клік ID',
    operating_system: 'Операційна система',
    device: 'Пристрій',
    browser: 'Браузер',
    campaign_url: 'Посилання на кампанію',
    offer_redirect: 'Редірект на оффер',
    request_to_tracker: 'Запит в трекер',
    sended_postbacks: 'Надіслані постбеки',
    validation_payment_account: 'Будь ласка, оберіть номер рахунку',
    validation_min_amount: 'Мінімальна сума виплати становить ',
    validation_payout_currency: 'Будь ласка, оберіть валюту виплати',
    decline_payout:
      'Відмовлено в запиті на виплату. Будь ласка, зверніться до Вашого менеджера.',
    offer_access: 'Доступ до оффера',
    opened: 'відкритий!',
    closed: 'закритий!',
    payout_complete: 'Виплата завершена!',
    payout_rejected: 'Виплата відхилена!',
    ticket_reply: 'Нове повідомлення в тікеті підтримки.',
    Валерий: 'Валерій',
    Богдан: 'Богдан',
    Евгений: 'Євгеній',
    Дмитрий: 'Дмитро',
    Александр: 'Олександр',
    uniqueless: 'Унікальний, шт.',
    double_dep: 'Повторні депозити',
    our_apps_traffic_hint:
      'Додайте цей параметр, якщо будете лити трафік через свій трекер на наші оффери, використовуючи наші мобільні додатки',
    only_my_offers: 'Тільки мої оффери',
    select_image_error: `Зображення повинно мати розширення .jpg, .png либо .gif`,
    id_for_apps:
      'Використовуйте цей ID для авторизації в Telegram боті аренди додатків Gambeat APPS BOT',
    network: 'Мережа',
    availables: 'Доступні',
    rows_per_page: 'Записів на сторінці:',
    finished: 'Завершені',
    resolved: 'Завершений',
    create_ticket: 'Створити тікет',
    theme: 'Тема',
    all: 'Всі',
    new: 'Новий',
    waiting_for_admin: 'Очікує відповіді адміністрації',
    waiting_for_affiliate: 'Очікує відповіді аффілейта',
    author: 'Автор',
    ticket_number: 'Тікер номер',
    message_text: 'Текст повідомлення',
    messages_text: 'Текст повідомлень',
    new_message: 'Нове повідомлення',
    enter_message_text: 'Введіть текст повідомлення',
    send: 'Відправити',
    attach_file: 'Додати файл',
    notifications: 'Сповіщення',
    mark_read_all: 'Відмітити всі як прочитані',
    close_window: 'Звернути вікно',
    export_data: 'Експорт даних',
    overview: 'Огляд',
    offer_closed:
      'Доступ до оффера закритий! Зверніться до особистого менеджера.',
    super_offers: 'Топ оффери',
    create: 'Створити',
    total: 'Всього',
    text_message_specify: 'Тема і повідомлення не мають бути пустими!',
    error: 'Виникла помилка. Будь ласка зверніться до Вашого менеджера.',

    offer_name: `Назва оффера`,
    bid: 'Ставка',
    yevhenii: 'Євгеній',
    conversion_id: 'ID конверсії',
    country: 'Країна',
    available: 'Доступно',
    traffic_type: 'Тип трафіку',
    condition: 'Умова',
    payout: 'Виплата',
    add_your_domain: 'Додати свій домен',
    created_at: 'Дата створення',
    add_new_naming: 'Додати новий неймінг',
    geo_organic: 'Гео (органіка):',
    cancel: 'Відмінити',

    status_changed: 'Статус виплати оновлено',
    reject_reason: 'Причина реджекту',
    welcome: 'Вітаєммо! Для скидання паролю перейдіть за посиланням',
    offerta: 'Оферта',
    rules: 'Правила',
    manager: 'Ваш менеджер',
    offers: 'Оффери',
    statistics: 'Статистика',
    conversions: 'Конверсії',
    campaigns: 'Кампании',
    postbacks: 'Глобальний Postback',
    tools: 'Інструменти',
    parking: 'Паркування доменів',
    payouts: 'Виплати',
    api: 'API',
    history: 'Історія',
    pay: 'Реквізити',
    income_hold: 'Дохід Холд',
    income_revshare: 'Дохід RevShare (Холд)',
    pass_balance: 'Переведено на баланс',
    balance_hold: 'Баланс Холд',
    offer_id: 'Оффер ID',
    support: 'Підтримка',
    question: 'Поставити запитання',
    get_questions: 'У Вас залишились ще питання? Напишіть Вашому менеджеру.',
    balance: 'Баланс',
    hold: 'Холд',
    approve: 'Апрув',
    access: 'Запросити доступ',
    had_access: 'Доступ запрошено',
    create_campaign: 'Створити потік',
    name: 'Назва',
    geos: 'Країни',
    vertical: 'Вертикаль',
    category: 'Категорія',
    reward_type: 'Тип конверсії',
    campaign: 'Потік',
    offer: 'Оффер',
    date: 'Дата',
    groupBy: 'Групувати по',
    general: 'Загальна',
    gambling: 'Гемблінг',
    landings: 'Лендінги',
    profile: 'Профіль',
    exit: 'Вихід',
    postback_tools: 'Налаштування Глобального Postback',
    reference: 'Довідка',
    add_postback: 'Додати Postback',
    save: 'Зберегти',
    approved_lead: 'Підтверджений лід',
    reg: 'Реєстрація',
    dep: 'Депозит',
    revshare: 'Ревшара',
    rejected: 'Відхилений',
    trash: 'Треш',
    settings: 'Налаштування',
    logs: 'Логи',
    log_sended: 'Лог відправлених постбеків',
    event: 'Подія',
    status: 'Статус',
    link: 'Посилання',
    datetime: 'Дата / Час',
    fullname: `Ім'я`,
    change: 'Змінити',
    edit: 'Редагувати',
    email: 'Email',
    download_new: 'Завантажити нове фото',
    download_photo: 'Завантажити сюди зображення розміром не більше 5МБ',
    own_data: 'Особисті дані',
    password: 'Пароль',
    pay_data: 'Платіжні дані',
    cur_password: 'Поточний пароль',
    new_password: 'Новий пароль',
    add_pay_method: 'Додати новий метод виводу',
    add_new_method: 'Новий метод виводу',
    payment_system: 'Платіжна система',
    currency: 'Валюта',
    billing: 'Номер рахунку',
    add: 'Додати',
    edit_profile: 'Редагувати профіль',
    messenger: 'Месенджер',
    base_currency: 'Базовая валюта аккаунту',
    nick: 'Нік',
    labels: 'Мітки',
    income: 'Дохід',
    campaign_name: 'Назва кампанії',
    actions: 'Действия',
    create_new_campaign: 'Створити новий потік',
    active: 'Активні',
    stopped: 'Призупинені',
    new_campaign: 'Новий потік',
    source: 'Джерело',
    domain: 'Домен',
    your_domain: 'Ваш домен',
    geo: 'Гео',
    traffic: 'Трафік',
    clicks: 'Hit',
    uniqs: 'Host',
    leads: 'Ліди',
    finances: 'Фінанси',
    approved: 'Принято',
    rejects: 'Відхилено',
    postback_setting_info: 'Інформація по налаштуванню Postback',
    params: 'Параметри:',
    event_type:
      'Тип події (reg - при регістрації, first_dep - при першому депозиті, revshare - при доході по ревшарі)',
    id_campaign: 'ID кампанії',
    all_status: 'Статус (approved, pending, hold, rejected, trash)',
    amount_sum:
      'Сума надходження (CPA - сума доходу, RevShare дохід за кожну годину)',
    general_income_revshare: 'Загальний дохід по RevShare',
    amount_currency: 'Валюта надходження (usd, eur, rub, uah)',
    deposit_id: 'унікальний ID депозиту або іншої цільової події',
    unix_timestamp: 'unix timestamp генерації події',
    copy: 'Копіювати',
    instruction_parking: 'Інструкція по налаштуванню паркування',
    for_add_domain: 'Для додавання домену вам необхідно:',
    first_inst: '1) зареєструвати домен на будь-якому реєстраторі',
    second_inst: '2) перейти в розділ редагування DNS',
    third_inst: '3) прописати для домену або його піддомену CNAME запис:',
    four_inst: '4) в якості Target цілі для редіректу обрати:',
    fifth_inst: '5) додати домен в інтерфейсі нижче або при створенні кампанії',
    dns:
      'Коли DNS записи вашего домена оновляться, Ви зможете використовувати його в поссиланнях для рекламних матеріалів.',
    update_dns:
      'Оновлення записів DNS зазвичай займає від декількох хвилин до 12 годин.',
    your_balance: 'Ваш баланс',
    payment_history: 'Історія виплат',
    payment_method: 'Метод виводу',
    wallet: 'Гаманець',
    sum: 'Сума',
    no_data: 'Немає даних',
    get_link: 'Отримати посилання',
    records_count: 'Кількість записів',
    enter: 'Увійти в платформу',
    forgot_password: 'Забули пароль?',
    enter2: 'Увійти',
    no_account: 'Немає акаунта?',
    go_reg: 'Зареєструйтесь',
    registration: 'Реєстрація',
    telegram_nick: 'Нікнейм в Telegram',
    skype_nick: 'Нікнейм в Skype',
    email_address: 'Адреса електронної пошти',
    have_account: 'Є акаунт?',
    have_account_enter: 'Увійдіть',
    onreg: 'Зареєструватись',
    reset_password: 'Відновлення паролю',
    send_request: 'Відправити запит',
    check_mail:
      'Перевірте поштову скриньку, посилання для відновлення пароля надіслано.',
    order_payout: 'Замовити виплату',
    show_more: 'Показати еще...',
    add_sids: 'Додати сіди:',
    link_campaign: 'Посилання кампанії:',
    get_access: 'Отримати доступ',
    copied: 'Скопійовано',
    your_domain_small: 'ваш_домен',
    regs: 'Реєстрації',
    deps: 'Депозити',
    first_dep: 'Перший депозит',
    count: 'Кількість, шт',
    installs: 'Інстали',
    rate: 'Витрати',
    rates: 'Показники',
    days: 'Дні',
    weeks: 'Тижні',
    landing: 'Лендінг',

    app: 'Додатки',
    apps: 'Додаток:',
    traffic_source: 'Джерело трафіку:',
    sharing: 'Пошарка',
    pushes: 'Пуши',
    transactions: 'Транзакції',
    refill: 'Поповнити',
    texting: 'Написати',
    refill_balance: 'Поповнення балансу',
    message_for_refill: 'Для поповнення балансу напишіть вашому менеджеру.',
    general_naming: 'Універсальний неймінг',
    your_naming: 'Ваш неймінг',
    offer_link: 'Посилання на оффер',
    naming: 'Неймінг',
    advert_cabinets: 'Рекламні кабінети:',
    pass_your_ad_id:
      'Введіть ID рекламних кабінетів, кожен з нового рядка без ком.',
    delete: 'Видалити',
    delete_all: 'Видалити все',
    accounts: 'Акаунти',
    operation: 'Операція',
    sharing_date: 'Дата пошарки',
    problem_accounts: 'Проблемні акаунти (помилки)',
    comment: 'Коментар',
    play_market_url: 'Посилання в Google Play Market',
    autopush: 'Автопуши',
    create_new_push: 'Створити новий пуш',
    title: 'Заголовок',
    time: 'Час',
    incl: 'Увімк/Вимк',
    text: 'Текст',
    events: 'Подія',
    install: 'Інстал',
    pass_title: 'Введіть заголовок',
    notification_text: 'Текст оповіщення',
    for_example: 'Наприклад',
    preview_image: 'Preview зображення',
    back_image: 'Фонове зображення',
    monday: 'Пн',
    tuesday: 'Вт',
    wednesday: 'Ср',
    thursday: 'Чт',
    friday: 'Пт',
    saturday: 'Сб',
    sunday: 'Нд',
    sending_time: 'Час відправки',
    gmt_sending_time:
      'Час відправлення GMT+1, враховуйте часовий пояс для Вашого гео',
    square:
      'Зображення, яке буде розміщено праворуч від тексту та відображається у попередньому повідомлення. Розмір – квадрат 1:1',
    square2:
      'Зображення, яке відображається у відкритому (розширеному) повідомленні під текстом сповіщення. Розмір – прямокутник 2:1',
    url_camp_tooltip:
      'URL кампанії з ПП або Вашого трекера або ПП. Параметр {click_id} потрібний для оптимізації за події. Приклад:',
    url_track_camp_tooltip: 'URL кампанії з ПП або вашого трекера. Приклад:',
    url_track_camp_tooltip2:
      "URL кампанії з нашого ПП або з трекера веб. Якщо це посилання від Інтернету - то в якесь із полів обов'язково потрібно підставити ${click_id}, щоб Інтернет надсилав нам постбеки на TDS. Для передачі сабів з неймінгу використовувати ${sub1}, ${sub2}, ${sub3}, ${sub4}, ${sub5}, ${sub6}, ${sub7}. Приклад:",
    naming_tooltip:
      'У неймінгу замість sub1, sub2, ..., sub5 можна підставити свої значення. У сабах не можна використовувати нижнє підкреслення _',
    first_naming_tooltip: 'Першу частину неймінгу до _ міняти не можна!',
    subs:
      'Для передачі сабів з неймінгу на посилання на оффер необхідно підставити доступні саби.',
    available_subs: 'Доступні саби:',
    param: 'Параметр',
    need_click_id:
      'необхідний для передачі вам у трекер нашого клік ID, щоб Ви змогли надсилати на нього події (реєстрація та депозит).',
    add_new_rule: 'Додати нове правило',
    optimization: 'Постбеки (оптимізація):',
    for_optimization:
      'Для оптимізації подій та перегляду статистики Вам необхідно налаштувати постбеки з Вашого трекера на нашу TDS.',
    postback_available_params: 'Доступні параметри в постбеку:',
    click_id_tds: 'ID кліка у нашій TDS',
    deposit_sum: 'сума депозиту. Якщо одно 0 - значить була Реєстрація',
    deposit_currency: 'Валюта депозиту (наприклад: usd)',
    postback_reg_example: 'Приклад постбеку на реєстрацію:',
    postback_dep_example: 'Приклад постбеку на депозит:',
    audience_text:
      'Ми автоматично переносимо аудиторію користувачів, які внесли депозит, на ВСІ доступні вам органи ГЕО за вибраним додатком.',
    validation_text: 'Поле має бути заповнено',
    validation_text_url: 'Поле має бути валідною URL-адресою',
    validation_text_email_password: 'Невірний e-mail або пароль',
    validation_text_email: 'Невірний e-mail',
    validation_text_password: 'Пароль має містити 6 або більше символів',

    unknown_error_occured: 'Ой! Виникла невідома помилка.',
    validation_text_email_exists: 'Даний e-mail вже зареєстрований',
    validation_text_payment_system: 'Оберіть платіжну систему',
    validation_text_payment_system_network: 'Оберіть мережу платіжної системи',
    validation_text_payment_currency: 'Оберіть валюту',
    validation_text_payment_account: 'Вкажіть номер рахунку либо картки',
    validation_text_tds_rule_url:
      'Посилання повинно починатись на http:// або https://',
    validation_text_app_campaign_name: 'Вкажіть назву кампанії',
    validation_text_app_campaign_app: 'Оберіть мобільний додаток',
    validation_text_app_campaign_source: 'Оберіть джерело трафіка',
    validation_text_campaign_name: 'Вкажіть назву кампанії',
    validation_text_campaign_offer: 'Оберіть оффер',
    validation_text_campaign_source: 'Оберіть джерело трафіка',
    validation_text_campaign_domain: 'Оберіть домен для редиректа',
    validation_text_campaign_geo: 'Оберіть гео',
    validation_text_campaign_promo: 'Оберіть лендінг',
    validation_text_push_app: 'Оберіть мобільний додаток',
    validation_text_email_push_title: 'Вкажіть заголовок пуша',
    validation_text_email_push_message: 'Вкажіть текст пуша',
    validation_text_email_push_geo: 'Оберіть гео',
  },
  en: {
    select_leads_for_payout: 'Select leads for payout',
    all_sum: 'All sum',
    select_leads: 'Select leads',
    payout_sum: 'Payout amount',
    landing_name: 'Landing name',
    open_link: 'Open link',
    conditions: 'Rates',
    terms: 'Terms',
    dashboard: 'Dashboard',
    timezone: 'Timezone',
    id_offer: 'Offer ID',
    enter3: 'Enter',
    marketing_network: 'PERFORMANCE MARKETING NETWORK',
    show_more2: 'Show more',
    best_offers_diff_geo: 'Best Offers, Different Geo',
    mindep: 'mindep',
    baseline: 'baseline',

    fact_stats: 'Factual data',
    fact_stats_tooltip:
      'The actual statistics show the deposits on the days when they were actually made. Historical statistics show registrations and deposits on the day of the click.',

    choose_offer: 'Choose offer',
    choose_campaign: 'Choose campaign',
    choose_geo: 'Choose country',
    choose_sid: 'Choose sub',
    choose_landing: 'Choose landing',
    apply: 'Apply',
    today: 'Today',
    yesterday: 'Yesterday',
    current_week: 'Current week',
    last_week: 'Last week',
    current_month: 'Current month',
    last_month: 'Last month',
    current_year: 'Current year',
    last_year: 'Last year',
    all_time: 'All time',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',

    ref_program: 'Ref. program',
    referral_program: 'Ref. program',
    your_ref_link: 'Your referral link',
    referral_program_off: 'Referral program disabled',
    your_referrals: 'Your referrals',
    referrals: 'Referrals',
    referral_payments: 'Referral accruals',
    webmaster: 'Affiliate',
    profit_approve: 'Profit Approve',
    profit_hold: 'Profit Hold',
    ref_link_desc1: 'attract partners and get',
    ref_link_desc2: 'from their earnings',
    offers_count: 'offers',

    registration_date: 'Registration date',
    conversion_date: 'Conversion date',
    click_id: 'Click ID',
    tds_click_id: 'TDS Click ID',
    operating_system: 'Operating system',
    device: 'Device type',
    browser: 'Browser',
    campaign_url: 'Campaign URL',
    offer_redirect: 'Redirect to offer',
    request_to_tracker: 'Tracker request',
    sended_postbacks: 'Sended postbacks',
    validation_payment_account: 'Please, select payment account',
    validation_min_amount: 'Minimum amount is ',
    validation_payout_currency: 'Payout currency should be specified',
    decline_payout: 'Payout request denied. Please contact your manager.',
    offer_access: 'Offer',
    opened: 'opened!',
    closed: 'closed!',
    payout_complete: 'Payout complete!',
    payout_rejected: 'Payout rejected!',
    ticket_reply: 'New message available in support ticket.',
    Валерий: 'Valerii',
    Богдан: 'Bogdan',
    Евгений: 'Yevhenii',
    Дмитрий: 'Dmitry',
    Александр: 'Alexander',
    uniqueless: 'Unique',
    double_dep: 'Repeated deposits',
    our_apps_traffic_hint:
      'Add this parameter if you will drive traffic through your tracker to our offers using our mobile apps',
    only_my_offers: 'Only my offers',
    select_image_error: `The picture (image) must have the extension .jpg, .png or .gif`,
    id_for_apps:
      'Use this ID for auth in Telegram bot Gambeat APPS BOT to rent apps',
    availables: 'Available',
    network: 'Network',
    rows_per_page: 'Rows per page:',
    finished: 'Completed',
    resolved: 'Resolved',
    create_ticket: 'Create ticket',
    theme: 'Title',
    all: 'All',
    new: 'New',
    waiting_for_admin: 'Waiting for admin',
    waiting_for_affiliate: 'Waiting for affiliate',
    author: 'Author',
    ticket_number: 'Ticket number',
    message_text: 'Message text',
    messages_text: 'Message text',
    new_message: 'New message',
    enter_message_text: 'Enter message text',
    send: 'Send',
    attach_file: 'Attach file',
    notifications: 'Notifications',
    mark_read_all: 'Mark all as read',
    close_window: 'Close panel',
    export_data: 'Export data',
    overview: 'Overview',
    offer_closed: 'Offer is closed. Please contact your manager.',
    super_offers: 'Super offers',
    create: 'Create',
    total: 'Total',
    text_message_specify: 'Ticket theme and message should be specified',
    error: 'An error has occurred. Please contact your manager.',

    offer_name: 'Offer name',
    bid: 'Payout',
    yevhenii: 'Yevhenii',
    conversion_id: 'Lead ID',
    country: 'Country',
    available: 'Available',
    traffic_type: 'Traffic type',
    condition: 'Condition',
    payout: 'Payout',
    add_your_domain: 'Add your domain',
    created_at: 'Created at',
    add_new_naming: 'Add new naming',
    geo_organic: 'GEO (organic):',
    cancel: 'Cancel',

    status_changed: 'Payout status is updated',
    reject_reason: 'The rejection reason',
    welcome: 'Welcome! To reset your password, follow this link',
    offerta: 'Offer',
    rules: 'Rules',
    manager: 'Your manager',
    offers: 'Offers',
    statistics: 'Statistics',
    conversions: 'Conversions',
    campaigns: 'Campaigns',
    postbacks: 'Global postback',
    tools: 'Tools',
    parking: 'Domain parking',
    payouts: 'Payouts',
    api: 'API',
    history: 'History',
    pay: 'Banking details',
    income_hold: 'Income Hold',
    income_revshare: 'Revenue RevShare (Hold)',
    pass_balance: 'Transferred to balance',
    balance_hold: 'Balance Hold',
    offer_id: 'Offer ID',
    support: 'Support',
    question: 'Ask a question',
    get_questions: 'Do you have any other questions? Write to your manager.',
    balance: 'Balance',
    hold: 'Hold',
    approve: 'Approve',
    access: 'Request access',
    had_access: 'Access requested',
    create_campaign: 'Create campaign',
    name: 'Name',
    geos: 'Countries',
    vertical: 'Vertical',
    category: 'Category',
    reward_type: 'Conversion type',
    campaign: 'Stream',
    offer: 'Offer',
    date: 'Date',
    groupBy: 'Group by',
    general: 'Home',
    gambling: 'Gambling',
    landings: 'Landing pages',
    profile: 'Profile',
    exit: 'Exit',
    postback_tools: 'Global Postback Settings',
    reference: 'Help',
    add_postback: 'Add Postback',
    save: 'Save',
    approved_lead: 'Approved lead',
    reg: 'Registration',
    dep: 'Deposit',
    revshare: 'Revshare',
    rejected: 'Rejected',
    trash: 'Trash',
    settings: 'Settings',
    logs: 'Logs',
    log_sended: 'Log of sent postbacks',
    event: 'Event',
    status: 'Status',
    link: 'Link',
    datetime: 'Date/Time',
    fullname: 'Name',
    change: 'Change',
    edit: 'Edit',
    email: 'Email',
    download_new: 'Upload a new photo',
    download_photo: 'Upload an image no more than 5MB here',
    own_data: 'Personal data',
    password: 'Password',
    pay_data: 'Payment data',
    cur_password: 'Current password',
    new_password: 'New password',
    add_pay_method: 'Add a new withdrawal method',
    add_new_method: 'New withdrawal method',
    payment_system: 'Payment system',
    currency: 'Currency',
    billing: 'Account number',
    add: 'Add',
    edit_profile: 'Edit Profile',
    messenger: 'Messenger',
    base_currency: 'Base account currency',
    nick: 'Nickname',
    labels: 'Labels',
    income: 'Income',
    campaign_name: 'Stream name',
    actions: 'Actions',
    create_new_campaign: 'Create a new campaign',
    active: 'Active',
    stopped: 'Stopped',
    new_campaign: 'New campaign',
    source: 'Source',
    domain: 'Domain',
    your_domain: 'Your domain',
    geo: 'Geo',
    traffic: 'Traffic',
    clicks: 'Hit',
    uniqs: 'Host',
    leads: 'Leads',
    finances: 'Finance',
    approved: 'Approved',
    rejects: 'Rejected',
    postback_setting_info: 'Postback configuration information',
    params: 'Parameters:',
    event_type:
      'Event type (reg - upon registration, first_dep - upon first deposit, revshare - upon revshare income)',
    id_campaign: 'Campaign ID',
    all_status: 'Status (approved, pending, hold, rejected, trash)',
    amount_sum:
      'Amount of receipt (CPA - amount of income, RevShare income per hour)',
    general_income_revshare: 'Total revenue for RevShare',
    amount_currency: 'Currency of receipt (usd, eur, rub, uah)',
    deposit_id: 'unique ID of a deposit or of other target event',
    unix_timestamp: 'unix timestamp generating action',
    copy: 'Copy',
    instruction_parking: 'Instructions for parking set up',
    for_add_domain: 'To add a domain, you have to:',
    first_inst: '1) register a domain at any registrar',
    second_inst: '2) go to the DNS editing section',
    third_inst: '3) register a CNAME entry for the domain or its subdomain:',
    four_inst: '4) select as the Target target for the redirect:',
    fifth_inst:
      '5) add a domain in the interface below or when creating a stream',
    dns: `When your domain's DNS records are updated, you will be able to use it in links for promotional materials.`,
    update_dns:
      'Updating DNS records usually takes from a few minutes to 12 hours.',
    your_balance: 'Your balance',
    payment_history: 'Payment history',
    payment_method: 'Withdrawal method',
    wallet: 'Wallet',
    sum: 'Sum',
    no_data: 'No data',
    get_link: 'Get a link',
    records_count: 'Number of records',
    enter: 'Enter the platform',
    forgot_password: 'Forgot your password?',
    enter2: 'Log in',
    no_account: 'No account?',
    go_reg: 'Register',
    registration: 'Registration',
    telegram_nick: 'Telegram nickname',
    skype_nick: 'Skype nickname',
    email_address: 'Email address',
    have_account: 'Have an account?',
    have_account_enter: 'Log in',
    onreg: 'Register',
    reset_password: 'Password recovery',
    send_request: 'Send a request',
    check_mail: 'Check the mailbox, the password recovery link has been sent.',
    order_payout: 'Order payment',
    show_more: 'Show more...',
    add_sids: 'Add seeds:',
    link_campaign: 'Campaign link:',
    get_access: 'Get access',
    copied: 'Copied',
    app: 'Apps',
    apps: 'App:',
    traffic_source: 'Traffic source:',
    sharing: 'Sharing',
    pushes: 'Push',
    transactions: 'Transactions',
    refill: 'Top up',
    texting: 'Write',
    refill_balance: 'Balance replenishment',
    message_for_refill: 'To top up your balance, write to your manager.',
    general_naming: 'Universal naming',
    your_naming: 'Your naming',
    offer_link: 'Link to the offer',
    naming: 'Naming',
    advert_cabinets: 'Advertising cabinets:',
    pass_your_ad_id:
      'Enter the ID of the advertising cabinets, each with a new line without commas',
    delete: 'Delete',
    delete_all: 'Delete all',
    accounts: 'Accounts',
    operation: 'Operation',
    sharing_date: 'Date of sharing',
    problem_accounts: 'Problem accounts (errors)',
    comment: 'Comment',
    play_market_url: 'Link to Google Play Market',
    regs: 'Registrations',
    deps: 'Deposits',
    first_dep: 'First deposit',
    count: 'Amount, pcs',
    installs: 'Installations',
    rate: 'Expense',
    rates: 'Indicators',
    days: 'Days',
    weeks: 'Weeks',
    landing: 'Landing',
    autopush: 'Autopush',
    create_new_push: 'Create a new push',
    title: 'Title',
    time: 'Time',
    incl: 'On/Off',
    text: 'Text',
    events: 'Events',
    install: 'Install',
    pass_title: 'Enter a title',
    notification_text: 'Notification text',
    for_example: 'For example',
    preview_image: 'Preview image',
    back_image: 'Background image',
    monday: 'Mon',
    tuesday: 'Tue',
    wednesday: 'Wed',
    thursday: 'Thu',
    friday: 'Fri',
    Saturday: 'Sat',
    Sunday: 'Sun',
    sending_time: 'Sending time',
    gmt_sending_time:
      'The time of sending by GMT+1, take into account the time zone for your geo',
    square:
      'The image that will be placed to the right of the text and displayed in the notification preview. Size - square 1:1',
    square2:
      'The image that is displayed in the open (extended) notification under the notification text. Size - rectangle 2:1',
    url_camp_tooltip:
      'URL of the campaign from the PP or from your tracker or PP. The {click_id} parameter is needed to optimize for events. Example:',
    url_track_camp_tooltip:
      'URL of the campaign from the PP or from your tracker. Example:',
    url_track_camp_tooltip2:
      'The URL of the campaign from our PP or from the web tracker. If this is a link from the web, then it is necessary to substitute ${click_id} in some of the fields so that the web sends us postbacks to TDS. To transfer subs from naming, use ${sub1}, ${sub2}, ${sub3}, ${sub4}, ${sub5}, ${sub6}, ${sub7}. Example:',
    naming_tooltip: `In naming, you can substitute YOUR OWN values instead of sub1, sub2, ..., sub5. You can't use underscores in subs _`,
    first_naming_tooltip: `You can't change the first part of the naming before _!!!`,
    subs:
      'To transfer subs from naming to the link to the offer, you need to substitute the available subs.',
    available_subs: 'Available subs:',
    param: 'Parameter',
    need_click_id:
      'it is necessary to transfer our click ID to your tracker so that you can send events to it (registration and deposit).',
    add_new_rule: 'Add a new rule',
    optimization: 'Postbacks (optimization):',
    for_optimization:
      'To optimize for events and view statistics, you need to set up postbacks from your tracker to our TDS.',
    postback_available_params: 'Available parameters in postback:',
    click_id_tds: 'Click ID in our TDS',
    deposit_sum:
      'Deposit amount. If it is equal to 0, it means there was a Registration',
    deposit_currency: 'Deposit currency (for example: USD)',
    postback_reg_example: 'Example of a postback for Registration:',
    postback_dep_example: 'Example of a postback for Deposit:',
    audience_text:
      'We automatically transfer the audience of users who have made a deposit to ALL GEO organics available to you for the selected application.',
    validation_text: 'The field must be filled in',
    validation_text_url: 'The field must be a valid URL',
    validation_text_email_password: 'Invalid e-mail or password',
    validation_text_email: 'Invalid e-mail',
    validation_text_password: 'The password must contain 6 or more characters',

    unknown_error_occured: 'Oops! An unknown error has occurred.',
    validation_text_email_exists: 'This email is already registered',
    validation_text_payment_system: 'Select payment system',
    validation_text_payment_system_network: 'Select payment system network',
    validation_text_payment_currency: 'Select currency',
    validation_text_payment_account: 'Enter account or card number',
    validation_text_tds_rule_url:
      'The link must start with http:// or https://',
    validation_text_app_campaign_name: 'Enter stream name',
    validation_text_app_campaign_app: 'Choose an app',
    validation_text_app_campaign_source: 'Select a traffic source',
    validation_text_campaign_name: 'Enter stream name',
    validation_text_campaign_offer: 'Choose an offer',
    validation_text_campaign_source: 'Select a traffic source',
    validation_text_campaign_domain: 'Select a redirect domain',
    validation_text_campaign_geo: 'Select a geo',
    validation_text_campaign_promo: 'Select a landing page',
    validation_text_push_app: 'Select App',
    validation_text_email_push_title: 'Specify push title',
    validation_text_email_push_message: 'Specify push text',
    validation_text_email_push_geo: 'Select a geo',
  },
  pl: {
    timezone: 'Strefa czasowa',
    enter3: 'Wchodzić',
    marketing_network: 'SIEĆ MARKETINGU WYDAJNEGO',
    show_more2: 'Pokaż więcej',
    best_offers_diff_geo: 'Najlepsze oferty, różne geo',
    mindep: 'mindep',
    baseline: 'baseline',

    fact_stats: 'Dane faktyczne',
    fact_stats_tooltip:
      'Rzeczywiste statystyki pokazują wpłaty w dniach, w których zostały faktycznie dokonane. Statystyki historyczne pokazują rejestracje i depozyty w dniu kliknięcia.',

    choose_offer: 'Wybierz ofertę',
    choose_campaign: 'Wybierz kampanię',
    choose_geo: 'Wybierz kraj',
    choose_sid: 'Wybierz stronę',
    choose_landing: 'Wybierz stronę docelową',
    apply: 'Zastosuj',
    today: 'Dziś',
    yesterday: 'Wczoraj',
    current_week: 'Obecny tydzień',
    last_week: 'Zeszły tydzień',
    current_month: 'Obecny miesiąc',
    last_month: 'W zeszłym miesiącu',
    current_year: 'Rok bieżący',
    last_year: 'Ubiegły rok',
    all_time: 'Cały czas',
    january: 'Styczeń',
    february: 'Luty',
    march: 'Marzec',
    april: 'Kwiecień',
    may: 'Maj',
    june: 'Czerwiec',
    july: 'Lipiec',
    august: 'Sierpień',
    september: 'Wrzesień',
    october: 'Październik',
    november: 'Listopad',
    december: 'Grudzień',

    ref_program: 'Nr ref. programu',
    referral_program: 'Program polecający',
    your_ref_link: 'Twój link referencyjny',
    referral_program_off: 'Program polecający wyłączony',
    your_referrals: 'Twoje polecenia',
    referrals: 'Polecenia',
    referral_payments: 'Naliczenia za polecenia',
    webmaster: 'Przyłącz',
    profit_approve: 'Zatwierdzanie zysku',
    profit_hold: 'Utrzymanie zysku',
    ref_link_desc1: 'przyciągnij partnerów i zdobądź',
    ref_link_desc2: 'z ich zarobków',
    offers_count: 'oferuje',

    registration_date: 'Data rejestracji',
    conversion_date: 'Data konwersji',
    click_id: 'Click ID',
    tds_click_id: 'TDS Click ID',
    operating_system: 'System operacyjny',
    device: 'Rodzaj urządzenia',
    browser: 'Przeglądarka',
    campaign_url: 'URL kampanii',
    offer_redirect: 'Przekieruj do oferty',
    request_to_tracker: 'Prośba o śledzenie',
    sended_postbacks: 'Wysłane postbacki',
    validation_payment_account: 'Proszę wybrać konto płatnicze',
    validation_min_amount: 'Minimalna kwota to ',
    validation_payout_currency: 'Należy określić walutę wypłaty',
    decline_payout:
      'Prośba o wypłatę odrzucona. Skontaktuj się ze swoim menedżerem.',
    offer_access: 'Oferta',
    opened: 'Otwarte!',
    closed: 'Zamknięte!',
    payout_complete: 'Wypłata zakończona!',
    payout_rejected: 'Wypłata odrzucona!',
    ticket_reply: 'Nowa wiadomość dostępna w zgłoszeniu pomocy technicznej.',
    Валерий: 'Waleri',
    Богдан: 'Bogdan',
    Евгений: 'Jewhenii',
    Дмитрий: 'Dmitrij',
    Александр: 'Aleksander',
    uniqueless: 'Unikalny',
    double_dep: 'Powtarzające się depozyty',
    our_apps_traffic_hint:
      'Dodaj ten parametr, jeśli będziesz przekierowywać ruch przez swój tracker do ofert za pomocą naszych aplikacji mobilnych',
    only_my_offers: 'Tylko moje oferty',
    select_image_error: `Zdjęcie (obraz) musi mieć rozszerzenie .jpg, .png lub .gif`,
    id_for_apps:
      'Użyj tego identyfikatora do uwierzytelniania w bocie aplikacji Telegram Gambeat APPS BOT w celu wypożyczenia aplikacji',
    availables: 'Dostępne',
    network: 'Sieć',
    rows_per_page: 'Wiersze na stronę:',
    finished: 'Zakończony',
    resolved: 'Rozwiązany',
    create_ticket: 'Utwórz bilet',
    theme: 'Tytuł',
    all: 'Wszystko',
    new: 'Nowy',
    waiting_for_admin: 'Czekam na administratora',
    waiting_for_affiliate: 'Czekam na partnera',
    author: 'Autor',
    ticket_number: 'Numer biletu',
    message_text: 'Wiadomość tekstowa',
    messages_text: 'Wiadomość tekstowa',
    new_message: 'Nowa wiadomość',
    enter_message_text: 'Wpisz tekst wiadomości',
    send: 'Wyślij',
    attach_file: 'Załącz plik',
    notifications: 'Powiadomienia',
    mark_read_all: 'Oznacz wszystkie jako przeczytane',
    close_window: 'Zamknij panel',
    export_data: 'Eksportuj dane',
    overview: 'Przeglądaj',
    offer_closed: 'Oferta jest zamknięta. Skontaktuj się ze swoim menedżerem.',
    super_offers: 'Super oferty',
    create: 'Stwórz',
    total: 'Całkowity',
    text_message_specify: 'Należy określić motyw i treść biletu',
    error: 'Wystąpił błąd. Skontaktuj się ze swoim menedżerem.',
    id_offer: 'ID oferty',
    offer_name: 'Nazwa oferty',
    bid: 'Oferta',
    yevhenii: 'Jewhenii',
    conversion_id: 'Lead ID',
    country: 'Kraj',
    available: 'Dostępny',
    traffic_type: 'Rodzaj ruchu',
    condition: 'Stan',
    payout: 'Wypłata',
    add_your_domain: 'Dodaj swoją domenę',
    created_at: 'Utworzono w',
    add_new_naming: 'Dodaj nowe nazewnictwo',
    geo_organic: 'GEO (organiczne):',
    cancel: 'Anuluj',

    status_changed: 'Status wypłaty został zaktualizowany',
    reject_reason: 'Powód odrzucenia',
    welcome: 'Witamy! Aby zresetować hasło, kliknij ten link',
    offerta: 'Oferta',
    rules: 'Zasady',
    manager: 'Twój menedżer',
    offers: 'Oferty',
    statistics: 'Statystyka',
    conversions: 'Konwersje',
    campaigns: 'Strumienie',
    postbacks: 'Postback globalny',
    tools: 'Narzędzia',
    parking: 'Parkowanie domeny',
    payouts: 'Wypłaty',
    api: 'API',
    history: 'Historia',
    pay: 'Dane bankowe',
    income_hold: 'Wstrzymanie dochodów',
    income_revshare: 'Przychód w RevShare (Wstrzymany)',
    pass_balance: 'Przeniesione do salda',
    balance_hold: 'Utrzymanie salda',
    offer_id: 'Identyfikator oferty',
    support: 'Wsparcie',
    question: 'Zadaj pytanie',
    get_questions: 'Czy masz inne pytania? Napisz do swojego menedżera.',
    balance: 'Saldo',
    hold: 'Utrzymaj',
    approve: 'Zatwierdź',
    access: 'Poproś o dostęp',
    had_access: 'Prośba o dostęp',
    create_campaign: 'Utwórz strumień',
    name: 'Nazwa',
    geos: 'Kraje',
    Vertical: 'Pionowy',
    category: 'Kategoria',
    reward_type: 'Typ konwersji',
    campaign: 'Strumień',
    offer: 'Oferta',
    date: 'Data',
    groupBy: 'Grupuj według',
    general: 'Dom',
    gambling: 'Hazard',
    landings: 'Strony docelowe',
    profile: 'Profil',
    exit: 'Wyjście',
    postback_tools: 'Ustawiania postbacków globalnych',
    reference: 'Pomoc',
    add_postback: 'Dodaj postback',
    save: 'Zapisz',
    approved_lead: 'AZatwierdzony lead',
    reg: 'Rejestracja',
    dep: 'Depozyt',
    revshare: 'Revshare',
    rejected: 'Odrzucony',
    trash: 'Kosz',
    settings: 'Ustawienia',
    logs: 'Dzienniki',
    log_sended: 'Dziennik wysłanych postbacków',
    event: 'Wydarzenie',
    status: 'Status',
    link: 'Link',
    datetime: 'Data/godzina',
    fullname: 'Nazwa',
    change: 'Zmiana',
    edit: 'Edytuj',
    email: 'E-mail',
    download_new: 'Prześlij nowe zdjęcie',
    download_photo: 'Prześlij obraz nie większy niż 5 MB',
    own_data: 'Dane osobiste',
    password: 'Hasło',
    pay_data: 'Dane dotyczące płatności',
    cur_password: 'Aktualne hasło',
    new_password: 'Nowe hasło',
    add_pay_method: 'Dodaj nową metodę wypłaty',
    add_new_method: 'Nowa metoda wypłaty',
    payment_system: 'System płatności',
    currency: 'Waluta',
    billing: 'Numer konta',
    add: 'Dodaj',
    edit_profile: 'Edytuj profil',
    messenger: 'Messenger',
    base_currency: 'Waluta konta bazowego',
    nick: 'Nickname',
    labels: 'Etykiety',
    income: 'Dochód',
    campaign_name: 'Nazwa strumienia',
    actions: 'Działania',
    create_new_campaign: 'Utwórz nowy strumień',
    active: 'Aktywny',
    stopped: 'Zatrzymany',
    new_campaign: 'Nowy strumień',
    source: 'Źródło',
    domain: 'Domena',
    your_domain: 'Twoja domena',
    geo: 'Geo',
    traffic: 'Ruch',
    clicks: 'Hit',
    uniqs: 'Host',
    leads: 'Leads',
    finances: 'Finanse',
    approved: 'Zatwierdzony',
    rejects: 'Odrzucony',
    postback_setting_info: 'Informacje o konfiguracji postbacka',
    params: 'Parametry:',
    event_type:
      'Typ zdarzenia (reg - przy rejestracji, first_dep - przy pierwszym depozycie, revshare - przy przychodach z revshare)',
    id_campaign: 'Identyfikator strumienia',
    all_status: 'Stan (zatwierdzony, oczekujący, wstrzymany, odrzucony, kosz)',
    amount_sum:
      'Kwota paragonu (CPA - kwota dochodu, dochód RevShare na godzinę)',
    general_income_revshare: 'Całkowity dochód za RevShare',
    amount_currency: 'Waluta paragonu (usd, eur, rub, uah)',
    deposit_id:
      'unikalny identyfikator depozytu lub innego zdarzenia docelowego',
    unix_timestamp: 'Akcja generowania uniksowego znacznika czasu',
    copy: 'Kopiuj',
    instruction_parking: 'Instrukcje dotyczące konfiguracji parkingu',
    for_add_domain: 'Aby dodać domenę, musisz:',
    first_inst: '1) zarejestrować domenę u dowolnego rejestratora',
    second_inst: '2) przejść do sekcji edycji DNS',
    third_inst: '3) zarejestrować wpis CNAME dla domeny lub jej subdomeny:',
    four_inst: '4) wybrać jako Docelowy cel przekierowania:',
    fifth_inst:
      '5) dodać domenę w interfejsie poniżej lub podczas tworzenia strumienia',
    dns: `Po zaktualizowaniu rekordów DNS domeny będzie można używać ich w linkach do materiałów promocyjnych.`,
    update_dns:
      'Aktualizacja rekordów DNS trwa zwykle od kilku minut do 12 godzin.',
    your_balance: 'Twój balans',
    payment_history: 'Historia płatności',
    payment_method: 'Metoda wypłaty',
    wallet: 'Portfel',
    sum: 'Suma',
    no_data: 'Brak danych',
    get_link: 'Uzyskaj link',
    records_count: 'Liczba rekordów',
    enter: 'Wejdź na platformę',
    forgot_password: 'Zapomniałeś hasła?',
    enter2: 'Zaloguj sie',
    no_account: 'Brak konta?',
    go_reg: 'Zarejestruj się',
    registration: 'Rejestracja',
    telegram_nick: 'Nickname na Telegramie',
    skype_nick: 'Nickname na Skype',
    email_address: 'Adres e-mail',
    have_account: 'Masz już konto?',
    have_account_enter: 'Zaloguj sie',
    onreg: 'Zarejestruj się',
    reset_password: 'Odzyskiwanie hasła',
    send_request: 'Wyślij zapytanie',
    check_mail:
      'Sprawdź skrzynkę pocztową, link do odzyskiwania hasła został wysłany.',
    order_payout: 'Płatność za zamówienie',
    show_more: 'Pokaż więcej...',
    add_sids: 'Dodaj seeds:',
    link_campaign: 'Link do strumienia:',
    get_access: 'Uzyskaj dostęp',
    copied: 'Skopiowane',
    app: 'Aplikacje',
    apps: 'Aplikacja:',
    traffic_source: 'Źródło ruchu:',
    sharing: 'Sharing',
    pushes: 'Push',
    transactions: 'Transakcje',
    refill: 'Uzupełnienie',
    texting: 'Napisz',
    refill_balance: 'Uzupełnianie salda',
    message_for_refill: 'Aby uzupełnić saldo, napisz do swojego menedżera.',
    general_naming: 'Uniwersalne nazewnictwo',
    your_naming: 'Twoje nazewnictwo',
    offer_link: 'Link do oferty',
    naming: 'Nazewnictwo',
    advert_cabinets: 'Advertising cabinets:',
    pass_your_ad_id:
      'Wprowadź identyfikator advertising cabinets, każdy w nowej linijce bez przecinków',
    delete: 'Usuń',
    delete_all: 'Usuń wszystko',
    accounts: 'Konta',
    operation: 'Operacja',
    sharing_date: 'Data udostępnienia',
    problem_accounts: 'Konta problematyczne (błędy)',
    comment: 'Komentarz',
    play_market_url: 'Połącz z Google Play Market',
    regs: 'Rejestracje',
    deps: 'Depozyty',
    first_dep: 'Pierwszy depozyt',
    count: 'Ilość, szt',
    installs: 'Instalacje',
    rate: 'Koszt',
    rates: 'Wskaźniki',
    days: 'Dni',
    weeks: 'Tygodnie',
    landing: 'Landing',
    autopush: 'Autopush',
    create_new_push: 'Utwórz nowy push',
    title: 'Tytuł',
    time: 'Czas',
    incl: 'Wł/Wył',
    text: 'Tekst',
    events: 'Wydarzenia',
    install: 'Zainstaluj',
    pass_title: 'Wpisz tytuł',
    notification_text: 'Tekst powiadomienia',
    for_example: 'Na przykład',
    preview_image: 'Podgląd obrazu',
    back_image: 'Zdjęcie w tle',
    monday: 'Pon',
    tuesday: 'Wt',
    wednesday: 'Śr',
    thursday: 'Czw',
    friday: 'Pt',
    Saturday: 'Sat',
    Sunday: 'Nie',
    sending_time: 'Czas wysyłania',
    gmt_sending_time:
      'Czas wysłania przez GMT+1, weź pod uwagę strefę czasową dla swojego geo',
    square:
      'Obraz, który zostanie umieszczony po prawej stronie tekstu i wyświetlony w podglądzie powiadomienia. Rozmiar - kwadrat 1:1',
    square2:
      'Obraz wyświetlany w otwartym (rozszerzonym) powiadomieniu pod tekstem powiadomienia. Rozmiar - prostokąt 2:1',
    url_camp_tooltip:
      'URL kampanii z PP lub z Twojego trackera lub PP. Parametr {click_id} jest potrzebny do optymalizacji pod kątem zdarzeń. Przykład:',
    url_track_camp_tooltip:
      'URL kampanii z PP lub z Twojego trackera. Przykład:',
    url_track_camp_tooltip2:
      'Adres URL kampanii z naszego PP lub z narzędzia do śledzenia sieci. Jeśli jest to link z sieci, należy w niektórych polach zastąpić ${click_id}, aby witryna wysyłała nam postbacki do TDS. Aby przenieść subskrypcje z nazwy, użyj ${sub1}, ${sub2}, ${sub3}, ${sub4}, ${sub5}, ${sub6}, ${sub7}. Przykład:',
    naming_tooltip: `W nazewnictwie możesz zastąpić WŁASNE wartości zamiast sub1, sub2, ..., sub5. Nie możesz używać podkreśleń w napisach _`,
    first_naming_tooltip: `Nie możesz zmienić pierwszej części nazewnictwa przed _!!!`,
    subs:
      'Aby przenieść subskrypcje z nazewnictwa na link do oferty, musisz zastąpić dostępne subskrypcje.',
    available_subs: 'Dostępne subskrypcje:',
    param: 'Parametr',
    need_click_id:
      'konieczne jest przeniesienie naszego Click ID do Twojego trackera, abyś mógł wysyłać do niego zdarzenia (rejestracja i depozyt).',
    add_new_rule: 'Dodaj nową regułę',
    optimization: 'Postbacki (optymalizacja):',
    for_optimization:
      'Aby zoptymalizować pod kątem zdarzeń i wyświetlić statystyki, musisz skonfigurować postbacki z Twojego trackera do naszego TDS.',
    postback_available_params: 'Dostępne parametry w postbacku:',
    click_id_tds: 'Click ID w naszym TDS',
    deposit_sum:
      'Kwota depozytu. Jeśli jest równa 0, oznacza to, że nastąpiła Rejestracja',
    deposit_currency: 'Waluta depozytu (na przykład: USD)',
    postback_reg_example: 'Przykład postbacku do Rejestracji:',
    postback_dep_example: 'Przykład postbacku dla Depozytu:',
    audience_text:
      'Automatycznie przenosimy użytkowników, którzy dokonali wpłaty do WSZYSTKICH organicznych produktów GEO dostępnych dla wybranej aplikacji.',
    validation_text: 'Pole musi być wypełnione',
    validation_text_url: 'Pole musi być prawidłowym adresem URL',
    validation_text_email_password: 'Nieprawidłowy e-mail lub hasło',
    validation_text_email: 'Niepoprawny email',
    validation_text_password: 'Hasło musi zawierać 6 lub więcej znaków',

    unknown_error_occured: 'Ups! Wystąpił nieznany błąd',
    validation_text_email_exists: 'Ten e-mail jest już zarejestrowany',
    validation_text_payment_system: 'Wybierz system płatności',
    validation_text_payment_system_network: 'Wybierz sieć systemu płatności',
    validation_text_payment_currency: 'Wybierz walutę',
    validation_text_payment_account: 'Wprowadź numer konta lub karty',
    validation_text_tds_rule_url:
      'Link musi zaczynać się od http:// lub https://',
    validation_text_app_campaign_name: 'Wprowadź nazwę strumienia',
    validation_text_app_campaign_app: 'Wybierz aplikację',
    validation_text_app_campaign_source: 'Wybierz źródło ruchu',
    validation_text_campaign_name: 'Wprowadź nazwę strumienia',
    validation_text_campaign_offer: 'Wybierz ofertę',
    validation_text_campaign_source: 'Wybierz źródło ruchu',
    validation_text_campaign_domain: 'Wybierz domenę przekierowania',
    validation_text_campaign_geo: 'Wybierz obszar geograficzny',
    validation_text_campaign_promo: 'Wybierz stronę docelową',
    validation_text_push_app: 'Wybierz aplikację',
    validation_text_email_push_title: 'Określ tytuł push',
    validation_text_email_push_message: 'Określ tekst push',
    validation_text_email_push_geo: 'Wybierz obszar geograficzny',
  },
  fr: {
    timezone: 'Fuseau horaire',
    enter3: 'Enter',
    marketing_network: 'RÉSEAU MARKETING À LA PERFORMANCE',
    show_more2: 'Montre plus',
    best_offers_diff_geo: 'Meilleures offres, différentes géo',
    mindep: 'mindep',
    baseline: 'baseline',

    fact_stats: 'Données factuelles',
    fact_stats_tooltip:
      'Les statistiques réelles montrent les dépôts les jours où ils ont été effectivement effectués. Les statistiques historiques montrent les inscriptions et les dépôts le jour du clic.Les statistiques réelles montrent les dépôts les jours où ils ont été effectivement effectués. Les statistiques historiques montrent les inscriptions et les dépôts le jour du clic.',

    choose_offer: `Choisissez l'offre`,
    choose_campaign: 'Choisissez la campagne',
    choose_geo: 'Choisissez un pays',
    choose_sid: 'Choisissez côté',
    choose_landing: `Choisissez l'atterrissage`,
    apply: 'Appliquer',
    today: `Aujourd'hui`,
    yesterday: 'Hier',
    current_week: 'Cette semaine',
    last_week: 'La semaine dernière',
    current_month: 'Mois en cours',
    last_month: 'Le mois dernier',
    current_year: 'Année actuelle',
    last_year: `L'année dernière`,
    all_time: 'Tout le temps',
    january: 'Janvier',
    february: 'Février',
    march: 'Mars',
    april: 'Avril',
    may: 'Peut',
    june: 'Juin',
    july: 'Juillet',
    august: 'Août',
    september: 'Septembre',
    october: 'Octobre',
    november: 'Novembre',
    december: 'Décembre',

    ref_program: 'Réf. programme',
    referral_program: 'Programme de parrainage',
    your_ref_link: 'Votre lien de référence',
    referral_program_off: 'Programme de parrainage désactivé',
    your_referrals: 'Vos filleuls',
    referrals: 'Parrainages',
    referral_payments: 'Cumuls de parrainage',
    webmaster: 'Affilier',
    profit_approve: 'Bénéfice approuver',
    profit_hold: 'Blocage des bénéfices',
    ref_link_desc1: 'attirer des partenaires et obtenir',
    ref_link_desc2: 'de leurs gains',
    offers_count: 'des offres',

    registration_date: `Date d'inscription`,
    conversion_date: 'Date de conversion',
    click_id: 'ID de clic',
    tds_click_id: 'ID de clic TDS',
    operating_system: 'Système opérateur',
    device: `Type d'appareil`,
    browser: 'Navigateur',
    campaign_url: 'URL de la campagne',
    offer_redirect: `Rediriger vers l'offre`,
    request_to_tracker: 'Demande de suivi',

    sended_postbacks: 'Publications envoyées',
    validation_payment_account: 'Veuillez sélectionner un numéro de compte',
    validation_min_amount: 'Le montant minimum de paiement est ',
    validation_payout_currency: 'Veuillez sélectionner la devise de paiement',
    decline_payout:
      'Demande de paiement refusée. Veuillez contacter votre gestionnaire.',
    offer_access: `l'accès à l'offre `,
    opened: 'est ouvert!',
    closed: 'est fermé!',
    payout_complete: 'Paiement terminé!',
    payout_rejected: 'Paiement rejeté!',
    ticket_reply: `Nouveau message disponible dans le ticket d'assistance.`,
    Валерий: 'Valéry',
    Богдан: 'Bogdan',
    Евгений: 'Evgeny',
    Дмитрий: 'Dmitri',
    Александр: 'Alexandre',
    uniqueless: 'Unique',
    double_dep: 'Dépôts répétés',
    our_apps_traffic_hint:
      'Ajoutez ce paramètre si vous allez générer du trafic via votre tracker vers nos offres en utilisant nos applications',
    only_my_offers: 'Uniquement mes offres',
    select_image_error: `L'image doit avoir l'extension .jpg, .png ou .gif`,
    id_for_apps: `Utilisez cet ID pour l'authentification dans Telegram bot Gambeat APPS BOT pour louer des applications`,
    availables: 'Disponible',
    network: 'Réseau',
    rows_per_page: 'Lignes par page:',
    finished: 'Terminé',
    resolved: 'Terminé',
    create_ticket: 'Créer un billet',
    theme: 'Titre',
    all: 'Tout',
    new: 'Nouveau',
    waiting_for_admin: `En attente de l'administrateur`,
    waiting_for_affiliate: `En attente d'affiliation`,
    author: 'Auteur',
    ticket_number: 'Numéro de billet',
    message_text: 'Texte du message',
    messages_text: 'Texte du message',
    new_message: 'Nouveau message',
    enter_message_text: 'Saisissez le texte du message',
    send: 'Envoyer',
    attach_file: 'Pièce jointe',
    notifications: 'Avis',
    mark_read_all: 'Tout marquer comme lu',
    close_window: 'Fermer le panneau',
    export_data: 'Exporter des données',
    overview: 'Aperçu',
    offer_closed: `L'offre est fermée. Veuillez contacter votre gestionnaire.`,
    super_offers: 'Super offres',
    create: 'Créer',
    total: 'Total',
    text_message_specify: `L'objet et le message doivent être remplis!`,
    error: 'Une erreur est survenue. Veuillez contacter votre gestionnaire.',
    id_offer: `ID de l'offre`,
    offer_name: `Nom de l'offre`,
    bid: 'Taux',
    yevhenii: 'Evgueni',
    conversion_id: 'Conversion ID',
    country: 'Le pays',
    available: 'Disponible',
    traffic_type: 'Type de trafic',
    condition: 'état',
    payout: 'Payer',
    add_your_domain: 'Ajouter votre domaine',
    created_at: 'Date de création',
    add_new_naming: 'Ajouter un nouveau nom',
    geo_organic: 'GEO (organic):',
    cancel: 'Annuler',

    status_changed: 'Le statut du paiement a été mis à jour',
    reject_reason: 'Reject Reason',
    welcome:
      'Bienvenue ! Pour réinitialiser votre mot de passe, suivez ce lien',
    offerta: 'Offerta',
    rules: 'Règles',
    manager: 'Votre manager',
    offers: 'Offres',
    offer: 'Offre',
    statistics: 'Les statistiques',
    conversions: 'Conversions',
    campaigns: ` Les 'lux`,
    postbacks: 'Global postbacks',
    tools: 'Outils',
    parking: 'Domaines de stationnement',
    payouts: 'Paiements',
    api: 'API',
    histoire: 'Histoire',
    pay: 'Props',
    income_hold: 'Income Hold',
    income_revshare: 'RevShare income (Hold)',
    pass_balance: 'Transféré à la balance',
    balance_hold: 'Balance Hold',
    offer_id: `ID de l'offre`,
    support: 'Support',
    question: 'Posez une question',
    get_questions: `Avez-vous d'autres questions ? Écrivez à votre responsable.`,
    balance: 'Balance',
    hold: 'Hold',
    approve: 'Aprouw',
    access: `Demande d'accès`,
    had_access: 'Accès demandé',
    create_campaign: 'Créer un fil de discussion',
    name: 'Nom',
    geos: 'Pays',
    vertical: 'Vertical',
    category: 'Catégorie',
    reward_type: 'Type de conversion',
    campaign: `Le 'flux`,
    offrer: 'Offre',
    date: 'Date',
    groupBy: 'Group by',
    general: 'Général',
    gambling: `Jeux d'argent`,
    landings: 'Landings',
    profile: 'Profil',
    exit: 'Exit',
    postback_tools: 'Global Postback Settings',
    reference: 'Aide',
    add_postback: 'Add Postback',
    save: 'Save',
    approved_lead: 'Piste approuvée',
    reg: 'Enregistrement',
    dep: 'Dépôt',
    revshare: 'Revshare',
    rejected: 'Rejeté',
    trash: 'Poubelle',
    settings: 'Paramètres',
    logs: 'Logs',
    log_sended: 'Journal des retours postaux envoyés',
    event: 'Événement',
    status: 'Status',
    link: 'lien',
    datetime: 'Date/Time',
    fullname: 'Name',
    change: 'Change',
    edit: 'Edit',
    email: 'Email',
    download_new: 'Télécharger une nouvelle photo',
    download_photo: `Téléchargez une image jusqu'à 5 Mo ici`,
    own_data: 'Données personnelles',
    password: 'Password',
    pay_data: 'Données de paiement',
    cur_password: 'Mot de passe actuel',
    new_password: 'Nouveau mot de passe',
    add_pay_method: 'Ajouter une nouvelle méthode de paiement',
    add_new_method: 'Nouvelle méthode de sortie',
    payment_system: 'Payment_system',
    devise: 'Devise',
    facturation: 'Numéro de compte',
    add: 'Add',
    edit_profile: 'Modifier le profil',
    messager: 'Messager',
    base_currency: 'Devise de base du compte',
    nick: 'Nick',
    labels: 'Tags',
    revenu: 'Revenu',
    campaign_name: 'Nom du fil',
    actions: 'Actions',
    create_new_campaign: 'Créer un nouveau fil de discussion',
    active: 'Active',
    stopped: 'Stopped',
    new_campaign: 'Nouveau fil',
    source: 'Source',
    domaine: 'Domaine',
    your_domain: 'Votre domaine',
    geo: 'Geo',
    trafic: 'Traffic',
    clics: 'Hit',
    uniqs: 'Host',
    leads: 'Leeds',
    finances: 'Finances',
    approved: 'Accepté',
    rejects: 'Annulé',
    postback_setting_info: 'Informations sur les paramètres de postback',
    params: 'Paramètres:',
    event_type: `Type d'événement (reg - sur inscription, first_dep - sur premier dépôt, revshare - sur revenu de revshare)`,
    id_campaign: 'Campaign ID',
    all_status: 'Statut (approuvé, en attente, en attente, rejeté, corbeille)',
    amount_sum:
      'Montant du revenu (CPA - montant du revenu, revenu RevShare pour chaque heure)',
    general_income_revshare: 'Total RevShare income',
    amount_currency: 'Devise du reçu (usd, eur, rub, uah)',
    deposit_id: `ID unique du dépôt ou d'un autre événement cible`,
    unix_timestamp: `Horodatage unix de la génération de l'action`,
    copy: 'Copy',
    instruction_parking: 'Instructions de stationnement',
    for_add_domain: 'Pour ajouter un domaine, vous devez :',
    first_inst: `1) enregistrer le domaine auprès de n'importe quel bureau d'enregistrement`,
    second_inst: `2) aller dans la section d'édition de DNS`,
    third_inst: `3) spécifier pour le domaine ou son sous-domaine l'entrée CNAME :`,
    four_inst: '4) comme cible de la redirection select:',
    fifth_inst: `5) ajouter le domaine dans l'interface ci-dessous ou lors de la création du flux`,
    dns: `Lorsque les enregistrements DNS de votre domaine seront mis à jour, vous pourrez l'utiliser dans des liens pour du matériel promotionnel.`,
    update_dns: `La mise à jour des enregistrements DNS prend généralement de quelques minutes à 12 heures.`,
    your_balance: 'Votre solde',
    history: `L' histoire`,
    clicks: 'Hit',
    payment_history: 'Historique des paiements',
    payment_method: 'Output method',
    wallet: 'Wallet',
    sum: 'Sum',
    no_data: 'Aucune donnée',
    get_link: 'Get_link',
    records_count: `Nombre d'enregistrements`,
    enter: 'Entrez dans la plate-forme',
    forgot_password: 'Vous avez oublié votre mot de passe ?',
    enter2: 'Enter',
    no_account: 'Pas de compte ?',
    go_reg: 'Registre',
    enregistrement: 'Enregistrement',
    telegram_nick: 'Surnom de Telegram',
    skype_nick: 'Surnom de Skype',
    email_address: 'Adresse électronique',
    have_account: 'Vous avez un compte ?',
    have_account_enter: 'Entrez',
    onreg: 'Register',
    reset_password: 'Restaurer le mot de passe',
    send_request: 'Envoyer une demande',
    check_mail:
      'Vérifier la boîte aux lettres, le lien de récupération du mot de passe a été envoyé',
    order_payout: 'Commander un paiement',
    show_more: 'Afficher plus...',
    add_sids: 'Add subs:',
    link_campaign: 'Campaign link:',
    get_access: 'Obtenir un accès',
    copied: 'Copied',
    app: 'Applications',
    apps: 'Application :',
    traffic_source: 'Source de trafic:',
    sharing: 'Rascharka',
    pushes: 'Pushy',
    transactions: 'Transactions',
    refill: 'Recharge',
    texting: 'Write',
    refill_balance: 'Solde de la recharge',
    message_for_refill:
      'Pour recharger votre solde, écrivez à votre responsable',
    general_naming: `Dénomination universelle`,
    your_naming: 'Votre nom',
    offre_lien: 'lien_offre',
    naming: 'Dénomination',
    advert_cabinets: 'Armoires publicitaires:',
    pass_your_ad_id:
      'Entrez les ID des bureaux de publicité, chacun sur une nouvelle ligne sans virgule',
    delete: 'Supprimer',
    delete_all: 'Supprimer tout',
    accounts: 'Comptes',
    operation: 'Opération',
    sharing_date: 'Date de partage',
    problem_accounts: 'Comptes à problèmes (erreurs)',
    comment: 'Commentaire',
    play_market_url: 'Lien vers Google Play Market',
    regs: 'Enregistrements',
    deps: 'Dépôts',
    first_dep: 'Premier dépôt',
    count: 'Quantité, pcs',
    installs: 'Installs',
    rate: 'Dépenses',
    rates: 'Indicateurs',
    days: 'Jours',
    weeks: 'Semaines',
    landing: `L'atterrissage`,
    autopush: 'Autopush',
    create_new_push: 'Créer un nouveau poinçon',
    title: 'Titre',
    time: 'Time',
    incl: 'On/Off',
    text: 'Texte',
    events: 'Events',
    install: 'Installer',
    pass_title: 'Enter title',
    notification_text: 'Texte de notification',
    for_example: 'Par exemple',
    preview_image: 'Image de prévisualisation',
    back_image: 'Image de fond',
    monday: 'Lun',
    tuesday: 'Mar',
    wednesday: 'Wed',
    thursday: 'Jeu',
    friday: 'Ven',
    saturday: 'Sam',
    sunday: 'Dim',
    sending_time: `Heure d'envoi`,
    gmt_sending_time: `L'heure d'envoi est GMT+1, tenez compte du fuseau horaire de votre région`,
    square: `L'image à placer à droite du texte et à afficher dans la notification de l'aperçu. La taille est de 1:1' carré`,
    square2: `L'image qui apparaît dans la notification ouverte (étendue) sous le texte de la notification. La taille est un rectangle de 2:1`,
    url_camp_tooltip: `URL de la campagne depuis PP ou depuis votre tracker ou PP. Le paramètre {click_id} est nécessaire pour optimiser les événements. Exemple:`,
    url_track_camp_tooltip:
      'URL de la campagne depuis PP ou votre tracker. Exemple:',
    url_track_camp_tooltip2: `URL de la campagne depuis notre PP ou notre tracker web. S'il s'agit d'un lien provenant du web, alors \${click_id} doit être substitué dans l'un des champs, afin que le web nous envoie des postbacks vers TDS. Utilisez \${sub1}, \${sub2}, \${sub3}, \${sub4}, \${sub5}, \${sub6}, \${sub7} pour transférer des subs à partir du nommage. Exemple:`,
    naming_tooltip: `Dans le nommage, au lieu de sub1, sub2, ..., sub5 vous pouvez mettre vos propres valeurs. Vous ne pouvez pas utiliser le trait de soulignement _ dans les sous-titres`,
    first_naming_tooltip: `La première partie de l'info-bulle avant _ ne peut pas être modifiée !!!`,
    subs: `Pour transférer les subs de la convention de dénomination, les subs disponibles doivent être substitués dans le lien vers l'offre`,
    available_subs: 'Available subs:',
    param: 'Paramètre',
    need_click_id:
      'nécessaire pour vous transmettre notre identifiant de clic dans le tracker afin que vous puissiez lui envoyer des événements (inscription et dépôt).',
    add_new_rule: 'Ajouter une nouvelle règle',
    optimization: 'Postbacks (optimisation):',
    for_optimisation: `Pour optimiser les événements et les statistiques d'affichage, vous devrez configurer des retours de votre tracker vers notre TDS`,
    postback_available_params: 'Paramètres disponibles en postback:',
    click_id_tds: 'click ID dans notre TDS',
    deposit_sum: `Montant du dépôt. S'il est égal à 0, cela signifie qu'il y a eu un 'enregistrement`,
    deposit_currency: 'Devise du dépôt (exemple: usd)',
    postback_reg_example: `Exemple de postback d'enregistrement:`,
    postback_dep_example: 'Deposit postback example:',
    audience_text: `Nous migrons automatiquement l'audience des utilisateurs qui ont effectué un dépôt vers TOUS les produits organiques GEO disponibles sur l'application sélectionnée.`,
    validation_text: 'Le champ doit être rempli',
    validation_text_url: 'Le champ doit être une URL valide',
    validation_text_email_password: 'Invalid email or password',
    validation_text_email: 'Invalid e-mail',
    validation_text_password:
      'Le mot de passe doit contenir 6 caractères ou plus',

    unknown_error_occured: `Oups! Une erreur inconnue s'est produite.`,
    validation_text_email_exists: 'Cet e-mail est déjà enregistré',
    validation_text_payment_system: 'Sélectionner le système de paiement',
    validation_text_payment_system_network:
      'Sélectionner le réseau du système de paiement',
    validation_text_payment_currency: 'Sélectionner la devise',
    validation_text_payment_account: 'Entrez le numéro de compte ou de carte',
    validation_text_tds_rule_url:
      'Le lien doit commencer par http:// ou https://',
    validation_text_app_campaign_name: 'Entrez le nom du flux',
    validation_text_app_campaign_app: 'Choisissez une application',
    validation_text_app_campaign_source: 'Sélectionnez une source de trafic',
    validation_text_campaign_name: 'Entrez le nom du flux',
    validation_text_campaign_offer: 'Choisissez une offre',
    validation_text_campaign_source: 'Sélectionnez une source de trafic',
    validation_text_campaign_domain: 'Sélectionnez un domaine de redirection',
    validation_text_campaign_geo: 'Sélectionner une zone géographique',
    validation_text_campaign_promo: 'Sélectionnez une page de destination',
    validation_text_push_app: `Sélectionner l'application`,
    validation_text_email_push_title: 'Spécifier le titre du push',
    validation_text_email_push_message: 'Spécifier le texte push',
    validation_text_email_push_geo: 'Sélectionner une géo',
  },
  es: {
    timezone: 'Zona horaria',
    enter3: 'Enter',
    marketing_network: 'RED DE MARKETING DE RENDIMIENTO',
    show_more2: 'Mostrar más',
    best_offers_diff_geo: 'Las mejores ofertas, diferente geo',
    mindep: 'mindep',
    baseline: 'baseline',

    fact_stats: 'Datos fácticos',
    fact_stats_tooltip:
      'Las estadísticas reales muestran los depósitos en los días en que realmente se realizaron. Las estadísticas históricas muestran registros y depósitos el día del clic.',

    choose_offer: `Elegir oferta`,
    choose_campaign: 'Elegir campaña',
    choose_geo: 'Geo',
    choose_sid: 'Elegir sub',
    choose_landing: 'Elige aterrizaje',
    apply: 'Aplicar',
    today: 'Este Dia',
    yesterday: 'El dia de ayer',
    current_week: 'Semana actual',
    last_week: 'La semana pasada',
    current_month: 'Mes actual',
    last_month: 'El mes pasado',
    current_year: 'Año corriente',
    last_year: 'El año pasado',
    all_time: 'Todo el tiempo',
    january: 'Enero',
    february: 'Febrero',
    march: 'Marzo',
    april: 'Abril',
    may: 'Mayo',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'Septiembre',
    october: 'Octubre',
    november: 'Noviembre',
    december: 'Diciembre',

    ref_program: 'Árbitro. programa',
    referral_program: 'Programa de referencia',
    your_ref_link: 'Tu enlace de referencia',
    referral_program_off: 'Programa de referidos deshabilitado',
    your_referrals: 'Tus referencias',
    referrals: 'Referencias',
    referral_payments: 'Acumulaciones de referencia',
    webmaster: 'Afiliado',
    profit_approve: 'Aprobación de beneficios',
    profit_hold: 'Retención de ganancias',
    ref_link_desc1: 'atraer socios y obtener',
    ref_link_desc2: 'de sus ganancias',
    offers_count: 'ofertas',

    registration_date: 'Fecha de Registro',
    conversion_date: 'Fecha de conversión',
    click_id: 'Haga clic en identificación',
    tds_click_id: 'ID de clic de TDS',
    operating_system: 'Operating system',
    device: 'Tipo de dispositivo',
    browser: 'Navegador',
    campaign_url: 'URL de la campaña',
    offer_redirect: 'Redirigir a oferta',
    request_to_tracker: 'Solicitud de rastreador',

    sended_postbacks: 'Devoluciones enviadas',
    validation_payment_account: 'Por favor seleccione un número de cuenta',
    validation_min_amount: 'La cantidad mínima de pago es ',
    validation_payout_currency: 'Seleccione la moneda de pago',
    decline_payout:
      'Solicitud de pago denegada. Póngase en contacto con su gerente.',
    offer_access: 'El acceso a la oferta',
    opened: 'está abierto!',
    closed: 'está cerrado!',
    payout_complete: 'Pago completo',
    payout_rejected: 'Pago rechazado!',
    ticket_reply: 'Nuevo mensaje disponible en ticket de soporte.',
    Валерий: 'Valery',
    Богдан: 'Bogdán',
    Евгений: 'Evgenio',
    Дмитрий: 'Dmitri',
    Александр: 'Alejandro',
    uniqueless: 'Único',
    double_dep: 'Repetir depósitos',
    our_apps_traffic_hint:
      'Agregue este parámetro si va a dirigir el tráfico a través de su rastreador a nuestras ofertas usando nuestras aplicaciones',
    only_my_offers: 'Solo mis ofertas',
    select_image_error: 'La imagen debe tener la extensión .jpg, .png o .gif',
    id_for_apps: `Use esta ID para la autenticación en Telegram bot Gambeat APPS BOT para alquilar aplicaciones`,
    availables: 'Disponible',
    network: 'Red',
    rows_per_page: 'Filas por página:',
    finished: 'Terminado',
    resolved: 'Terminado',
    create_ticket: 'Crear Ticket',
    theme: 'Título',
    all: 'Todos',
    new: 'Nuevo',
    stopped: 'Detenido',
    waiting_for_admin: 'Esperando al administrador',
    waiting_for_affiliate: 'Esperando afiliado',
    author: 'Autor',
    ticket_number: 'Numero de ticket',
    message_text: 'Mensaje de texto',
    messages_text: 'Mensaje de texto',
    new_message: 'Nuevo mensaje',
    enter_message_text: 'Ingrese el texto del mensaje',
    send: 'Enviar',
    attach_file: 'Adjuntar archivo',
    notifications: 'Notificaciones',
    mark_read_all: 'Marcar todo como leido',
    close_window: 'Cerrar panel',
    export_data: 'Exportar datos',
    overview: 'Visión de conjunto',
    offer_closed: 'La oferta está cerrada. Póngase en contacto con su gerente.',
    super_offers: 'Súper ofertas',
    create: 'Crear',
    total: 'Total',
    text_message_specify: '¡El asunto y el mensaje deben ser completados!',
    error: 'Se ha producido un error. Póngase en contacto con su gerente.',
    id_offer: `ID de la oferta`,
    offer_name: `Nombre de la oferta`,
    bid: 'Licitación',
    yevhenii: 'Evgeny',
    conversion_id: 'Conversión ID',
    country: 'El país',
    available: 'Disponible',
    traffic_type: 'Tipo de tráfico',
    condition: 'Condición',
    payout: 'Pagar',
    add_your_domain: 'Agrega tu dominio',
    created_at: 'Fecha de creación',
    add_new_naming: 'Añadir nuevo nombre',
    geo_organic: 'GEO (organic):',
    cancel: 'Cancelar',

    status_changed: 'Estado de pago actualizado',
    reject_reason: 'Motivo de reject',
    welcome:
      '¡Bienvenido! Para restablecer la contraseña, haga clic en este enlace',
    offerta: 'Oferta',
    rules: 'Reglas',
    manager: 'Tu Manager',
    offers: 'Offers',
    statistics: 'Estadísticas',
    conversions: 'Conversiones',
    campaigns: 'Campaigns',
    postbacks: 'Postback global',
    tools: 'Herramientas',
    parking: 'Estacionamiento de dominios',
    payouts: 'Pagos',
    api: 'API',
    history: 'Historia',
    pay: 'Apoyos',
    income_hold: 'Ingresos Hold',
    income_revshare: 'Ingresos RevShare (Hold)',
    pass_balance: 'Transferido al saldo',
    balance_hold: 'Balance Hold',
    offer_id: 'Offer ID',
    support: 'Apoyo',
    question: 'Hacer una pregunta',
    get_questions: '¿Tienes más preguntas? Escriba A su gerente.',
    balance: 'Balance',
    hold: 'Hold',
    approve: 'Aprove',
    access: 'Solicitar acceso',
    had_access: 'Acceso solicitado',
    create_campaign: 'Crear flujo',
    name: 'Nombre',
    geos: 'Países',
    vertical: 'Vertical',
    category: 'Categoría',
    reward_type: 'Tipo de conversión',
    campaign: 'Stream',
    offer: 'Offer',
    date: 'Fecha',
    groupBy: 'Agrupar por',
    general: 'General',
    gambling: 'Gambling',
    landings: 'Landings',
    profile: 'Perfil',
    exit: 'Salir',
    postback_tools: 'Configuración de Postback Global',
    reference: 'Ayuda',
    add_postback: 'Agregar Postback',
    save: 'Guardar',
    approved_lead: 'Lead Confirmado',
    reg: 'Registro',
    dep: 'Depósito',
    revshare: 'Revshara',
    rejected: 'Rechazado',
    trash: 'Thrash',
    settings: 'Ajustes',
    logs: 'Logs',
    log_sended: 'Log de postbacks enviados',
    event: 'Evento',
    status: 'Estado',
    link: 'Enlace',
    datetime: 'Fecha / Hora',
    fullname: 'Nombre',
    change: 'Cambiar',
    editar: 'Editar',
    email: 'Email',
    download_new: 'Descargar nueva foto',
    download_photo: 'Sube aquí una imagen que no pesa más de 5 MB',
    own_data: 'Datos Personales',
    password: 'Contraseña',
    pay_data: 'Datos de Pago',
    cur_password: 'Contraseña Actual',
    new_password: 'Nueva contraseña',
    add_pay_method: 'Añadir nuevo método de salida',
    add_new_method: 'Nuevo método de salida',
    payment_system: 'Sistema de Pago',
    currency: 'Moneda',
    billing: 'Número de cuenta',
    add: 'Añadir',
    edit_profile: 'Editar perfil',
    messenger: 'Mensajero',
    base_currency: 'Moneda base de la cuenta',
    nick: 'Nick',
    labels: 'Etiquetas',
    income: 'Ingresos',
    campaign_name: 'Nombre del flujo',
    actions: 'Acciones',
    create_new_campaign: 'Crear un nuevo hilo',
    active: 'Activo',
    detenido: 'Detenidos',
    new_campaign: 'Nuevo hilo',
    source: 'Fuente',
    domain: 'Dominio',
    your_domain: 'Tu dominio',
    geo: 'Geo',
    traffic: 'Tráfico',
    clicks: 'Hit',
    uniqs: 'Host',
    leads: 'Leads',
    finances: 'Finanzas',
    approved: 'Aceptado',
    rejects: 'Cancelado',
    postback_setting_info: 'Información de configuración de Postback',
    params: 'Parámetros:',
    event_type:
      'Tipo de evento (reg - en el registro, first_dep-en el primer depósito, revshare-en los ingresos de revshare)',
    id_campaign: 'ID de flujo',
    all_status: 'Estado (approved, pending, hold, rejected, trash)',
    amount_sum:
      'Cantidad de ingresos (CPA-cantidad de ingresos, revshare ingresos por cada hora)',
    general_income_revshare: 'Ingresos Totales por RevShare',
    amount_currency: 'Moneda de ingreso (usd, eur, rub, uah)',
    deposit_id: 'ID de depósito único u otro evento objetivo',
    unix_timestamp: 'Unix timestamp generar acción',
    copy: 'Copiar',
    instruction_parking: 'Instrucciones para configurar el estacionamiento',
    for_add_domain: 'Para agregar un dominio necesitas:',
    first_inst: '1) registrar un dominio en cualquier registrador',
    second_inst: '2) ir a la sección de edición de DNS',
    third_inst: '3) escribir para el dominio o su subdominio CNAME registro:',
    four_inst: '4) como objetivo objetivo para la redirección seleccionar:',
    fifth_inst:
      '5) añadir un dominio en la interfaz de abajo o al crear un flujo',
    dns:
      'Cuando los registros de DNS de su dominio se actualicen, podrá usarlo en enlaces de contenido publicitario.',
    update_dns:
      'la Actualización de los registros DNS generalmente toma de unos minutos a 12 horas.',
    your_balance: 'Tu saldo',
    payment_history: 'Historial de pagos',
    payment_method: 'Método de salida',
    wallet: 'Monedero',
    sum: 'Suma',
    no_data: 'Sin datos',
    get_link: 'Obtener enlace',
    records_count: 'Número de registros',
    enter: 'Entrar en la plataforma',
    forgot_password: '¿Olvidó su contraseña?',
    enter2: 'Iniciar sesión',
    no_account: 'No cuenta?',
    go_reg: 'Regístrese',
    registration: 'Registro',
    telegram_nick: 'Apodo en Telegram',
    skype_nick: 'Apodo en Skype',
    email_address: 'Dirección de correo electrónico',
    have_account: 'Tienes una cuenta?',
    have_account_enter: 'Entra',
    onreg: 'Regístrate',
    reset_password: 'Recuperación de contraseña',
    send_request: 'Enviar solicitud',
    check_mail:
      'Compruebe el buzón, se ha enviado el enlace de recuperación de contraseña.',
    order_payout: 'Ordenar pago',
    show_more: 'Mostrar más...',
    add_sids: 'Añadir sids: ',
    link_campaign: 'Enlace de flujo: ',
    get_access: 'Acceder',
    copied: 'Copiado',
    app: 'Aplicaciones',
    apps: 'Aplicación: ',
    traffic_source: 'Fuente de tráfico: ',
    sharing: 'Compartir',
    pushes: 'Pushes',
    transactions: 'Transacciones',
    refill: 'Recarga',
    texting: 'Escribir',
    refill_balance: 'Reabastecimiento de saldo',
    message_for_refill: 'Para recargar el saldo, escriba A su gerente.',
    general_naming: 'Universal neyming',
    your_naming: 'Tu nombre',
    offer_link: 'Enlace a la oferta',
    naming: 'Naming',
    advert_cabinets: 'Oficinas de publicidad:',
    pass_your_ad_id:
      'Introduzca el id de los gabinetes de publicidad, cada uno con una nueva línea sin comas',
    delete: 'Eliminar',
    delete_all: 'Eliminar todo',
    accounts: 'Cuentas',
    operation: 'Operación',
    sharing_date: 'Fecha de descarte',
    problem_accounts: 'Cuentas Problemáticas (errores)',
    comment: 'Comentario',
    play_market_url: 'Enlace en Google Play Market',
    regs: 'Registro',
    deps: 'Depósitos',
    first_dep: 'Primer depósito',
    count: 'Cantidad, Pcs',
    installs: 'Instalaciones',
    rate: 'Gasto',
    rates: 'Indicadores',
    days: 'Días',
    weeks: 'Semanas',
    landing: 'Landing',
    autopush: 'Autopush',
    create_new_push: 'Crear nuevo empuje',
    title: 'Título',
    time: 'Tiempo',
    incl: 'On / Off',
    text: 'Texto',
    events: 'Eventos',
    install: 'Install',
    pass_title: 'Introduzca el título',
    notification_text: 'Texto de alerta',
    for_example: 'Por Ejemplo',
    preview_image: 'Imagen de vista previa',
    back_image: 'Imagen de Fondo',
    monday: 'Lun',
    tuesday: 'Mar',
    wednesday: 'Mié',
    thursday: 'Jue',
    friday: 'Vi',
    saturday: 'Sáb',
    sunday: 'Dom',
    sending_time: 'Tiempo de envío',
    gmt_sending_time:
      'Hora de envío por GMT + 1, tenga en cuenta la zona horaria para Su geo',
    square:
      'Imagen que se coloca a la derecha del texto y se muestra en la vista previa de notificación. Tamaño-cuadrado 1: 1',
    square2:
      'Imagen que se muestra en la notificación abierta (extendida) debajo del texto de notificación. Tamaño-rectángulo 2: 1',
    url_camp_tooltip:
      'URL de la campaña con PP o Con su rastreador o PP. El parámetro {click_id} es necesario para la optimización por evento. Ejemplo:',
    url_track_camp_tooltip:
      'URL de la campaña con PP o Con su rastreador. Ejemplo:',
    url_track_camp_tooltip2:
      'URL de la campaña con nuestro PP o con el rastreador web. Si se trata de un enlace de la web, debe sustituir ${click_id} en alguno de los campos para que la web nos envíe postbeaks en TDS. Para transferir subs desde el nombre, use ${sub1}, ${sub2}, ${sub3}, ${sub4}, ${sub5}, ${sub6}, ${sub7}. Ejemplo:',
    naming_tooltip:
      'En el nombre en lugar de sub1, sub2, .... sub5 puede sustituir sus valores. En Sabah, no se puede usar el subrayado inferior _',
    first_naming_tooltip:
      '¡La primera parte del nombre antes de _ no se puede cambiar!!!',
    subs:
      'Para transferir subs de un nombre a un enlace a la oferta, debe sustituir los subs disponibles.',
    available_subs: 'subs Disponibles: ',
    param: 'Parámetro',
    need_click_id:
      'es necesario para enviarle al rastreador nuestro id de clic para que pueda enviarle eventos (registro y depósito).',
    add_new_rule: 'Añadir nueva regla',
    optimization: 'Postbeaks (optimización):',
    for_optimization:
      'Para optimizar los eventos y ver las estadísticas, debe configurar los postbacks de su rastreador a nuestro TDS.',
    postback_available_params: 'Opciones Disponibles en postback:',
    click_id_tds: 'Id de clic en nuestro TDS',
    deposit_sum:
      'Cantidad del depósito. Si es igual a 0, entonces hubo un Registro',
    deposit_currency: 'Moneda de depósito (por ejemplo: usd)',
    postback_reg_example: 'Ejemplo de postback de Registro: ',
    postback_dep_example: 'Ejemplo de postback en Depósito: ',
    audience_text:
      'Transferimos automáticamente la audiencia de los usuarios que han realizado un depósito a TODOS los geo orgánicos disponibles para usted en la aplicación seleccionada.',
    validation_text: 'El Campo debe estar lleno',
    validation_text_url: 'El Campo debe ser una DIRECCIÓN URL válida',
    validation_text_email_password:
      'Correo electrónico o contraseña Incorrecta',
    validation_text_email: 'Correo electrónico no Válido',
    validation_text_password: 'La Contraseña debe contener 6 o más caracteres',

    unknown_error_occured: '¡Uy! Un error desconocido a ocurrido.',
    validation_text_email_exists: 'Este correo electrónico ya está registrado',
    validation_text_payment_system: 'Seleccionar sistema de pago',
    validation_text_payment_system_network:
      'Seleccione la red del sistema de pago',
    validation_text_payment_currency: 'Seleccionar moneda',
    validation_text_payment_account: 'Ingrese número de cuenta o tarjeta',
    validation_text_tds_rule_url:
      'El enlace debe comenzar con http:// o https://',
    validation_text_app_campaign_name: 'Ingrese el nombre de la transmisión',
    validation_text_app_campaign_app: 'Elige una aplicación',
    validation_text_app_campaign_source: 'Seleccione una fuente de tráfico',
    validation_text_campaign_name: 'Ingrese el nombre de la transmisión',
    validation_text_campaign_offer: 'Elige una oferta',
    validation_text_campaign_source: 'Seleccione una fuente de tráfico',
    validation_text_campaign_domain:
      'Seleccione un dominio de redireccionamiento',
    validation_text_campaign_geo: 'Seleccione una geo',
    validation_text_campaign_promo: 'Seleccione una página de destino',
    validation_text_push_app: 'Seleccionar aplicación',
    validation_text_email_push_title: 'Especificar título de inserción',
    validation_text_email_push_message: 'Especificar texto de inserción',
    validation_text_email_push_geo: 'Seleccione una geo',
  },
  de: {
    timezone: 'Zeitzone',
    enter3: 'Enter',
    marketing_network: 'PERFORMANCE MARKETING NETZWERK',
    show_more2: 'Zeig mehr',
    best_offers_diff_geo: 'Beste Angebote, andere Geo',
    mindep: 'mindep',
    baseline: 'baseline',

    fact_stats: 'Sachliche Daten',
    fact_stats_tooltip:
      'Die tatsächlichen Statistiken zeigen die Einzahlungen an den Tagen, an denen sie tatsächlich getätigt wurden. Historische Statistiken zeigen Registrierungen und Einzahlungen am Tag des Klicks.',

    choose_offer: 'Angebot wählen',
    choose_campaign: 'Kampagne auswählen',
    choose_geo: 'Wählen Sie Geo',
    choose_sid: 'Wählen Sie sid',
    choose_landing: 'Landung wählen',
    apply: 'Sich bewerben',
    today: 'Heute',
    yesterday: 'Gestern',
    current_week: 'Diese Woche',
    last_week: 'Letzte Woche',
    current_month: 'Aktueller Monat',
    last_month: 'Im vergangenen Monat',
    current_year: 'Laufendes Jahr',
    last_year: 'Vergangenes Jahr',
    all_time: 'Alle Zeit',
    january: 'Januar',
    february: 'Februar',
    march: 'Marsch',
    april: 'April',
    may: 'Kann',
    june: 'Juni',
    july: 'Juli',
    august: 'August',
    september: 'September',
    october: 'Oktober',
    november: 'November',
    december: 'Dezember',

    ref_program: 'Ref. Programm',
    referral_program: 'Empfehlungsprogramm',
    your_ref_link: 'Ihr Empfehlungslink',
    referral_program_off: 'Empfehlungsprogramm deaktiviert',
    your_referrals: 'Ihre Empfehlungen',
    referrals: 'Empfehlungen',
    referral_payments: 'Provisionen für Empfehlungen',
    webmaster: 'Webmaster',
    profit_approve: 'Gewinngenehmigung',
    profit_hold: 'Gewinn halten',
    ref_link_desc1: 'Partner anziehen und bekommen',
    ref_link_desc2: 'aus ihrem Verdienst',
    offers_count: 'bietet an',

    registration_date: 'Registrierungsdatum',
    conversion_date: 'Umstellungsdatum',
    click_id: 'Click ID',
    tds_click_id: 'TDS Click ID',
    operating_system: 'Operating system',
    device: 'Gerätetyp',
    browser: 'Browser',
    campaign_url: 'Kampagnen-URL',
    offer_redirect: 'Weiterleitung zum Angebot',
    request_to_tracker: 'Tracker-Anfrage',

    sended_postbacks: 'Postbacks gesendet',
    validation_payment_account: 'Bitte wählen Sie eine Kontonummer aus',
    validation_min_amount: 'Der Mindestauszahlungsbetrag beträgt ',
    validation_payout_currency: 'Bitte wählen Sie die Auszahlungswährung aus',
    decline_payout:
      'Auszahlungsanforderung abgelehnt. Bitte wenden Sie sich an Ihren Vorgesetzten.',
    offer_access: 'Der Zugang zum',
    opened: 'Angebot ist offen!',
    closed: 'Angebot ist gesperrt!',
    payout_complete: 'Auszahlung abgeschlossen!',
    payout_rejected: 'Auszahlung abgelehnt!',
    ticket_reply: 'Neue Nachricht im Support-Ticket verfügbar.',
    Валерий: 'Valery',
    Богдан: 'Bogdan',
    Евгений: 'Eugeni',
    Дмитрий: 'Dmitri',
    Александр: 'Alexander',
    uniqueless: 'Einzigartig',
    double_dep: 'Einzahlungen wiederholen',
    our_apps_traffic_hint:
      'Fügen Sie diesen Parameter hinzu, wenn Sie mithilfe unserer Anwendungen Traffic über Ihren Tracker zu unseren Angeboten leiten',
    only_my_offers: 'Nur meine Angebote',
    select_image_error: 'Das Bild muss die Endung .jpg, .png oder .gif haben',
    id_for_apps: `Verwenden Sie diese ID für die Authentifizierung im Telegram-Bot Gambeat APPS BOT, um Apps zu mieten`,
    availables: 'Verfügbar',
    network: 'Netzwerk',
    rows_per_page: 'Zeilen pro Seite:',
    finished: 'Abgeschlossen',
    resolved: 'Abgeschlossen',
    create_ticket: 'Ticket erstellen',
    theme: 'Titel',
    all: 'Alles',
    new: 'Neu',
    waiting_for_admin: 'Warten auf Administrator',
    waiting_for_affiliate: 'Warten auf Partner',
    author: 'Autor',
    ticket_number: 'Ticketnummer',
    message_text: 'Nachrichtentext',
    messages_text: 'Nachrichtentext',
    new_message: 'Neue Nachricht',
    enter_message_text: 'Nachrichtentext eingeben',
    send: 'Schicken',
    attach_file: 'Datei anhängen',
    notifications: 'Benachrichtigungen',
    mark_read_all: 'Markiere alle als gelesen',
    close_window: 'Bedienfeld schließen',
    export_data: 'Export von Daten',
    overview: 'Überblick',
    offer_closed:
      'Angebot ist geschlossen. Bitte wenden Sie sich an Ihren Vorgesetzten.',
    super_offers: 'Super Angebote',
    create: 'Erstellen',
    total: 'Gesamt',
    text_message_specify: 'Betreff und Nachricht müssen ausgefüllt werden!',
    error:
      'Ein Fehler ist aufgetreten. Bitte wenden Sie sich an Ihren Vorgesetzten.',
    id_offer: 'Angebots-ID',
    offer_name: `Angebotsname`,
    bid: 'Gebot',
    yevhenii: 'Evgeni',
    conversion_id: 'Wandlung ID',
    country: 'das Land',
    available: 'Erhältlich',
    traffic_type: 'Verkehrstyp',
    condition: 'Zustand',
    payout: 'Zahlen',
    add_your_domain: 'Fügen Sie Ihre Domäne hinzu',
    created_at: 'Erstelldatum',
    add_new_naming: 'Neue Benennung hinzufügen',
    geo_organic: 'GEO (organic):',
    cancel: 'Abbrechen',

    status_changed: 'Auszahlungsstatus aktualisiert',
    reject_reason: 'Ablehnungsgrund',
    welcome:
      'Willkommen! Um Ihr Passwort zurückzusetzen, folgen Sie bitte diesem Link',
    offerta: 'Offerta',
    rules: 'Regeln',
    manager: 'Ihr Manager',
    offers: 'Angebote',
    statistics: 'Statistik',
    conversions: 'Konversionen',
    campaigns: 'Ströme',
    postbacks: 'Globale postbacks',
    tools: 'Werkzeuge',
    parking: 'Domain auch',
    payouts: 'Zahlungen',
    api: 'API',
    history: 'Geschichte',
    pay: 'Requisiten',
    income_hold: 'Einkommen halten',
    income_revshare: 'RevShare income (Hold)',
    pass_balance: 'In den Saldo überführt',
    balance_hold: 'Gleichgewicht halten',
    offer_id: 'Angebots-ID',
    support: 'Unterstützung',
    question: 'Stellen Sie eine Frage',
    get_questions:
      'Haben Sie noch weitere Fragen? Schreiben Sie an Ihren Vorgesetzten.',
    balance: 'Gleichgewicht',
    hold: 'Halten',
    approve: 'Aprouw',
    access: 'Zugang beantragen',
    had_access: 'Zugang beantragt',
    create_campaign: 'Thema erstellen',
    name: 'Name',
    geos: 'Länder',
    vertical: 'Vertikal',
    category: 'Kategorie',
    reward_type: 'Umrechnungsart',
    campaign: 'Flow',
    offer: 'Angebot',
    date: 'Datum',
    groupBy: 'Gruppieren nach',
    general: 'Allgemein',
    gambling: 'Glücksspiel',
    landings: 'Landungen',
    profile: 'Profil',
    exit: 'Beenden',
    postback_tools: 'Globale Postback-Einstellungen',
    reference: 'Hilfe',
    add_postback: 'Postback hinzufügen',
    save: 'Speichern',
    approved_lead: 'Genehmigter Hinweis',
    reg: 'Registrierung',
    dep: 'Kaution',
    revshare: 'Revshare',
    rejected: 'Abgelehnt',
    trash: 'Papierkorb',
    settings: 'Einstellungen',
    logs: 'Logs',
    log_sended: 'Protokoll der gesendeten Postbacks',
    event: 'Ereignis',
    status: 'Status',
    link: 'Link',
    datetime: 'Datum/Uhrzeit',
    fullname: 'Name',
    change: 'Ändern',
    edit: 'Bearbeiten',
    email: 'Email',
    download_new: 'Neues Foto hochladen',
    download_photo: 'Laden Sie hier ein Bild mit bis zu 5 MB hoch',
    own_data: 'Persönliche Daten',
    password: 'Passwort',
    pay_data: 'Zahlungsdaten',
    cur_password: 'Aktuelles Passwort',
    new_password: 'Neues Passwort',
    add_pay_method: 'Neue Ausgabemethode hinzufügen',
    add_new_method: 'Neue Ausgabemethode',
    payment_system: 'Payment_system',
    currency: 'Währung',
    billing: 'Kontonummer',
    add: 'Hinzufügen',
    edit_profile: 'Profil bearbeiten',
    messenger: 'Bote',
    base_currency: 'Basiswährung des Kontos',
    nick: 'Nick',
    labels: 'Tags',
    income: 'Einkommen',
    campaign_name: 'Name des Themas',
    actions: 'Aktionen',
    create_new_campaign: 'Neues Thema erstellen',
    active: 'Aktiv',
    stopped: 'Gestoppt',
    new_campaign: 'Neues Thema',
    source: 'Quelle',
    domain: 'Domäne',
    your_domain: 'Ihre Domäne',
    geo: 'Geo',
    traffic: 'Verkehr',
    clicks: 'Hit',
    uniqs: 'Host',
    leads: 'Leeds',
    finances: 'Finanzen',
    approved: 'Angenommen',
    rejects: 'Storniert',
    postback_setting_info: 'Informationen zur Postback-Einstellung',
    params: 'Parameter:',
    event_type:
      'Ereignistyp (reg - bei Anmeldung, first_dep - bei erster Einzahlung, revshare - bei 	revshare-Einnahmen)',
    id_campaign: 'Stream-ID',
    all_status: 'Status (approved, pending, hold, rejected, trash)',
    amount_sum:
      'Höhe des Einkommens (CPA - Höhe des Einkommens, RevShare Einkommen für jede Stunde)',
    general_income_revshare: 'Gesamteinkommen RevShare',
    amount_currency: 'Währung der Quittung (usd, eur, rub, uah)',
    deposit_id:
      'Eindeutige ID der Einzahlung oder eines anderen Zielereignisses',
    unix_timestamp: 'Unix-Zeitstempel der Aktionserzeugung',
    copy: 'Kopie',
    instruction_parking: 'Anweisungen zum Parken',
    for_add_domain: 'Um eine Domäne hinzuzufügen, benötigen Sie:',
    first_inst:
      '1) Registrierung der Domäne bei einer beliebigen Registrierstelle',
    second_inst: '2) gehen Sie zum Abschnitt DNS bearbeiten',
    third_inst:
      '3) geben Sie für die Domain oder ihre Subdomain den CNAME-Eintrag an:',
    four_inst: '4) als Ziel für redirect select:',
    fifth_inst:
      '5) fügen Sie die Domäne in der nachstehenden Schnittstelle oder bei der Erstellung des Datenstroms 	hinzu',
    dns:
      'Wenn die DNS-Einträge Ihrer Domain aktualisiert sind, können Sie sie in Links für Werbematerial 	verwenden.',
    update_dns:
      'Die Aktualisierung von DNS-Einträgen dauert in der Regel einige Minuten bis 12 Stunden.',
    your_balance: 'Ihr Kontostand',
    payment_history: 'Zahlungsverlauf',
    payment_method: 'Ausgabemethode',
    wallet: 'Brieftasche',
    sum: 'Summe',
    no_data: 'Keine Daten',
    get_link: 'Get_link',
    records_count: 'Anzahl der Datensätze',
    enter: 'Betreten Sie die Plattform',
    forgot_password: 'Haben Sie Ihr Passwort vergessen?',
    enter2: 'Enter',
    no_account: 'Kein Konto?',
    go_reg: 'Registrieren',
    registration: 'Registrierung',
    telegram_nick: 'Telegramm-Spitzname',
    skype_nick: 'Skype-Spitzname',
    email_address: 'E-Mail Adresse',
    have_account: 'Haben Sie ein Konto?',
    have_account_enter: 'Hereinspaziert',
    onreg: 'Registrieren',
    reset_password: 'Passwort wiederherstellen',
    send_request: 'Anfrage senden',
    check_mail: 'Check mailbox, password recovery link has been sent.',
    order_payout: 'Eine Zahlung bestellen',
    show_more: 'Mehr anzeigen...',
    add_sids: 'Sids hinzufügen:',
    link_campaign: 'Campaign link:',
    get_access: 'Zugang erhalten',
    copied: 'Kopiert',
    app: 'Anwendungen',
    apps: 'Anwendung:',
    traffic_source: 'Verkehrsquelle:',
    sharing: 'Rascharka',
    pushes: 'Aufdringlich',
    transactions: 'Vorgänge',
    refill: 'Nachfüllen',
    texting: 'Schreiben',
    refill_balance: 'Nachfüllsaldo',
    message_for_refill:
      'Um Ihr Guthaben aufzuladen, schreiben Sie an Ihren Manager.',
    general_naming: 'Universelle Namensgebung',
    your_naming: 'Ihre Namensgebung',
    angebot_link: 'Link zum Angebot',
    naming: 'Benennung',
    advert_cabinets: 'Werbeschränke:',
    pass_your_ad_id:
      'Geben Sie die IDs der Werbebüros ein, jeweils in einer neuen Zeile ohne Kommas',
    delete: 'Löschen',
    delete_all: 'Alles löschen',
    accounts: 'Konten',
    operation: 'Operation',
    sharing_date: 'Datum der gemeinsamen Nutzung',
    problem_accounts: 'Problemkonten (Fehler)',
    comment: 'Kommentar',
    play_market_url: 'Link zum Google Play Market',
    regs: 'Registrierungen',
    deps: 'Einlagen',
    first_dep: 'Erste Einzahlung',
    count: 'Menge, Stk',
    installs: 'Installs',
    rate: 'Ausgaben',
    rates: 'Indikatoren',
    days: 'Tage',
    weeks: 'Wochen',
    landing: 'Landung',
    autopush: 'Autopush',
    create_new_push: 'Einen neuen Stempel erstellen',
    title: 'Titel',
    time: 'Zeit',
    incl: 'Ein/Aus',
    text: 'Text',
    events: 'Ereignisse',
    install: 'Installieren',
    pass_title: 'Titel eingeben',
    notification_text: 'Benachrichtigungstext',
    for_example: 'Zum Beispiel',
    preview_image: 'Vorschaubild',
    back_image: 'Hintergrundbild',
    monday: 'Mon',
    tuesday: 'Di',
    wednesday: 'Wed',
    thursday: 'Thu',
    friday: 'Fr',
    saturday: 'Sat',
    sunday: 'Alle',
    sending_time: 'Sendezeit',
    gmt_sending_time: `Die Sendezeit ist GMT+1, beachten Sie die Zeitzone für Ihr Land',
    square: 'Das Bild, das rechts neben dem Text platziert und in der Vorschaumeldung angezeigt werden soll. Die 	Größe beträgt 1:1' im Quadrat`,
    square2:
      'Das Bild, das in der geöffneten (erweiterten) Benachrichtigung unterhalb des Benachrichtigungstextes 	erscheint. Die Größe ist ein Rechteck von 2:1',
    url_camp_tooltip:
      'Kampagnen-URL von PP oder von Ihrem Tracker oder PP. Der Parameter {click_id} wird zur 	Optimierung für Ereignisse benötigt. Beispiel:',
    url_track_camp_tooltip:
      'Kampagnen-URL von PP oder Ihrem Tracker. Beispiel:',
    url_track_camp_tooltip2:
      'Kampagnen-URL von unserem PP oder Web-Tracker. Wenn es sich um einen Link aus dem Web 	handelt, muss ${click_id} in einem der Felder ersetzt werden, damit das Web uns Postbacks zu TDS sendet. Verwenden 	Sie ${sub1}, ${sub2}, ${sub3}, ${sub4}, ${sub5}, ${sub6}, ${sub7}, um Subs aus der Benennung zu übertragen. 	Beispiel:',
    naming_tooltip: `Bei der Benennung können Sie anstelle von sub1, sub2, ..., sub5 Ihre eigenen Werte eingeben. 	Sie können den Unterstrich _' in den Untertiteln nicht verwenden _`,
    first_naming_tooltip:
      'Der erste Teil des naming_tooltip vor _ kann nicht geändert werden!!!',
    subs:
      'Um Subs aus der Namenskonvention zu übernehmen, müssen verfügbare Subs im Link zum Angebot ersetzt 	werden.',
    available_subs: 'Verfügbare Untertitel:',
    param: 'Parameter',
    need_click_id:
      'benötigt, um Ihnen unsere Klick-ID im Tracker zu übergeben, damit Sie Ereignisse an ihn senden 	können (Registrierung und Einzahlung).',
    add_new_rule: 'Neue Regel hinzufügen',
    optimization: 'Postbacks (Optimierung):',
    for_optimisation:
      'Für die Optimierung von Ereignissen und die Anzeige von Statistiken müssen Sie Postbacks von 	Ihrem Tracker zu unserem TDS einrichten.',
    postback_available_params: 'Verfügbare Parameter im Postback:',
    click_id_tds: 'Klick-ID in unserem TDS',
    deposit_sum:
      'Einzahlungsbetrag. Wenn er 0 ist, bedeutet dies, dass eine Registrierung stattgefunden hat',
    deposit_currency: 'Einzahlungswährung (Beispiel: usd)',
    postback_reg_example: 'Postback-Beispiel für die Registrierung:',
    postback_dep_example: 'Postback-Beispiel für die Einzahlung:',
    audience_text:
      'Wir migrieren automatisch die Zielgruppe der Nutzer, die eine Einzahlung getätigt haben, zu 	ALLEN GEO Organics, die Ihnen in der ausgewählten App zur Verfügung stehen.',
    validation_text: 'Das Feld muss ausgefüllt werden',
    validation_text_url: 'Das Feld muss eine gültige URL sein',
    validation_text_email_password: 'Ungültige E-Mail oder ungültiges Passwort',
    validation_text_email: 'Ungültige E-Mail',
    validation_text_password: 'Das Passwort muss 6 oder mehr Zeichen enthalten',

    unknown_error_occured: 'Ups! Ein unbekannter Fehler ist aufgetreten.',
    validation_text_email_exists: 'Diese E-Mail ist bereits registriert',
    validation_text_payment_system: 'Zahlungssystem auswählen',
    validation_text_payment_system_network:
      'Netzwerk des Zahlungssystems auswählen',
    validation_text_payment_currency: 'Währung auswählen',
    validation_text_payment_account: 'Konto- oder Kartennummer eingeben',
    validation_text_tds_rule_url:
      'Der Link muss mit http:// oder https:// beginnen',
    validation_text_app_campaign_name: 'Stream-Namen eingeben',
    validation_text_app_campaign_app: 'App auswählen',
    validation_text_app_campaign_source: 'Zugriffsquelle auswählen',
    validation_text_campaign_name: 'Stream-Namen eingeben',
    validation_text_campaign_offer: 'Wählen Sie ein Angebot',
    validation_text_campaign_source: 'Zugriffsquelle auswählen',
    validation_text_campaign_domain: 'Wählen Sie eine Umleitungsdomäne aus',
    validation_text_campaign_geo: 'Geo auswählen',
    validation_text_campaign_promo: 'Zielseite auswählen',
    validation_text_push_app: 'App auswählen',
    validation_text_email_push_title: 'Push-Titel angeben',
    validation_text_email_push_message: 'Push-Text angeben',
    validation_text_email_push_geo: 'Geo auswählen',
  },
}

export default locales
