
import getColumns from './config/getColumns'
import { defineComponent } from 'vue'
import axios from 'axios'
import Filters from '@/components/filters/Filters.vue'
import CampaignEditDialog from '@/components/dialogs/CampaignEditDialog.vue'
import CampaignUrlsBuilder from '@/components/dialogs/CampaignUrlsBuilder.vue'
import {
  FiltersType,
  getTableProperties,
  request,
  Table,
  TableRequestProps,
} from '@/utils/request'
import { CampaignStatus } from '@/enums/CampaignStatus'
import i18n from '@/locales/i18n'
import { getIncludeOptions } from '@/utils/filters'
import {
  heroOutline24DocumentDuplicate,
  heroOutline24PencilSquare,
  heroOutline24Trash,
  heroOutline24ChartBar,
  heroOutline24Link,
} from 'quasar-extras-svg-icons/hero-icons-v2'
import { formatDate } from '@/utils'

export default defineComponent({
  name: 'CampaignsList',
  setup() {
    return {
      heroOutline24DocumentDuplicate,
      heroOutline24PencilSquare,
      heroOutline24Trash,
      heroOutline24ChartBar,
      heroOutline24Link,
    }
  },
  components: { Filters, CampaignUrlsBuilder, CampaignEditDialog },
  async created() {
    await this.onRequest({ pagination: this.pagination })
    this.rows = this.rows.map(row => ({
      ...(row as {}),
      showCampaignUrlsBuilder: false,
    })) as never[]
  },
  computed: {
    columns(): any {
      return getColumns()
    },
  },
  data() {
    return {
      ...getTableProperties('id'),
      filters: { status: CampaignStatus.Active, ...getIncludeOptions() },
      campaigns: [],
      campaignStatusTab: CampaignStatus.Active,
      campaignStatusOptions: [
        { label: i18n.t('active'), value: CampaignStatus.Active },
        { label: i18n.t('stopped'), value: CampaignStatus.Stopped },
      ],
      selectedCampaignId: null,
      showCampaignEditDialog: false,
    }
  },
  watch: {
    async filters() {
      await this.onRequest({ pagination: this.pagination })
    },
    async campaignStatusTab(value: number) {
      this.resetFilters(value)
    },
  },
  methods: {
    format(val: string) {
      return formatDate(val)
    },
    async copyRow(props: TableRequestProps) {
      this.loading = true
      await axios.post('/api/campaigns/' + props.row?.id)
      await this.onRequest({ pagination: this.pagination })
    },
    async deleteRow(props: TableRequestProps) {
      this.loading = true
      await axios.delete('/api/campaigns', { params: { id: props.row?.id } })
      await this.onRequest({ pagination: this.pagination })
    },
    async onRequest(
      props: TableRequestProps | Omit<TableRequestProps, 'pagination'>,
    ) {
      const options = { pagination: this.pagination, ...props }
      await request(options, this as Table, '/api/campaigns/')
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters } as any
    },
    resetFilters(status?: number) {
      this.pagination.page = 1
      this.filters = {
        status: status !== undefined ? status : this.filters.status,
        ...getIncludeOptions(),
      }
    },
    openCampaignDialog(campaignId: number) {
      this.selectedCampaignId = (campaignId as unknown) as null
      this.showCampaignEditDialog = true
    },
    onCloseDialog() {
      this.showCampaignEditDialog = false
      this.selectedCampaignId = null
    },
  },
})
