import getDefaultData from '../../pages/apps-campaigns/config/getDefaultData'
import {
  Errors,
  AppCampaignData,
  AppCampaignState,
  RootModule,
} from '@/store/types'
import { ActionContext } from 'vuex'
import axios from 'axios'
import router from '@/router'

const appsCampaignsModule = {
  state: getDefaultData(),
  mutations: {
    update(state: AppCampaignState, value: AppCampaignData): void {
      state.campaign = { ...state.campaign, ...value }
      state.errors = {}
    },
    reset(state: AppCampaignState): void {
      const { campaign, errors } = getDefaultData()
      state.campaign = campaign
      state.errors = errors
    },
    setErrors(state: AppCampaignState, value: Errors): void {
      state.errors = value
    },
  },
  actions: {
    async getData(
      { commit }: ActionContext<AppCampaignState, RootModule>,
      campaignId: string,
    ) {
      try {
        const { data } = await axios.get('/api/apps-campaigns/' + campaignId)
        commit('update', data)
        commit('setErrors', {})
      } catch (err) {
        await router.push('/apps-campaigns')
        throw new Error(err as any)
      }
    },
  },
  namespaced: true,
}

export default appsCampaignsModule
