import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import router from './router'
import store from './store'
import quasarOptions from './configs/quasar-options'
import VueFeather from 'vue-feather'
import i18n from '@/locales/i18n'
import Vue3Storage from 'vue3-storage'

createApp(App)
  .component(VueFeather.name as string, VueFeather)
  .use(Quasar, quasarOptions as any)
  .use(router)
  .use(store)
  .use(i18n)
  .use(Vue3Storage, {})
  .mount('#app')
