
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SignUpContentHeader',
  props: {
    hideNav: Boolean,
  },
  methods: {
    getReferrerParamsStr() {
      return new URL(window.location.href).search
    },
  },
})
