import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_td = _resolveComponent("q-td")!
  const _component_vue_feather = _resolveComponent("vue-feather")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createBlock(_component_q_table, {
    id: "offer-landings",
    columns: _ctx.columns,
    rows: _ctx.promos,
    "hide-bottom": "",
    flat: ""
  }, {
    body: _withCtx((props) => [
      _createVNode(_component_q_tr, {
        style: _normalizeStyle(
          props.row.borderColor && props.row.borderLabel
            ? `border: 2px solid ${props.row.borderColor} !important;`
            : ''
        )
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_td, {
            props: props,
            key: "name"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(props.row.name), 1)
            ]),
            _: 2
          }, 1032, ["props"]),
          _createVNode(_component_q_td, {
            props: props,
            key: "type"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.markupTypes[props.row.markupType]), 1)
            ]),
            _: 2
          }, 1032, ["props"]),
          _createVNode(_component_q_td, {
            props: props,
            key: "archive"
          }, {
            default: _withCtx(() => [
              (props.row.archive)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_vue_feather, {
                      size: "16px",
                      onClick: ($event: any) => (_ctx.download(props.row.id)),
                      type: "download",
                      class: "link"
                    }, null, 8, ["onClick"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["props"]),
          _createVNode(_component_q_td, {
            props: props,
            key: "link"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                onClick: ($event: any) => (_ctx.landingRedirect(props.row.url)),
                color: "primary",
                class: "q-btn__main-size text-weight-bold",
                outline: "",
                "no-caps": ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('open_link')), 1)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        _: 2
      }, 1032, ["style"])
    ]),
    _: 1
  }, 8, ["columns", "rows"]))
}