
import { defineComponent } from 'vue'
import router from '@/router'
import { mapState } from 'vuex'
import { lookupGeos } from '@/utils/lookups'
import { getTableProperties, request, TableRequestProps } from '@/utils/request'
import { Offer, Reward } from '@/store/types'
import { getActualRewards, getAmountsByRewards } from '@/utils/rewards'
import axios from 'axios'
import { getData } from '@/utils/getData'
import i18n from '@/locales/i18n'
import { omit } from 'lodash'

export default defineComponent({
  name: 'ComingSoon',
})
