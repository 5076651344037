
import { defineComponent } from 'vue'
import getColumns from './config/getColumns'
import { Currency } from '@/enums/Currency'
import {
  request,
  getPagination,
  Table,
  TableRequestProps,
} from '@/utils/request'
import { exportToCsv } from '@/utils/export'
import { formatDate, formatValue, RowValue } from '@/utils'
import { LeadStatus } from '@/enums/LeadStatus'
import { enumToArray } from '@/utils'

export default defineComponent({
  name: 'ConversionsTable',
  props: ['filters', 'tab', 'exportCsv'],
  computed: {
    columns(): any {
      return getColumns()
    },
  },
  created() {
    this.onRequest({ pagination: this.pagination })
  },
  data: function() {
    return {
      currencies: Currency,
      loading: true,
      rows: [],
      pagination: getPagination('datetime'),
      statuses: enumToArray(LeadStatus),
    }
  },
  watch: {
    filters() {
      this.pagination.page = 1
      this.onRequest({ pagination: this.pagination })
    },
    exportCsv() {
      exportToCsv(this.rows, this.columns)
    },
  },
  methods: {
    formatAmount(amount: RowValue, currency: number) {
      return formatValue(amount, 3) + ' ' + (this.currencies[currency] ?? '')
    },
    async onRequest(props: TableRequestProps) {
      await request(props, this as Table, '/api/conversions/' + this.tab)
    },
    format(val: string) {
      return formatDate(val)
    },
  },
})
