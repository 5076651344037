
import { defineComponent } from 'vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import SignUpBackground from '@/components/sign-up/SignUpBackground.vue'
import { omit } from 'lodash'
import {
  heroOutline24Eye,
  heroOutline24EyeSlash,
} from 'quasar-extras-svg-icons/hero-icons-v2'
import SignUpContentHeader from '@/components/sign-up/SignUpContentHeader.vue'
import SignUpHeader from '@/components/sign-up/SignUpHeader.vue'

export default defineComponent({
  name: 'Login',
  components: { SignUpHeader, SignUpContentHeader },
  computed: mapState('authModule', ['loginError']),
  setup() {
    return {
      heroOutline24Eye,
      heroOutline24EyeSlash,
    }
  },
  created: function() {
    const params = this.$router.currentRoute.value?.query
    if (
      params &&
      Object.keys(params)?.length &&
      !this.$storage.getStorageSync('referrerParams')
    ) {
      this.$storage.setStorageSync('referrerParams', {
        referrer: document.referrer || null,
        ...omit(params, 'lang'),
      })
    }
    this.resetErrors()
    this.tryImpersonate()
  },
  data: function() {
    return {
      hidePassword: true,
      email: null,
      password: null,
      loading: false,
    }
  },
  methods: {
    ...mapActions('authModule', ['login', 'tryImpersonate']),
    ...mapMutations('authModule', ['resetErrors']),
    async submit() {
      this.loading = true
      try {
        await this.login({
          email: this.email,
          password: this.password,
        })
      } finally {
        this.loading = false
      }
    },
  },
})
