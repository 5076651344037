
import { defineComponent } from 'vue'
import { getData } from '@/utils/getData'
import { Reward } from '@/store/types'
import { getActualRewards, getAmountsByRewards } from '@/utils/rewards'
import { ref } from 'vue'

import {
  heroOutline24ChevronLeft,
  heroOutline24ChevronRight,
} from 'quasar-extras-svg-icons/hero-icons-v2'
import {RewardType} from "@/enums/RewardType";

export default defineComponent({
  name: 'SuperOffers',
  async created() {
    this.onWindowResize()
    await this.onRequestSuperOffers()
  },
  setup() {
    return {
      heroOutline24ChevronLeft,
      heroOutline24ChevronRight,
      slide: ref(1),
    }
  },

  computed: {
    prepareData(): any {
      const result = []

      for (let i = 0; i < this.superOffers.length; i += this.arraySize) {
        const subarray = this.superOffers.slice(i, i + this.arraySize)
        result.push(subarray)
      }
      return result
    },
  },
  data() {
    return {
      rewardTypes: RewardType,
      storageUrl: process.env.CDN_STORAGE_URL,
      superOffers: [],
      slideSuperOffers: 1,
      arraySize: 4,
    }
  },
  watch: {
    '$q.screen.width'() {
      this.onWindowResize()
    },
  },
  methods: {
    onWindowResize() {
      if (this.$q.screen.width > 1920) {
        this.arraySize = 6
      } else if (this.$q.screen.width > 1440) {
        this.arraySize = 4
      } else if (this.$q.screen.width > 1024) {
        this.arraySize = 3
      } else if (this.$q.screen.width > 744) {
        this.arraySize = 2
      } else {
        this.arraySize = 1
      }
    },
    getRewards(rewards: Reward[]) {
      return getActualRewards(rewards)
    },
    async onRequestSuperOffers() {
      const filters = { isSuperOffer: true } as any
      this.superOffers = await getData(
        { filters, limit: 0, offset: 0, orderBy: { createdAt: 'DESC' } },
        '/api/offers/super',
      )
    },
    getRewardAmounts(rewards: Reward[]): string {
      return getAmountsByRewards(rewards)
    },
  },
})
