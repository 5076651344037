
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ApiIntegration',
  data: function() {
    return {
      url: 'https://track.moniacc.com',
      json: `
      {
        "firstname": "Василий",
        "lastname": "Сидоров",
        "email": "v.s@example.com",
        "phone": "+79201107780",
        "offer_name": "Газпром",
        "campaign_id": 163,
        "redirect_url": "https://example.com",
        "sub1": "sub1"
      }
      `,
      form: `
        <form action='https://track.moniacc.com' method='post'>
          <input type='text' name='firstname' :placeholder='Имя' />
          <input type='text' name='lastname' placeholder='Фамилия' />
          <input type='text' name='email' placeholder='Email' />
          <input type='text' name='phone' placeholder='Ваш телефон' />
          <input type='hidden' name='offer_name' value='' />
          <input type='hidden' name='campaign_id' value='' />
          <input type='hidden' name='redirect_url' value='' />
          <input type='hidden' name='sub1' value='123123.abc.123' />
          <input type='hidden' name='click_id' value = "<?php echo $clickId; ?>" />
          <input type='submit' value='Сделать заказ!' />
        </form>
      `,
      script: `
        <?php
          $campaignId = ''; // ВАШ CAMPAIGN ID

          $sid1 = '';
          $sid2 = '';
          $sid3 = '';
          $sid4 = '';
          $sid5 = '';

          // Define ip
          if (!empty($_SERVER['HTTP_CF_CONNECTING_IP'])) {
            $ip =  $_SERVER['HTTP_CF_CONNECTING_IP'];
          }  elseif (!empty($_SERVER['HTTP_X_REAL_IP'])) {
            $ip =  $_SERVER['HTTP_X_REAL_IP'];
          } elseif (!empty($_SERVER['HTTP_X_FORWARDED_FOR'])) {
            $ip =  $_SERVER['HTTP_X_FORWARDED_FOR'];
          } else {
            $ip =  $_SERVER['REMOTE_ADDR'];
          }

          parse_str(parse_url($_SERVER['REQUEST_URI'], PHP_URL_QUERY), $clientQueryParams);
          $clickId = isset($clientQueryParams['click_id']) ? $clientQueryParams['click_id'] : $clientQueryParams['event_id'];

          if (!$clickId) {
            $ch = curl_init();
            $url = "https://" . $campaignId . ".tracker.moniacc.com/r";
            $url_parts = parse_url($url);
            $params = array(
              'sid1' => $sid1 ? $sid1 : ($qs['sid1'] ? $qs['sid1'] : $qs['sub1']),
              'sid2' => $sid2 ? $sid2 : ($qs['sid2'] ? $qs['sid2'] : $qs['sub2']),
              'sid3' => $sid3 ? $sid3 : ($qs['sid3'] ? $qs['sid3'] : $qs['sub3']),
              'sid4' => $sid4 ? $sid4 : ($qs['sid4'] ? $qs['sid4'] : $qs['sub4']),
              'sid5' => $sid5 ? $sid5 : ($qs['sid5'] ? $qs['sid5'] : $qs['sub5']),
              'ip' => $ip
            );
            $params = array_filter($params, function($value) { return !is_null($value) && $value !== ''; });
            $url_parts['query'] = http_build_query($params);
            $url = $url_parts['scheme'] . '://' . $url_parts['host'] . $url_parts['path'] . '?' . $url_parts['query'];
            curl_setopt($ch, CURLOPT_URL, $url);
            curl_setopt($ch,CURLOPT_HEADER, true);
            curl_setopt($ch,CURLOPT_RETURNTRANSFER, true);
            curl_setopt($ch,CURLOPT_FOLLOWLOCATION, false);
            $result = curl_exec($ch);
            if (preg_match('~Location: (.*)~i', $result, $match)) {
              $location = trim($match[1]);
            }
            parse_str(parse_url($location, PHP_URL_QUERY), $queryParams);
            $clickId = isset($queryParams['click_id']) ? $queryParams['click_id'] : $queryParams['event_id'];
          }
        ?>
      `,
    }
  },
  methods: {
    copy(text: string) {
      const element = document.createElement('textarea')
      document.body.appendChild(element)
      element.value = text
      element.select()
      document.execCommand('copy')
      document.body.removeChild(element)
    },
  },
})
