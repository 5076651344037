
import { defineComponent } from 'vue'
import axios from 'axios'
import {
  getSortedBalances,
  payoutCurrencies as currencies,
  showErrorMessageSnackBar,
} from '@/utils'
import { Balance, PayoutSystemAccount } from '@/store/types'
import { Currency } from '@/enums/Currency'
import i18n from '@/locales/i18n'
import { mapMutations, mapState } from 'vuex'
import PayoutLeadsDialog from '@/components/dialogs/PayoutLeadsDialog.vue'

export default defineComponent({
  name: 'CreatePayoutDialog',
  components: { PayoutLeadsDialog },
  props: ['open', 'close', 'refresh'],
  async mounted() {
    this.payoutSystemAccounts = (
      await axios.get('/api/payout-system-accounts')
    ).data
    const balances = (await axios.get('/api/balances')).data
    let sortedBalances: any[] = []
    if (balances.length) {
      sortedBalances = getSortedBalances(balances)
      this.sortedBalances = sortedBalances
    }
    const maxBalance = sortedBalances
      .map(b => b[0])
      .sort(
        (b1: Balance, b2: Balance) =>
          (b2.value.amount - b1.value.amount) as any,
      )?.[0]
    this.update({
      amount: maxBalance.value.amount,
      currency: maxBalance.value.currency,
    })
    this.payout.value = maxBalance.value
    this.currency = maxBalance.value.currency
    this.maxAmount = maxBalance.value
  },
  computed: {
    ...mapState('payoutLeadsModule', ['payoutLeads']),
    showDialog(): boolean {
      return this.open
    },
    accounts(): string[] {
      return this.payoutSystemAccounts.map(
        (payout: PayoutSystemAccount) => payout.account,
      )
    },
  },
  data: function() {
    return {
      showPayoutLeadsDialog: false,
      payoutGroupOption: 'all',
      payoutOptions: [
        {
          label: i18n.t('all_sum'),
          value: 'all',
        },
        {
          label: i18n.t('select_leads'),
          value: 'leads',
        },
      ],
      loading: false,
      maxAmount: 0,
      sortedBalances: [] as any,
      payoutSystemAccounts: [],
      payout: this.getDefaultPayoutValue(),
      currency: Currency.USD,
      errors: {},
      currencies,
    }
  },
  watch: {
    payoutGroupOption() {
      this.showPayoutLeadsDialog = this.payoutGroupOption === 'leads'
      if (this.payoutGroupOption === 'all') {
        this.onUpdateCurrency(this.payoutLeads.currency)
      }
    },
    currency() {
      this.update({ amount: null })
    },
  },
  methods: {
    ...mapMutations('payoutLeadsModule', ['update', 'reset']),
    getDefaultPayoutValue() {
      return {
        value: { amount: null },
        payoutSystemAccount: { account: null },
      }
    },
    async createPayout() {
      const payoutSystemAccount = this.payoutSystemAccounts.find(
        (payoutAccount: PayoutSystemAccount) =>
          payoutAccount.account === this.payout.payoutSystemAccount.account,
      )
      this.loading = true
      const validationData = (
        await axios.post('/api/payouts/validate', {
          ...this.payout,
          leadIds: this.payoutLeads.leadIds,
          value: this.payout.value,
          payoutSystemAccount,
        })
      ).data
      if (!validationData.isValid) {
        if (validationData.payoutSystemAccountError) {
          this.errors = {
            payoutSystemAccount: i18n.t('validation_payment_account'),
          }
        }
        if (validationData.amountError) {
          this.errors = {
            amount:
              i18n.t('validation_min_amount') +
              validationData.minPayoutAmount +
              ' ' +
              Currency[validationData.minPayoutCurrency],
          }
        }
        if (validationData.currencyError) {
          this.errors = { currency: i18n.t('validation_payout_currency') }
        }
        this.loading = false
        return
      }
      axios
        .post('/api/payouts', {
          ...this.payout,
          payoutSystemAccount,
          leadIds: this.payoutLeads.leadIds,
          value: this.payout.value,
        })
        .then(() => {
          this.errors = {}
          this.$emit('refresh')
        })
        .catch(() => {
          showErrorMessageSnackBar(i18n.t('decline_payout'))
        })
        .finally(() => {
          this.loading = false
          this.payout = this.getDefaultPayoutValue()
          this.onClose()
        })
    },
    async onUpdateCurrency(currency: number) {
      this.update({ currency })
      this.currency = currency
      this.payout.value.currency = currency
      const balances = (await axios.get('/api/balances')).data
      let sortedBalances: any[] = []
      if (balances.length) {
        sortedBalances = getSortedBalances(balances)
        this.sortedBalances = sortedBalances
      }
      const curBalance = this.sortedBalances
        .map((b: any) => b[0])
        .find((b: Balance) => b.value.currency === currency)
      this.update({ amount: curBalance?.value.amount || 0 })
      this.payout.value.amount = curBalance?.value.amount || 0
    },
    onClosePayoutLeadsDialog() {
      this.showPayoutLeadsDialog = false
      if (!this.payoutLeads.leadIds?.length) {
        this.payoutGroupOption = 'all'
        this.payout.value.amount = this.maxAmount
      } else {
        this.payout.value.amount = this.payoutLeads.amount
      }
    },
    onClose() {
      this.reset()
      this.errors = {}
      this.$emit('close')
    },
  },
})
