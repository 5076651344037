import axios, { AxiosError } from 'axios'
import { Currency } from '@/enums/Currency'
import { Balance } from '@/store/types'
import { BalanceType } from '@/enums/BalanceType'
import Notify from 'quasar/src/plugins/Notify.js';
import i18n from '@/locales/i18n'
import { PushNotificationEventType } from '@/enums/PushNotificationEventType'
import { Day } from '@/enums/Day'
import { RewardType } from '@/enums/RewardType'
import { Timezone } from '@/enums/Timezone'
import {PaymentSystem} from "@/enums/PaymentSystem";

type SearchParams = { ids?: number[]; search: string }
export type RowValue = string | null | number | undefined
const StringIsNumber = (value: string) => !isNaN(Number(value))
interface EnumItem<E> {
  id: keyof E
  name: E
}

export function enumToArray<E>(enumObject: {
  [key: string]: E
}): EnumItem<E>[] {
  return Object.keys(enumObject)
    .filter(StringIsNumber)
    .map(key => ({ id: Number(key), name: enumObject[key] } as EnumItem<E>))
}

export function isDefined(value: RowValue) {
  if (value === null || value === undefined) {
    return '—'
  }
  return value
}

export function formatValue(value: RowValue, round = 2, isMoney = false) {
  const formattedValue = isDefined(value)
  if (!isNaN(Number(formattedValue)) && isMoney) {
    return (+formattedValue).toFixed(round)
  }
  if (Number.isInteger(+formattedValue)) {
    return Number.parseInt(formattedValue as string)
  }
  if (formattedValue === '—') {
    return formattedValue
  }
  return (+formattedValue).toFixed(round)
}

export function formatDate(isoDate: string) {
  if (!isoDate) {
    return '—'
  }
  const date = new Date(isoDate)
  return (
    date
      .toLocaleDateString()
      .split('/')
      .reverse()
      .join('.') +
    ' - ' +
    date.toLocaleTimeString()
  )
}

export const getGamblingRewardTypes = (withReg = false) => {
  const rewardTypes = enumToArray(RewardType)
  if (withReg) {
    rewardTypes.push({ id: 99, name: 'Registration' } as any)
    return rewardTypes.filter(({ name }) =>
      ['FTD', 'BL', 'Registration'].includes(name as string),
    )
  } else {
    return rewardTypes.filter(({ name }) =>
      ['FTD', 'BL', 'RevShare'].includes(name as string),
    )
  }
}

export async function getOptions(
  options: [],
  value: string,
  url: string,
  ids: number[] = [],
  extraParams: any = {},
): Promise<any> {
  if (!options.length || value === '') {
    return (await axios.get(url, { params: extraParams })).data
  }

  const params: SearchParams = { search: value }
  const selectedIds = ids.filter(Number)
  if (selectedIds.length) {
    params.ids = selectedIds
  }

  if (value !== '') {
    return (await axios.get(url, { params: { ...params, ...extraParams } }))
      .data
  }

  return options
}

export const payoutCurrencies = enumToArray(Currency).filter(c =>
  [Currency.USD, Currency.EUR, Currency.UAH, Currency.PLN].includes(
    (c.id as unknown) as number,
  ),
)

export const payoutSystems = enumToArray(PaymentSystem).filter(c =>
  ![PaymentSystem.Capitalist].includes(
    (c.id as unknown) as number,
  ),
)

export function getAffiliateBalances(
  affiliateBalances: Balance[],
  type?: BalanceType,
) {
  const balances: Balance[] = affiliateBalances
    .filter((b: Balance) => !b.isRentAppsBalance && !b.isReferralBalance)
    .sort((b1: Balance, b2: Balance) =>
      String(b1.value.currency).localeCompare(String(b2.value.currency)),
    )
  const available = balances.filter(
    (b: Balance) => b.type === BalanceType.Available,
  )
  const hold = balances.filter((b: Balance) => b.type === BalanceType.Hold)
  const formattedBalances = []
  for (let i = 0; i < available.length; i++) {
    const holdBalance = formatValue(hold[i].value.amount)
    const availableBalance = formatValue(available[i].value.amount)
    if (type === BalanceType.Hold) {
      formattedBalances.push(
        `${holdBalance} ${Currency[available[i].value.currency]}`,
      )
    } else {
      formattedBalances.push(
        `${availableBalance}   [ ${holdBalance} ]  ${
          Currency[available[i].value.currency]
        }`,
      )
    }
  }
  return formattedBalances.join('\n')
}

export async function downloadPromo(id: string) {
  const response: any = await axios.get(`/api/promos/${id}/download`)
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = response.data.url
  document.body.appendChild(link)
  link.click()
  setTimeout(() => {
    URL.revokeObjectURL(link.href)
    ;(link.parentNode as any).removeChild(link)
  }, 0)
}

export function getPushNotificationEventTypes() {
  return [
    { label: i18n.t('install'), value: PushNotificationEventType.Install },
    { label: i18n.t('reg'), value: PushNotificationEventType.Registration },
    { label: i18n.t('dep'), value: PushNotificationEventType.Deposit },
  ]
}

export function getDays() {
  return [
    { label: i18n.t('monday'), value: Day.Monday },
    { label: i18n.t('tuesday'), value: Day.Tuesday },
    { label: i18n.t('wednesday'), value: Day.Wednesday },
    { label: i18n.t('thursday'), value: Day.Thursday },
    { label: i18n.t('friday'), value: Day.Friday },
    { label: i18n.t('saturday'), value: Day.Saturday },
    { label: i18n.t('sunday'), value: Day.Sunday },
  ]
}

export function showErrorSnackBar(err: AxiosError) {
  if (err.response?.status === 500) {
    Notify.create({
      type: 'negative',
      timeout: 2500,
      position: 'bottom-right',
      message: `Internal Server Error: ${err.message}`,
    })
  }
}

export function showErrorMessageSnackBar(message: string) {
  Notify.create({
    type: 'negative',
    timeout: 2500,
    position: 'bottom-right',
    message,
  })
}

export function showSuccessSnackBar(message: string) {
  Notify.create({
    type: 'positive',
    timeout: 2500,
    position: 'bottom-right',
    message,
  })
}

export function getTimezones() {
  return [
    {
      id: Timezone.InternationalWest,
      name: '(GMT-12:00) International Date Line West',
      value: -12,
    },
    {
      id: Timezone.Samoa,
      name: '(GMT-11:00) Midway Island, Samoa',
      value: -11,
    },
    { id: Timezone.Hawaii, name: '(GMT-10:00) Hawaii', value: -10 },
    { id: Timezone.Alaska, name: '(GMT-09:00) Alaska', value: -9 },
    {
      id: Timezone.USAMountain,
      name: '(GMT-08:00) Pacific Time (US & Canada)',
      value: -8,
    },
    {
      id: Timezone.Arizona,
      name: '(GMT-07:00) Mountain Time (US & Canada), Arizona',
      value: -7,
    },
    {
      id: Timezone.CentralAmerica,
      name: '(GMT-06:00) Central America',
      value: -6,
    },
    {
      id: Timezone.EasternAmerica,
      name: '(GMT-05:00) Eastern Time (US & Canada), Indiana (East)',
      value: -5,
    },
    {
      id: Timezone.AtlanticTime,
      name: '(GMT-04:00) Atlantic Time (Canada), Santiago',
      value: -4,
    },
    {
      id: Timezone.Brasilia,
      name: '(GMT-03:00) Brasilia, Greenland, Montevideo',
      value: -3,
    },
    { id: Timezone.MidAtlantic, name: '(GMT-02:00) Mid-Atlantic', value: -2 },
    { id: Timezone.CapeVerde, name: '(GMT-01:00) Cape Verde Is.', value: -1 },
    {
      id: Timezone.UTC,
      name: '(GMT+00:00) Greenwich Mean Time: Dublin, Lisbon, London',
      value: 0,
    },
    {
      id: Timezone.Berlin,
      name: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
      value: 1,
    },
    {
      id: Timezone.Kyiv,
      name: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
      value: 2,
    },
    {
      id: Timezone.Moscow,
      name: '(GMT+03:00) Moscow, St. Petersburg, Kuwait, Riyadh, Baghdad',
      value: 3,
    },
    {
      id: Timezone.Yerevan,
      name: '(GMT+04:00) Abu Dhabi, Muscat, Yerevan, Kabul',
      value: 4,
    },
    {
      id: Timezone.Islamabad,
      name: '(GMT+05:00) Islamabad, Karachi, Tashkent',
      value: 5,
    },
    {
      id: Timezone.Novosibirsk,
      name: '(GMT+06:00) Almaty, Novosibirsk',
      value: 6,
    },
    {
      id: Timezone.Jakarta,
      name: '(GMT+07:00) Bangkok, Hanoi, Jakarta',
      value: 7,
    },
    {
      id: Timezone.Singapore,
      name: '(GMT+08:00) Kuala Lumpur, Singapore, Hong Kong',
      value: 8,
    },
    {
      id: Timezone.Tokyo,
      name: '(GMT+09:00) Osaka, Sapporo, Tokyo, Seoul, Yakutsk',
      value: 9,
    },
    {
      id: Timezone.Sydney,
      name: '(GMT+10:00) Canberra, Melbourne, Sydney',
      value: 10,
    },
    {
      id: Timezone.NewCaledonia,
      name: '(GMT+11:00) Magadan, Solomon Is., New Caledonia',
      value: 11,
    },
    {
      id: Timezone.Fiji,
      name: '(GMT+12:00) Fiji, Kamchatka, Marshall Is., Wellington',
      value: 12,
    },
  ]
}

export function getSortedBalances(balances: Balance[]) {
  const sortedBalances = []
  const currencies = new Set([
    ...balances.map((b: Balance) => b.value.currency),
  ])
  for (const currency of currencies) {
    const availableBalances = balances.filter(
      (b: Balance) =>
        b.value.currency === currency && b.type === BalanceType.Available,
    )
    const holdBalances = balances.filter(
      (b: Balance) =>
        b.value.currency === currency && b.type === BalanceType.Hold,
    )
    const availableBal = {
      value: { amount: 0, currency: currency },
      type: BalanceType.Available,
    }
    const holdBal = {
      value: { amount: 0, currency: currency },
      type: BalanceType.Hold,
    }
    for (const ab of availableBalances) {
      availableBal.value.amount += ab.value.amount
    }
    for (const hb of holdBalances) {
      holdBal.value.amount += hb.value.amount
    }
    sortedBalances.push([availableBal, holdBal])
  }
  return sortedBalances
}
