import getDefaultData from '@/pages/push-notifications/config/getDefaultData'
import {
  Errors,
  PushNotification,
  PushNotificationState,
  RootModule,
} from '@/store/types'
import { ActionContext } from 'vuex'
import axios from 'axios'
import router from '@/router'

const pushNotificationsModule = {
  state: getDefaultData(),
  mutations: {
    update(state: PushNotificationState, value: PushNotification): void {
      state.pushNotification = { ...state.pushNotification, ...value }
      state.errors = {}
    },
    reset(state: PushNotificationState): void {
      const { pushNotification, errors } = getDefaultData()
      state.pushNotification = pushNotification
      state.errors = errors
    },
    setErrors(state: PushNotificationState, value: Errors): void {
      state.errors = value
    },
  },
  actions: {
    async getData(
      { commit }: ActionContext<PushNotificationState, RootModule>,
      pushId: string,
    ) {
      try {
        const { data } = await axios.get('/api/push-notifications/' + pushId)
        commit('update', data)
        commit('setErrors', {})
      } catch (err) {
        await router.push('/push')
        throw new Error(err as any)
      }
    },
  },
  namespaced: true,
}

export default pushNotificationsModule
