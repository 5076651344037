
import { defineComponent } from 'vue'
import { Currency } from '@/enums/Currency'
import { PaymentSystem } from '@/enums/PaymentSystem'
import axios from 'axios'
import { PaymentSystemNetwork } from '@/enums/PaymentSystemNetwork'
import {
  heroOutline24Trash,
  heroOutline24PencilSquare,
} from 'quasar-extras-svg-icons/hero-icons-v2'

export default defineComponent({
  name: 'PaymentSystemAccounts',
  props: ['isInline'],
  async created() {
    await this.onRequest()
  },
  setup() {
    return {
      heroOutline24Trash,
      heroOutline24PencilSquare,
    }
  },
  data: function() {
    return {
      currencies: Currency,
      paymentSystems: PaymentSystem,
      showAddPaymentSystemDialog: false,
      paymentAccounts: [],
    }
  },
  methods: {
    async onRequest() {
      this.paymentAccounts = (
        await axios.get('/api/payout-system-accounts/')
      ).data
    },
    async deleteRow(id: number) {
      await axios.delete('/api/payout-system-accounts/', {
        params: { id },
      })
      await this.onRequest()
    },
    getNetwork(acc: any) {
      return PaymentSystemNetwork[acc.paymentSystemNetwork]
        ? ` (${PaymentSystemNetwork[acc.paymentSystemNetwork].toLowerCase()})`
        : ''
    },
  },
})
