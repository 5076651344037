
import { defineComponent } from 'vue'
import { mapState, mapMutations } from 'vuex'
import { lookupGeos, lookupOffers, lookupTrafficSources } from '@/utils/lookups'

export default defineComponent({
  name: 'GeneralInfo',
  props: ['defaultOptions'],
  computed: mapState('campaignsModule', ['campaign', 'errors']),
  data: function() {
    return {
      offers: [...(this.defaultOptions.offers || [])],
      trafficSources: [...(this.defaultOptions.trafficSources || [])],
      geos: [...(this.defaultOptions.geos || [])],
    }
  },
  methods: {
    ...mapMutations('campaignsModule', ['update']),
    async getOffers(value: string, update: Function) {
      await lookupOffers(this, value, update, [this.campaign.offerId])
    },
    async getGeos(value: string, update: Function) {
      await lookupGeos(this, value, update, true)
    },
    async getTrafficSources(value: string, update: Function) {
      await lookupTrafficSources(this, value, update)
    },
  },
})
