import { AppCampaignState } from '@/store/types'

function getDefaultData(): AppCampaignState {
  return {
    campaign: {
      name: null,
      affiliateId: null,
      mobileAppId: null,
      mobileApp: null,
      trafficSourceId: null,
      tdsRules: [],
    },
    errors: {},
  }
}

export default getDefaultData
