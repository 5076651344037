
import axios from 'axios'
import { defineComponent } from 'vue'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import {
  heroOutline24Eye,
  heroOutline24EyeSlash,
} from 'quasar-extras-svg-icons/hero-icons-v2'

export default defineComponent({
  name: 'Passwords',
  data: function() {
    return {
      password: null,
      newPassword: null,
      errors: {},
      hidePassword: true,
      hideNewPassword: true,
    }
  },
  setup() {
    const $q = useQuasar()
    return {
      heroOutline24Eye,
      heroOutline24EyeSlash,
      showSuccessSnackbar() {
        $q.notify({
          type: 'positive',
          message: `Пароль успешно обновлен.`,
          position: 'top-right',
          timeout: 2500,
        })
      },
    }
  },
  methods: {
    changePassword() {
      axios
        .put('/api/profile/change-password', {
          password: this.password || '',
          newPassword: this.newPassword,
        })
        .then(() => {
          this.password = null
          this.newPassword = null
          this.errors = {}
          this.showSuccessSnackbar()
        })
        .catch(({ response }) => {
          this.errors = response?.data.errors
        })
    },
  },
})
