import { createStore } from 'vuex'
import authModule from './modules/auth.module'
import campaignsModule from './modules/campaigns.module'
import appsCampaignsModule from '@/store/modules/apps-campaigns.module'
import pushNotificationsModule from '@/store/modules/push-notifications.module'
import payoutLeadsModule from '@/store/modules/payout-leads.module'

const store = createStore({
  modules: {
    authModule,
    campaignsModule,
    appsCampaignsModule,
    pushNotificationsModule,
    payoutLeadsModule,
  },
})

export default store
