
import getColumns from './getColumns'
import { defineComponent } from 'vue'
import { getTableProperties, request, TableRequestProps } from '@/utils/request'
import { SupportTicketStatus } from '@/enums/SupportTicketStatus'
import i18n from '@/locales/i18n'
import CreateTicketDialog from '@/components/dialogs/CreateTicketDialog.vue'
import { getIncludeOptions } from '@/utils/filters'
import { heroOutline24ArrowLongRight } from 'quasar-extras-svg-icons/hero-icons-v2'
import { formatDate } from '@/utils'

export default defineComponent({
  name: 'SupportTickets',
  components: { CreateTicketDialog },
  async created() {
    await this.onRequest({ pagination: this.pagination })
  },
  setup() {
    return {
      heroOutline24ArrowLongRight,
    }
  },
  computed: {
    columns(): any {
      return getColumns()
    },
  },
  data() {
    return {
      ...getTableProperties('createdAt'),
      filters: {
        status: SupportTicketStatus.WaitingForAdmin,
        ...getIncludeOptions(),
      },
      campaigns: [],
      tab: SupportTicketStatus.WaitingForAdmin,
      campaignStatusOptions: [
        { label: i18n.t('active'), value: SupportTicketStatus.WaitingForAdmin },
        { label: i18n.t('finished'), value: SupportTicketStatus.Resolved },
      ],
      showCreateTicketDialog: false,
      ticketStatus: SupportTicketStatus,
    }
  },
  watch: {
    async filters() {
      await this.onRequest({ pagination: this.pagination })
    },
    tab() {
      this.resetFilters(this.tab)
    },
  },
  methods: {
    getTicketStatusName(val: number) {
      switch (val) {
        case SupportTicketStatus.New:
          return i18n.t('new')
        case SupportTicketStatus.WaitingForAdmin:
          return i18n.t('waiting_for_admin')
        case SupportTicketStatus.WaitingForAffiliate:
          return i18n.t('waiting_for_affiliate')
        case SupportTicketStatus.Resolved:
          return i18n.t('resolved')
      }
    },
    getStatusClass(status: SupportTicketStatus) {
      switch (status) {
        case SupportTicketStatus.New:
          return 'notify-success q-pa-sm bordered q-ma-auto'
        case SupportTicketStatus.WaitingForAdmin:
          return 'notify-warning q-pa-sm bordered q-ma-auto'
        case SupportTicketStatus.WaitingForAffiliate:
          return 'notify-orange q-pa-sm bordered q-ma-auto'
        case SupportTicketStatus.Resolved:
          return 'notify-grey q-pa-sm bordered q-ma-auto'
      }
    },
    onRowClick(ev: string, row: any) {
      this.$router.push('/tickets/' + row.id)
    },
    async onRequest(
      props: TableRequestProps | Omit<TableRequestProps, 'pagination'>,
    ) {
      const options = { pagination: this.pagination, ...props }
      await request(options, this as any, '/api/tickets/')
    },
    onCloseDialog() {
      this.showCreateTicketDialog = false
    },
    resetFilters(status: number) {
      this.pagination.page = 1
      this.filters = {
        status,
        ...getIncludeOptions(),
      }
    },
    format(val: string) {
      return formatDate(val)
    },
  },
})
