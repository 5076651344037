import { formatDate, isDefined, RowValue } from '@/utils'
import i18n from '@/locales/i18n'

export default function getColumns() {
  return [
    {
      name: 'createdAt',
      label: i18n.t('datetime'),
      field: 'createdAt',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    { name: 'url', label: i18n.t('link'), field: 'url', align: 'left' },
    {
      name: 'eventType',
      label: i18n.t('event'),
      field: 'eventType',
      align: 'left',
      sortable: true,
    },
    {
      name: 'campaignName',
      label: i18n.t('campaign'),
      field: 'campaignName',
      align: 'left',
      sortable: true,
    },
    {
      name: 'status',
      label: i18n.t('status'),
      field: 'status',
      align: 'left',
      format: (val: RowValue) => isDefined(val),
    },
  ]
}
