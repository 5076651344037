
import { defineComponent } from 'vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import { Currency } from '@/enums/Currency'
import { enumToArray, payoutCurrencies } from '@/utils'
import { Messengers } from '@/enums/Messengers'
import { omit } from 'lodash'
import SignUpContentHeader from '@/components/sign-up/SignUpContentHeader.vue'
import SignUpHeader from '@/components/sign-up/SignUpHeader.vue'

export default defineComponent({
  name: 'SignUp',
  components: { SignUpHeader, SignUpContentHeader },
  computed: mapState('authModule', ['signUpError', 'signUpErrorMessage']),
  beforeMount() {
    const params = this.$router.currentRoute.value?.query
    if (
      params &&
      Object.keys(params)?.length &&
      !this.$storage.getStorageSync('referrerParams')
    ) {
      this.$storage.setStorageSync('referrerParams', {
        referrer: document.referrer || null,
        ...omit(params, 'lang'),
      })
    }
  },
  created: function() {
    this.resetErrors()
  },
  data: function() {
    return {
      email: null,
      password: null,
      telegram: null,
      baseCurrency: Currency.USD,
      loading: false,
      currencies: payoutCurrencies,
      selectedMessenger: Messengers.Telegram,
      messengers: enumToArray(Messengers),
    }
  },
  methods: {
    ...mapActions('authModule', ['signUp', 'login']),
    ...mapMutations('authModule', ['resetErrors']),
    async submit() {
      this.loading = true
      try {
        const urlParams = new URLSearchParams(location.search)
        await this.signUp({
          email: this.email,
          password: this.password,
          telegram:
            this.selectedMessenger === Messengers.Telegram
              ? this.telegram
              : null,
          skype:
            this.selectedMessenger === Messengers.Skype ? this.telegram : null,
          baseCurrency: this.baseCurrency,
          ref: urlParams.get('ref'),
          refParams: this.$storage.getStorageSync('referrerParams') || null,
        })
        await this.login({
          email: this.email,
          password: this.password,
        })
      } finally {
        this.loading = false
      }
    },
  },
})
