import { enumToArray } from '@/utils'
import { MarkupType } from '@/enums/MarkupType'
import { TrafficPropertyType } from '@/enums/TrafficPropertyType'
import { PostbackEventType } from '@/enums/PostbackEventType'
import { Vertical } from '@/enums/Vertical'
import { groupByOptions } from '@/pages/statistics/config/groupByOptions'
import axios from 'axios'
import { SemanticType } from '@/enums/SemanticType'
import { Promo } from '@/store/types'
import { getGamblingRewardTypes } from '@/utils'
import { LeadStatus } from '@/enums/LeadStatus'

export function getFiltersData() {
  return {
    landings: [],
    preLandings: [],
    offers: [],
    campaigns: [],
    geos: [],
    sids1: [],
    sids2: [],
    sids3: [],
    sids4: [],
    sids5: [],
    trafficSourceNames: [],
    offerCategories: [],
    search: null,
    trafficProperties: [],
    apps: [],
    trafficSources: [],
    leadStatuses: enumToArray(LeadStatus),
    markupTypes: enumToArray(MarkupType),
    trafficPropertyTypes: enumToArray(TrafficPropertyType),
    rewardTypesWithReg: getGamblingRewardTypes(true),
    rewardTypes: getGamblingRewardTypes(false),
    postbackEventTypes: enumToArray(PostbackEventType),
    verticals: enumToArray(Vertical),
    groupByOptions,
  }
}

export function getIncludeOptions() {
  return {
    includeOfferId: true,
    includeOfferIds: true,
    includeGeoId: true,
    includeGeoIds: true,
    includeAdvertiserId: true,
    includeAdvertiserIds: true,
    includeAffiliateId: true,
    includeAffiliateIds: true,
    includeLandingId: true,
    includeRewardType: true,
    includeManagerId: true,
    includeManagerIds: true,
    includeCampaignId: true,
    includeCampaignIds: true,
    includeStatus: true,
    includeMarkerId: true,
    includeMobileAppId: true,
    includeCategoryId: true,
    includeOfferCategoryId: true,
    includeTrafficSourceId: true,
  }
}

export async function initOptionsByRouteParams(ref: any) {
  if (!Object.keys(ref.$route.params)?.length) {
    return
  }
  if (ref.$route.params.offerId) {
    ref.offers = (await axios.get('/api/offers/list')).data
  }
  if (ref.$route.params.campaignId) {
    ref.campaigns = (await axios.get('/api/campaigns')).data
  }
  if (ref.$route.params.geoId) {
    ref.geos = (await axios.get('/api/geos')).data
  }
  if (ref.$route.params.categoryId || ref.$route.params.offerCategoryId) {
    ref.offerCategories = (await axios.get('/api/offer-categories')).data
  }
  if (ref.$route.params.landingId || ref.$route.params.preLandingId) {
    const promos = (await axios.get('/api/promos')).data
    ref.landings = promos.filter(
      (p: Promo) => p.semanticType === SemanticType.Landing,
    )
    ref.preLandings = promos.filter(
      (p: Promo) => p.semanticType === SemanticType.Prelanding,
    )
  }
}

export async function initFilterOptions(ref: any) {
  ref.campaigns = (await axios.get('/api/campaigns')).data
  ref.offers = (await axios.get('/api/offers/list')).data
  ref.trafficProperties = (await axios.get('/api/traffic-properties')).data
  ref.geos = (await axios.get('/api/geos')).data
  ref.sids1 = (await axios.get('/api/sids', { params: { sid: 'sids1' } })).data
  ref.sids2 = (await axios.get('/api/sids', { params: { sid: 'sids2' } })).data
  ref.sids3 = (await axios.get('/api/sids', { params: { sid: 'sids3' } })).data
  ref.sids4 = (await axios.get('/api/sids', { params: { sid: 'sids4' } })).data
  ref.sids5 = (await axios.get('/api/sids', { params: { sid: 'sids5' } })).data
  ref.offerCategories = (await axios.get('/api/offer-categories')).data
  const promos = (await axios.get('/api/promos')).data
  ref.landings = promos.filter(
    (p: Promo) => p.semanticType === SemanticType.Landing,
  )
  ref.preLandings = promos.filter(
    (p: Promo) => p.semanticType === SemanticType.Prelanding,
  )
}

export async function initFilterOptionsForApps(ref: any) {
  ref.campaigns = (await axios.get('/api/apps-campaigns')).data
  ref.apps = (await axios.get('/api/apps/list')).data
  ref.geos = (await axios.get('/api/geos/apps')).data
  ref.trafficSources = (await axios.get('/api/traffic-sources/apps')).data
}
