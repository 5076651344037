
import { defineComponent } from 'vue'
import DatePicker from '@/components/date-picker/DatePicker.vue'
import { getFiltersData, initFilterOptions } from '@/utils/filters'
import {
  lookupCampaigns,
  lookupGeos,
  lookupLandings,
  lookupOfferCategories,
  lookupOffers,
  lookupSids,
  lookupTrafficProperties,
  lookupTrafficSourceNames,
} from '@/utils/lookups'
import { groupByOptions } from '@/pages/statistics/config/groupByOptions'
import { getDefaultFilters } from '@/utils/request'

export default defineComponent({
  name: 'FiltersDialog',
  components: { DatePicker },
  props: ['open', 'close', 'reset', 'currentFilters', 'changeFilters'],
  async created() {
    await initFilterOptions(this)
  },
  computed: {
    showDialog(): boolean {
      return this.open
    },
    hideForStatistics(): boolean {
      return !this.$route.path.includes('statistics')
    },
    hideForConversions(): boolean {
      return !this.$route.path.includes('conversions')
    },
    hideForCampaigns(): boolean {
      return !this.$route.path.includes('campaigns')
    },
    hideForOffers(): boolean {
      return !this.$route.path.includes('offers')
    },
    hideForPostbacks(): boolean {
      return !this.$route.path.includes('postbacks')
    },
  },
  data: function() {
    return {
      filters: { ...this.currentFilters },
      ...getFiltersData(),
      groupByOptions,
    }
  },
  watch: {
    search() {
      this.onChangeFilters('name', this.search)
    },
    currentFilters() {
      this.filters = { ...this.currentFilters }
      this.search = this.currentFilters.name
    },
  },
  methods: {
    addFilters() {
      this.$emit('changeFilters', this.filters)
      this.$emit('close')
    },
    resetFilters() {
      this.search = null
      this.filters = getDefaultFilters()
      this.$emit('changeFilters', this.filters)
    },
    onChangeFilters(field: string, value: string | null) {
      if (field === 'vertical') {
        this.filters.offerCategoryId = null
      }
      this.filters[field] = value
    },
    async getOffers(value: string, update: Function) {
      await lookupOffers(this, value, update)
    },
    async getCampaigns(value: string, update: Function) {
      await lookupCampaigns(this, value, update)
    },
    async getGeos(value: string, update: Function) {
      await lookupGeos(this, value, update)
    },
    async getTrafficProperties(value: string, update: Function) {
      await lookupTrafficProperties(this, value, update)
    },
    async getOfferCategories(value: string, update: Function) {
      await lookupOfferCategories(this, value, update)
    },
    async getSids(value: string, update: Function, sidField: string) {
      await lookupSids(this, value, update, sidField)
    },
    async getTrafficSources(value: string, update: Function) {
      await lookupTrafficSourceNames(this, value, update)
    },
    async getLandings(
      value: string,
      update: Function,
      isLandingFilter: boolean,
    ) {
      await lookupLandings(this, value, update, isLandingFilter)
    },
    onChangeDate(date: { startDate: string; endDate: string }) {
      this.filters.dateFrom = date.startDate
      this.filters.dateTo = date.endDate
    },
  },
})
