
import { defineComponent } from 'vue'
import ChangeLang from '@/components/header/ChangeLang.vue'
import { heroOutline24Plus } from 'quasar-extras-svg-icons/hero-icons-v2'

export default defineComponent({
  name: 'SignUpHeader',
  components: { ChangeLang },
  setup() {
    return {
      heroOutline24Plus,
    }
  },
})
