import {
  formatDate,
  formatValue,
  getAffiliateBalances,
  isDefined,
  RowValue,
} from '@/utils'
import { TransactionReason } from '@/enums/TransactionReason'
import { Currency } from '@/enums/Currency'
import { RewardType } from '@/enums/RewardType'
import { PaymentSystem } from '@/enums/PaymentSystem'
import { BalanceType } from '@/enums/BalanceType'
import i18n from '@/locales/i18n'
import { PaymentSystemNetwork } from '@/enums/PaymentSystemNetwork'

export function getPayoutColumns() {
  return [
    {
      name: 'createdAt',
      label: i18n.t('date'),
      field: 'createdAt',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'paymentSystem',
      label: i18n.t('payment_method'),
      field: 'paymentSystem',
      align: 'left',
      format: (val: string, row: any) =>
        PaymentSystem[row.payoutSystemAccount.paymentSystem] +
        (PaymentSystemNetwork[row.payoutSystemAccount.paymentSystemNetwork]
          ? ` (${PaymentSystemNetwork[
              row.payoutSystemAccount.paymentSystemNetwork
            ].toLowerCase()})`
          : ''),
    },
    {
      name: 'account',
      label: i18n.t('wallet'),
      field: 'account',
      align: 'left',
      classes: 'text-weight-bold',
      format: (val: string, row: any) => row.payoutSystemAccount.account,
    },
    {
      name: 'amount',
      label: i18n.t('sum'),
      field: 'amount',
      align: 'left',
      format: (val: string, row: any) =>
        row.value.amount + ' ' + Currency[row.value.currency],
    },
    {
      name: 'status',
      label: i18n.t('status'),
      field: 'status',
      align: 'left',
      sortable: true,
    },
  ]
}

export function getTransactionsColumns() {
  return [
    {
      name: 'datetime',
      label: i18n.t('date'),
      field: 'datetime',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'holdAmount',
      label: i18n.t('income_hold'),
      field: 'holdAmount',
      align: 'left',
      format: (val: string, row: any) => {
        return (row.reason === TransactionReason.LeadHold ||
          row.reason === TransactionReason.RefBonusPaymentHold) &&
          row.rewardType !== RewardType.RevShare
          ? `${formatValue(row.transactionAmount, 3)} ${
              Currency[row.transactionCurrency]
            }`
          : '—'
      },
    },
    {
      name: 'revShareAmount',
      label: i18n.t('income_revshare'),
      field: 'revShareAmount',
      align: 'left',
      sortable: true,
      format: (val: string, row: any) => {
        return row.rewardType === RewardType.RevShare &&
          row.reason === TransactionReason.LeadHold
          ? `${formatValue(row.transactionAmount, 3)} ${
              Currency[row.transactionCurrency]
            }`
          : '—'
      },
    },
    {
      name: 'offerName',
      label: i18n.t('offer'),
      field: 'offerName',
      align: 'left',
      sortable: true,
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'transactionAmount',
      label: i18n.t('pass_balance'),
      field: 'transactionAmount',
      align: 'left',
    },
    {
      name: 'reason',
      label: i18n.t('status'),
      field: 'reason',
      align: 'left',
      sortable: true,
      format: (val: number) => TransactionReason[val],
    },
    {
      name: 'leadId',
      label: i18n.t('conversion_id'),
      field: 'leadId',
      align: 'left',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'trafficProperty',
      label: i18n.t('labels'),
      field: 'trafficProperty',
      align: 'left',
      format: (val: RowValue) => isDefined(val),
    },
  ]
}

export function getRevShareColumns() {
  return [
    {
      name: 'offerId',
      label: i18n.t('offer_id'),
      field: 'offerId',
      align: 'left',
      sortable: true,
      format: (val: string, row: any) => row.id,
    },
    {
      name: 'offerName',
      label: i18n.t('offer_name'),
      field: 'offerName',
      align: 'left',
      sortable: true,
      format: (val: string, row: any) => row.name,
    },
    {
      name: 'balances',
      label: i18n.t('balance_hold'),
      field: 'balances',
      align: 'left',
      format: (val: string, row: any) =>
        getAffiliateBalances(row.balances, BalanceType.Hold),
    },
  ]
}

export function getConversionsColumns() {
  const arr: any = [
    {
      name: 'createdAt',
      label: i18n.t('registration_date'),
      field: 'createdAt',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'conversionDate',
      label: i18n.t('conversion_date'),
      field: 'conversionDate',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'offerName',
      label: i18n.t('offer'),
      field: 'offerName',
      align: 'center',
      sortable: true,
    },
    {
      name: 'isoCode',
      label: i18n.t('country'),
      field: 'isoCode',
      align: 'center',
      sortable: true,
    },
  ]
  if (!JSON.parse(localStorage.getItem('user') as any)?.showStatisticsOnly) {
    arr.push({
      name: 'amount',
      label: i18n.t('income'),
      field: 'amount',
      align: 'right',
      classes: 'monospaced',
      format: (val: RowValue, row: any) => {
        return formatValue(val, 3) + ' ' + (Currency[row.currency] ?? '')
      },
    })
  }
  arr.push(
    { name: 'id', label: 'ID', field: 'id', align: 'left', sortable: true },
    {
      name: 'trafficProperty',
      label: i18n.t('labels'),
      field: 'trafficProperty',
      align: 'left',
      format: (val: RowValue) => isDefined(val),
    },
  )

  return arr
}
