import { Geo, Offer, OfferCategory, Reward } from '@/store/types'
import { Currency } from '@/enums/Currency'
import { Vertical } from '@/enums/Vertical'
import { RewardType } from '@/enums/RewardType'

function getCurrencies(rewards: Reward[]) {
  if (!rewards.length) {
    return
  }
  if (rewards.length === 1) {
    if (rewards[0].percent) {
      return rewards[0].percent + '%'
    }
    return rewards[0].value.amount + ' ' + Currency[rewards[0].value.currency]
  }

  const amounts = rewards.map(reward => reward.value.amount)
  const min = Math.min(...amounts)
  const max = Math.max(...amounts)
  if (min === max || min === 0) {
    if (max === 0 && rewards[0].percent) {
      return rewards[0].percent + '%'
    }
    return max + ' ' + Currency[rewards[0].value.currency]
  }
  return min + '-' + max + ' ' + Currency[rewards[0].value.currency]
}

export function getAmountsByRewards(allRewards: Reward[]): string {
  const result: string[] = []
  const rewards = allRewards.filter((r: Reward) => !r.withoutValue)
  const usdRewards = rewards.filter(() => true)
  const revShareReward = rewards.find(
    r => r.percent !== null && r.percent !== undefined,
  )
  const revSharePercent = revShareReward ? revShareReward.percent + '%' : ''
  if (!usdRewards.length) {
    return rewards[0]
      ? rewards[0]?.value.amount +
          ' ' +
          Currency[rewards[0]?.value.currency] +
          (revSharePercent ? ', ' + revSharePercent : '')
      : '—'
  }
  const range = getCurrencies(usdRewards)
  if (range) {
    result.push(range)
  }
  return result.join(', ')
}

export function getVerticalByOffer(offer: Offer) {
  const categories = offer.categories
    .map((c: OfferCategory) => c.name)
    .join(', ')
  return Vertical[offer.vertical] + (categories ? ', ' + categories : '')
}

export function getActualRewards(rewards: Reward[]) {
  return rewards
    .filter((r: Reward) => !r.withoutValue)
    .sort(function(a: any, b: any) {
      if (a.trafficSource?.name < b.trafficSource?.name) {
        return -1
      }
      if (a.trafficSource?.name > b.trafficSource?.name) {
        return 1
      }
      return 0
    })
    .map(reward => {
      if (reward.type === RewardType.RevShare) {
        return (
          reward.geos.map(geo => geo.name) +
          ' - ' +
          reward.percent +
          ` % (${RewardType[reward.type]})`
        )
      }
      return (
        reward.geos.map(geo => geo.name) +
        ' - ' +
        reward.value.amount +
        ' ' +
        Currency[reward.value.currency] +
        (reward.trafficSource
          ? ` (${RewardType[reward.type]} - ${reward.trafficSource.name})`
          : ` (${RewardType[reward.type]})`)
      )
    })
    .join(',\n')
}

export function getTypesForRewards(rewards: Reward[]) {
  const rewardTypes = [...new Set(rewards.map(reward => reward.type))]
  return rewardTypes.map(type => {
    let className = 'default-option'
    switch (type) {
      case RewardType.FTD:
        className = 'first-option'
        break
      case RewardType.RevShare:
        className = 'second-option'
        break
      case RewardType.CPA:
      case RewardType.BL:
        className = 'third-option'
        break
      case RewardType.CPL:
      case RewardType.CPS:
      case RewardType.SOI:
      case RewardType.DOI:
        className = 'fourth-option'
        break
    }
    return `<div class='option ${className}'>${RewardType[type]}</div>`
  })
}

export function getGeosForRewards(rewards: Reward[], firstOnly: boolean) {
  const geos = rewards.map(reward => reward.geos).flat()
  const geosUniqueByName = [
    ...new Map(geos.map(item => [item['name'], item])).values(),
  ]
  if (firstOnly) {
    if (geosUniqueByName.length >= 1 && geosUniqueByName.length <= 3) {
      return geosUniqueByName
    }
    return [geosUniqueByName[0], geosUniqueByName[1]]
  }
  return geosUniqueByName.sort((a: Geo, b: Geo) => a.name.localeCompare(b.name))
}
