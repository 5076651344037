import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_route_tab = _resolveComponent("q-route-tab")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_btn_dropdown = _resolveComponent("q-btn-dropdown")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!

  return (_openBlock(), _createBlock(_component_q_toolbar, { class: "header__menu" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_tabs, {
        shrink: "",
        dense: "",
        "inline-label": "",
        style: {"margin-left":"-16px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_route_tab, {
            name: "Dashboard",
            label: _ctx.$t('dashboard'),
            "content-class": "flex inline",
            to: "/dashboard",
            icon: _ctx.heroOutline24Squares2x2,
            "no-caps": "",
            "inline-label": "",
            exact: ""
          }, null, 8, ["label", "icon"]),
          _createVNode(_component_q_route_tab, {
            name: "Offers",
            label: _ctx.$t('offers'),
            to: "/offers",
            icon: _ctx.heroOutline24DocumentText,
            "inline-label": "",
            "no-caps": "",
            exact: ""
          }, null, 8, ["label", "icon"]),
          _createVNode(_component_q_route_tab, {
            name: "Statistics",
            label: _ctx.$t('statistics'),
            to: "/statistics",
            icon: _ctx.heroOutline24ChartBar,
            "inline-label": "",
            "no-caps": "",
            exact: ""
          }, null, 8, ["label", "icon"]),
          _createVNode(_component_q_route_tab, {
            name: "Conversions",
            label: _ctx.$t('conversions'),
            to: "/conversions",
            icon: _ctx.heroOutline24Bolt,
            "inline-label": "",
            "no-caps": "",
            exact: ""
          }, null, 8, ["label", "icon"]),
          _createVNode(_component_q_route_tab, {
            name: "Campaigns",
            label: _ctx.$t('campaigns'),
            to: "/campaigns",
            icon: _ctx.heroOutline24Share,
            "inline-label": "",
            "no-caps": "",
            exact: ""
          }, null, 8, ["label", "icon"]),
          false
            ? (_openBlock(), _createBlock(_component_q_btn_dropdown, {
                key: 0,
                "auto-close": "",
                stretch: "",
                flat: "",
                label: _ctx.$t('app'),
                icon: _ctx.heroOutline24DevicePhoneMobile,
                "dropdown-icon": _ctx.heroOutline24ChevronDown,
                "no-caps": ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_list, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_route_tab, {
                        name: "Apps",
                        label: _ctx.$t('app'),
                        to: "/apps",
                        "inline-label": "",
                        "no-caps": "",
                        exact: ""
                      }, null, 8, ["label"]),
                      _createVNode(_component_q_route_tab, {
                        name: "Streams",
                        label: _ctx.$t('campaigns'),
                        to: "/apps-campaigns",
                        "inline-label": "",
                        "no-caps": "",
                        exact: ""
                      }, null, 8, ["label"]),
                      _createVNode(_component_q_route_tab, {
                        name: "Sharing",
                        label: _ctx.$t('sharing'),
                        to: "/sharing",
                        "inline-label": "",
                        "no-caps": "",
                        exact: ""
                      }, null, 8, ["label"]),
                      _createVNode(_component_q_route_tab, {
                        name: "Statistics",
                        label: _ctx.$t('statistics'),
                        to: "/apps-statistics",
                        "inline-label": "",
                        "no-caps": "",
                        exact: ""
                      }, null, 8, ["label"]),
                      _createVNode(_component_q_route_tab, {
                        name: "Push",
                        label: _ctx.$t('pushes'),
                        to: "/push",
                        "inline-label": "",
                        "no-caps": "",
                        exact: ""
                      }, null, 8, ["label"]),
                      _createVNode(_component_q_route_tab, {
                        name: "Transactions",
                        label: _ctx.$t('transactions'),
                        to: "/apps-transactions",
                        "inline-label": "",
                        "no-caps": "",
                        exact: ""
                      }, null, 8, ["label"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["label", "icon", "dropdown-icon"]))
            : _createCommentVNode("", true),
          _createVNode(_component_q_btn_dropdown, {
            "auto-close": "",
            stretch: "",
            flat: "",
            label: _ctx.$t('tools'),
            icon: _ctx.heroOutline24Wrench,
            "dropdown-icon": _ctx.heroOutline24ChevronDown,
            "no-caps": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_list, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_route_tab, {
                    name: "Global postback",
                    label: _ctx.$t('postbacks'),
                    to: "/postbacks",
                    "inline-label": "",
                    "no-caps": "",
                    exact: ""
                  }, null, 8, ["label"]),
                  _createVNode(_component_q_route_tab, {
                    name: "Domain parking",
                    label: _ctx.$t('parking'),
                    to: "/redirect-domains",
                    "inline-label": "",
                    "no-caps": "",
                    exact: ""
                  }, null, 8, ["label"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["label", "icon", "dropdown-icon"]),
          _createVNode(_component_q_route_tab, {
            name: "Payouts",
            label: _ctx.$t('payouts'),
            to: "/payments",
            icon: _ctx.heroOutline24CreditCard,
            "inline-label": "",
            "no-caps": "",
            exact: ""
          }, null, 8, ["label", "icon"]),
          _createVNode(_component_q_route_tab, {
            name: "Ref.program",
            label: _ctx.$t('referral_program'),
            to: "/referrals",
            icon: _ctx.heroOutline24Users,
            "inline-label": "",
            "no-caps": "",
            exact: ""
          }, null, 8, ["label", "icon"]),
          _createVNode(_component_q_route_tab, {
            name: "Logs",
            label: _ctx.$t('logs'),
            to: "/logs",
            icon: _ctx.heroOutline24CircleStack,
            "inline-label": "",
            "no-caps": "",
            exact: ""
          }, null, 8, ["label", "icon"]),
          _createVNode(_component_q_route_tab, {
            name: "Support",
            label: _ctx.$t('support'),
            to: "/tickets",
            icon: _ctx.heroOutline24ChatBubbleLeftRight,
            "inline-label": "",
            "no-caps": "",
            exact: ""
          }, null, 8, ["label", "icon"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}