import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "row q-mb-xs",
  style: {"color":"#6F757B","font-size":"10px"}
}
const _hoisted_2 = { class: "row justify-between items-center full-width text-weight-bold" }
const _hoisted_3 = {
  key: 0,
  style: {"max-width":"315px"}
}
const _hoisted_4 = {
  key: 1,
  style: {"max-width":"315px"}
}
const _hoisted_5 = {
  key: 2,
  style: {"max-width":"315px"}
}
const _hoisted_6 = {
  key: 3,
  style: {"max-width":"315px"}
}
const _hoisted_7 = {
  key: 4,
  style: {"max-width":"315px"}
}
const _hoisted_8 = {
  key: 5,
  style: {"max-width":"315px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(_component_q_btn, {
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeNotificationsMenu = false)),
    "no-caps": "",
    flat: "",
    icon: _ctx.heroOutline24Bell,
    size: "11px",
    class: "header__notifications self-stretch q-px-lg"
  }, {
    default: _withCtx(() => [
      (_ctx.notifications.length)
        ? (_openBlock(), _createBlock(_component_q_badge, {
            key: 0,
            color: "blue",
            floating: "",
            rounded: ""
          }))
        : _createCommentVNode("", true),
      (_ctx.notifications.length && !_ctx.closeNotificationsMenu)
        ? (_openBlock(), _createBlock(_component_q_menu, {
            key: 1,
            class: "notifications-menu"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_list, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (n) => {
                    return (_openBlock(), _createBlock(_component_q_item, {
                      class: "notify-item",
                      key: n.id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, { class: "row" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.format(n.createdAt)), 1),
                            _createElementVNode("div", _hoisted_2, [
                              (
                  n.eventType === _ctx.notificationEventType.OfferAccess &&
                    n.type === _ctx.notificationType.Success
                )
                                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(`${_ctx.$t('offer_access')} ${n.message} ${_ctx.$t('opened')}`), 1))
                                : (
                  n.eventType === _ctx.notificationEventType.OfferAccess &&
                    n.type === _ctx.notificationType.Error
                )
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(`${_ctx.$t('offer_access')} ${n.message} ${_ctx.$t('closed')}`), 1))
                                  : (
                  n.eventType === _ctx.notificationEventType.PayoutChanged &&
                    n.type === _ctx.notificationType.Success
                )
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('payout_complete')), 1))
                                    : (
                  n.eventType === _ctx.notificationEventType.PayoutChanged &&
                    n.type === _ctx.notificationType.Error
                )
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('payout_rejected')), 1))
                                      : (n.eventType === _ctx.notificationEventType.TicketReply)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('ticket_reply')), 1))
                                        : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(n.message), 1)),
                              _createVNode(_component_q_icon, {
                                class: "cursor-pointer",
                                size: "16px",
                                onClick: ($event: any) => (_ctx.readOne(n.id)),
                                color: "primary",
                                name: _ctx.heroOutline24CheckCircle
                              }, null, 8, ["onClick", "name"])
                            ])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }),
              _createElementVNode("div", {
                class: "close-menu row justify-end cursor-pointer q-mt-xs text-primary text-weight-bold",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.readAll && _ctx.readAll(...args)))
              }, _toDisplayString(_ctx.$t('mark_read_all')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["icon"]))
}