import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "mobile-menu q-py-lg q-px-md flex column no-wrap" }
const _hoisted_2 = {
  key: 0,
  class: "q-mb-md"
}
const _hoisted_3 = { class: "mobile-menu__nav" }
const _hoisted_4 = {
  class: "flex q-mt-auto",
  style: {"border-top":"1px solid var(--border-color)","border-bottom":"1px solid var(--border-color)","height":"56px","margin-left":"-16px","margin-right":"-16px"}
}
const _hoisted_5 = { class: "flex q-mr-auto header_border" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderPayment = _resolveComponent("HeaderPayment")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _component_HeaderNotifications = _resolveComponent("HeaderNotifications")!
  const _component_ChangeLang = _resolveComponent("ChangeLang")!
  const _component_HeaderProfile = _resolveComponent("HeaderProfile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$q.screen.width <= 767)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_HeaderPayment)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_q_btn, {
        name: "Dashboard",
        label: _ctx.$t('dashboard'),
        to: "/dashboard",
        icon: _ctx.heroOutline24Squares2x2,
        "no-caps": "",
        "inline-label": "",
        flat: "",
        exact: "",
        align: "left",
        class: "q-py-sm",
        size: "14px"
      }, null, 8, ["label", "icon"]),
      _createVNode(_component_q_btn, {
        name: "Offers",
        label: _ctx.$t('offers'),
        to: "/offers",
        icon: _ctx.heroOutline24DocumentText,
        "inline-label": "",
        "no-caps": "",
        exact: "",
        align: "left",
        flat: "",
        class: "q-py-sm"
      }, null, 8, ["label", "icon"]),
      _createVNode(_component_q_btn, {
        name: "Statistics",
        label: _ctx.$t('statistics'),
        to: "/statistics",
        icon: _ctx.heroOutline24ChartBar,
        "inline-label": "",
        "no-caps": "",
        exact: "",
        align: "left",
        flat: "",
        class: "q-py-sm"
      }, null, 8, ["label", "icon"]),
      _createVNode(_component_q_btn, {
        name: "Conversions",
        label: _ctx.$t('conversions'),
        to: "/conversions",
        icon: _ctx.heroOutline24Bolt,
        "inline-label": "",
        "no-caps": "",
        exact: "",
        align: "left",
        flat: "",
        class: "q-py-sm"
      }, null, 8, ["label", "icon"]),
      _createVNode(_component_q_btn, {
        name: "Campaigns",
        label: _ctx.$t('campaigns'),
        to: "/campaigns",
        icon: _ctx.heroOutline24Share,
        "inline-label": "",
        "no-caps": "",
        exact: "",
        align: "left",
        flat: "",
        class: "q-py-sm"
      }, null, 8, ["label", "icon"]),
      false
        ? (_openBlock(), _createBlock(_component_q_expansion_item, {
            key: 0,
            label: "Apps",
            icon: _ctx.heroOutline24DevicePhoneMobile
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                name: "Apps",
                label: _ctx.$t('app'),
                to: "/apps",
                "inline-label": "",
                "no-caps": "",
                exact: "",
                align: "left",
                flat: ""
              }, null, 8, ["label"]),
              _createVNode(_component_q_btn, {
                name: "Streams",
                label: _ctx.$t('campaigns'),
                to: "/apps-campaigns",
                "inline-label": "",
                "no-caps": "",
                exact: "",
                align: "left",
                flat: ""
              }, null, 8, ["label"]),
              _createVNode(_component_q_btn, {
                name: "Sharing",
                label: _ctx.$t('sharing'),
                to: "/sharing",
                "inline-label": "",
                "no-caps": "",
                exact: "",
                align: "left",
                flat: ""
              }, null, 8, ["label"]),
              _createVNode(_component_q_btn, {
                name: "Statistics",
                label: _ctx.$t('statistics'),
                to: "/apps-statistics",
                "inline-label": "",
                "no-caps": "",
                exact: "",
                align: "left",
                flat: ""
              }, null, 8, ["label"]),
              _createVNode(_component_q_btn, {
                name: "Push",
                label: _ctx.$t('pushes'),
                to: "/push",
                "inline-label": "",
                "no-caps": "",
                exact: "",
                align: "left",
                flat: ""
              }, null, 8, ["label"]),
              _createVNode(_component_q_btn, {
                name: "Transactions",
                label: _ctx.$t('transactions'),
                to: "/apps-transactions",
                "inline-label": "",
                "no-caps": "",
                exact: "",
                align: "left",
                flat: ""
              }, null, 8, ["label"])
            ]),
            _: 1
          }, 8, ["icon"]))
        : _createCommentVNode("", true),
      _createVNode(_component_q_expansion_item, {
        label: _ctx.$t('tools'),
        icon: _ctx.heroOutline24Wrench
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_btn, {
            name: "Global postback",
            label: _ctx.$t('postbacks'),
            to: "/postbacks",
            "inline-label": "",
            "no-caps": "",
            exact: "",
            align: "left",
            flat: ""
          }, null, 8, ["label"]),
          _createVNode(_component_q_btn, {
            name: "Domain parking",
            label: _ctx.$t('parking'),
            to: "/redirect-domains",
            "inline-label": "",
            "no-caps": "",
            exact: "",
            align: "left",
            flat: ""
          }, null, 8, ["label"])
        ]),
        _: 1
      }, 8, ["label", "icon"]),
      _createVNode(_component_q_btn, {
        name: "Payouts",
        label: _ctx.$t('payouts'),
        to: "/payments",
        icon: _ctx.heroOutline24CreditCard,
        "inline-label": "",
        "no-caps": "",
        exact: "",
        align: "left",
        flat: "",
        class: "q-py-sm"
      }, null, 8, ["label", "icon"]),
      _createVNode(_component_q_btn, {
        name: "Ref.program",
        label: _ctx.$t('referral_program'),
        to: "/referrals",
        icon: _ctx.heroOutline24Users,
        "inline-label": "",
        "no-caps": "",
        exact: "",
        align: "left",
        flat: "",
        class: "q-py-sm"
      }, null, 8, ["label", "icon"]),
      _createVNode(_component_q_btn, {
        name: "Logs",
        label: _ctx.$t('logs'),
        to: "/logs",
        icon: _ctx.heroOutline24CircleStack,
        "inline-label": "",
        "no-caps": "",
        exact: "",
        align: "left",
        flat: "",
        class: "q-py-sm"
      }, null, 8, ["label", "icon"]),
      _createVNode(_component_q_btn, {
        name: "Support",
        label: _ctx.$t('support'),
        to: "/tickets",
        icon: _ctx.heroOutline24ChatBubbleLeftRight,
        "inline-label": "",
        "no-caps": "",
        exact: "",
        align: "left",
        flat: "",
        class: "q-py-sm"
      }, null, 8, ["label", "icon"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_HeaderNotifications),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_ChangeLang)
      ]),
      _createVNode(_component_HeaderProfile)
    ])
  ]))
}