
import axios from 'axios'
import { defineComponent } from 'vue'
import { showSuccessSnackBar } from '@/utils'
import i18n from '@/locales/i18n'

export default defineComponent({
  name: 'CampaignUrlsBuilder',
  props: ['open', 'close', 'campaignId'],
  async created() {
    this.campaignUrl = (
      await axios.get('/api/campaigns/' + this.campaignId + '/url')
    ).data
  },
  computed: {
    showDialog(): boolean {
      return this.open
    },
  },
  data: function() {
    return {
      sid1: null,
      sid2: null,
      sid3: null,
      sid4: null,
      sid5: null,
      sid6: null,
      sid7: null,
      tdsEventId: null,
      campaignUrl: '',
      fullUrl: '',
    }
  },
  watch: {
    campaignUrl() {
      this.fullUrl = this.campaignUrl
    },
    sid1() {
      this.changeUrl()
    },
    sid2() {
      this.changeUrl()
    },
    sid3() {
      this.changeUrl()
    },
    sid4() {
      this.changeUrl()
    },
    sid5() {
      this.changeUrl()
    },
    sid6() {
      this.changeUrl()
    },
    sid7() {
      this.changeUrl()
    },
    tdsEventId() {
      this.changeUrl()
    },
  },
  methods: {
    copyUrl() {
      const url = document.getElementById('campaign_url') as HTMLInputElement
      url.select()
      document.execCommand('copy')
      showSuccessSnackBar(i18n.t('copied'))
    },
    changeUrl() {
      this.fullUrl = this.campaignUrl
      if (
        !this.sid1 &&
        !this.sid2 &&
        !this.sid3 &&
        !this.sid4 &&
        !this.sid5 &&
        !this.sid6 &&
        !this.sid7 &&
        !this.tdsEventId
      ) {
        return
      }
      this.fullUrl += '?'
      const sids = [
        this.sid1,
        this.sid2,
        this.sid3,
        this.sid4,
        this.sid5,
        this.sid6,
        this.sid7,
        this.tdsEventId,
      ]
      for (let index = 0; index < sids.length; index++) {
        if (!sids[index]) {
          continue
        }
        if (index === 7) {
          // tdsEventId
          this.fullUrl += `tds_event_id=${sids[index]}&`
          continue
        }
        this.fullUrl += `sub${index + 1}=${sids[index]}&`
      }
      this.fullUrl = this.fullUrl.slice(0, -1)
    },
  },
})
