
import { defineComponent } from 'vue'
import { downloadPromo } from '@/utils'
import { MarkupType } from '@/enums/MarkupType'
import { AffiliateAccessStatus } from '@/enums/AffiliateAccessStatus'
import i18n from '@/locales/i18n'

const columns = [
  {
    name: 'name',
    label: i18n.t('landing_name'),
    field: 'name',
    align: 'left',
    classes: 'text-weight-bold',
  },
  {
    name: 'type',
    label: '',
    field: 'type',
    align: 'left',
  },
  {
    name: 'archive',
    label: '',
    field: 'archive',
    align: 'left',
  },
  {
    name: 'link',
    label: i18n.t('link'),
    field: 'link',
    align: 'left',
    style: 'width: 130px',
  },
]

export default defineComponent({
  name: 'OfferLandings',
  props: ['promos', 'offer'],
  setup() {
    columns
  },
  data() {
    return {
      markupTypes: MarkupType,
    }
  },
  computed: {
    columns(): any {
      return columns
    },
  },
  methods: {
    hasOfferAccess() {
      return !(
        !this.offer.offersAccess ||
        !((this.offer.offersAccess as unknown) as {
          status: AffiliateAccessStatus
        })?.status
      )
    },
    async download(id: string) {
      if (!this.hasOfferAccess()) {
        return
      }
      await downloadPromo(id)
    },
    landingRedirect(landingUrl: string) {
      if (!this.hasOfferAccess()) {
        return
      }
      window.open(landingUrl)
    },
  },
})
