import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "section-container background-gray" }
const _hoisted_2 = { class: "flex justify-between items-center q-py-lg" }
const _hoisted_3 = { class: "page-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_Filters = _resolveComponent("Filters")!
  const _component_ConversionsTable = _resolveComponent("ConversionsTable")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-mb-lg" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (Object.keys(_ctx.$route.params)?.length)
          ? (_openBlock(), _createBlock(_component_q_btn, {
              key: 0,
              "no-caps": "",
              icon: "chevron_left",
              flat: "",
              label: "Назад",
              onClick: _ctx.backToStats
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('conversions')), 1),
          _createVNode(_component_q_btn, {
            label: "Export",
            color: "primary",
            class: "q-btn__main-size text-weight-bold",
            "icon-right": _ctx.heroOutline24ArrowDownTray,
            outline: "",
            "no-caps": "",
            onClick: _ctx.onExport
          }, null, 8, ["icon-right", "onClick"])
        ])
      ]),
      _createVNode(_component_Filters, {
        onReset: _ctx.resetFilters,
        onChange: _ctx.onChangeFilters,
        onChangeAll: _ctx.changeAll,
        onExport: _ctx.onExport,
        "current-filters": _ctx.filters
      }, null, 8, ["onReset", "onChange", "onChangeAll", "onExport", "current-filters"]),
      _createVNode(_component_ConversionsTable, {
        filters: _ctx.filters,
        tab: _ctx.statsTab,
        exportCsv: _ctx.exportCsv
      }, null, 8, ["filters", "tab", "exportCsv"])
    ]),
    _: 1
  }))
}