import i18n from '@/locales/i18n'

export default function getColumns() {
  return [
    {
      name: 'logo',
      label: 'Logo',
      field: 'logo',
      align: 'left',
    },
    {
      name: 'name',
      label: i18n.t('offer'),
      field: 'name',
      align: 'left',
      sortable: true,
    },
    { name: 'amount', label: i18n.t('bid'), field: 'amount', align: 'left' },
    {
      name: 'geo',
      label: i18n.t('geo'),
      field: 'geo',
      align: 'left',
    },
    {
      name: 'rewardType',
      label: i18n.t('reward_type'),
      field: 'rewardType',
      align: 'left',
    },
    {
      name: 'actions',
      label: 'Action',
      field: 'actions',
      align: 'left',
      headerStyle: 'width: 335px',
    },
  ]
}
