import { PayoutState } from '@/store/types'
import { Currency } from '@/enums/Currency'

function getDefaultData(): PayoutState {
  return {
    payoutLeads: {
      leadIds: [],
      amount: 0,
      currency: Currency.USD,
    },
    errors: {},
  }
}

export default getDefaultData
