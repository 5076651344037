import { formatDate, formatValue, isDefined, RowValue } from '@/utils'
import { Row } from '@/utils/request'
import { Currency } from '@/enums/Currency'
import { LeadStatus } from '@/enums/LeadStatus'
import i18n from '@/locales/i18n'

export default function getColumns() {
  return [
    { name: 'id', label: 'ID', field: 'id', align: 'left', sortable: true },
    {
      name: 'datetime',
      label: i18n.t('registration_date'),
      field: 'datetime',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'conversionDate',
      label: i18n.t('conversion_date'),
      field: 'conversionDate',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'offerName',
      label: i18n.t('offer'),
      field: 'offerName',
      align: 'left',
      sortable: true,
    },
    {
      name: 'status',
      label: i18n.t('status'),
      field: 'status',
      align: 'left',
      sortable: true,
      format: (val: number, row: Row) => {
        if (row.attributes?.advertiserStatus) {
          return LeadStatus[val] + ` ( ${row.attributes?.advertiserStatus} ) `
        }
        return LeadStatus[val]
      },
    },
    {
      name: 'geo',
      label: i18n.t('country'),
      field: 'geo',
      align: 'left',
      sortable: true,
    },
    {
      name: 'amount',
      label: i18n.t('income'),
      field: 'amount',
      align: 'left',
      // sortable: true,
      format: (val: RowValue, row: any) => {
        return formatValue(val, 3) + ' ' + (Currency[row.currency] ?? '')
      },
    },
    {
      name: 'type',
      label: i18n.t('reward_type'),
      field: 'type',
      align: 'left',
      classes: 'text-weight-bold',
      format: (val: RowValue, row: Row) => {
        if (row.status === LeadStatus.Pending && row.amount === null) {
          return 'Registration'
        }
        return isDefined(val)
      },
    },
    {
      name: 'trafficProperty',
      label: i18n.t('labels'),
      field: 'trafficProperty',
      align: 'left',
      format: (val: RowValue) => isDefined(val),
    },
  ]
}
