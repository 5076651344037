import i18n from '@/locales/i18n'
import { formatDate } from '@/utils'

export default function getColumns() {
  return [
    { name: 'id', label: 'ID', field: 'id', align: 'left', sortable: true },
    {
      name: 'name',
      label: i18n.t('link'),
      field: 'name',
      align: 'left',
      sortable: true,
    },
    {
      name: 'date',
      label: 'Date',
      field: 'updatedAt',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'status',
      label: 'Status',
      field: 'status',
      align: 'left',
      sortable: true,
    },
  ]
}
