
import { mapActions, mapState } from 'vuex'
import axios from 'axios'
import { BalanceType } from '@/enums/BalanceType'
import { defineComponent } from 'vue'
import { Currency } from '@/enums/Currency'
import { formatValue, showSuccessSnackBar } from '@/utils'
import { FormatMoney } from 'format-money-js'
import i18n from '@/locales/i18n'
import { NotificationType } from '@/enums/NotificationType'
import { NotificationEventType } from '@/enums/NotificationEventType'

import {
  heroOutline24Bell,
  heroOutline24ChevronDown,
  heroOutline24Bars3,
} from 'quasar-extras-svg-icons/hero-icons-v2'
import ChangeLang from '@/components/header/ChangeLang.vue'
import HeaderNotifications from '@/components/header/HeaderNotifications.vue'
import HeaderMenu from '@/components/header/HeaderMenu.vue'
import HeaderPayment from '@/components/header/HeaderPayment.vue'
import HeaderProfile from '@/components/header/HeaderProfile.vue'

export default defineComponent({
  name: 'Header',
  components: {
    HeaderProfile,
    HeaderMenu,
    HeaderNotifications,
    ChangeLang,
    HeaderPayment,
  },
  props: ['changeLangForce', 'openDrawer'],
  setup() {
    return {
      heroOutline24Bell,
      heroOutline24ChevronDown,
      heroOutline24Bars3,
    }
  },
  computed: {
    ...mapState('authModule', ['user']),
    moneyIconPath(): string {
      return !this.$route.path.includes('/offers/')
        ? 'img:./images/money-icon.png'
        : 'img:../images/money-icon.png'
    },
    profileImagePath(): string {
      return this.affiliate.image
        ? `img:${this.storageUrl + '/' + this.affiliate.image}`
        : 'account_circle'
    },
  },
  async created(): Promise<void> {
    await this.onRequest()
    this.notifications = (await axios.get('/api/notifications')).data
    // const lang = localStorage.getItem('lang') || 'ru'
    // await this.changeLang(lang)
  },
  data: function() {
    const moneyFormatter = new FormatMoney({
      decimals: 2,
      separator: ' ',
    })
    return {
      notificationEventType: NotificationEventType,
      notificationType: NotificationType,
      notifications: [],
      closeNotificationsMenu: false,
      langLabel: 'RU',
      moneyFormatter,
      storageUrl: process.env.CDN_STORAGE_URL,
      affiliate: { image: '' },
      balanceTypes: BalanceType,
      currencies: Currency,
      availableBalances: [{ value: { amount: 0, currency: Currency.USD } }],
      holdBalances: [{ value: { amount: 0, currency: Currency.USD } }],
    }
  },
  methods: {
    ...mapActions('authModule', ['logout']),
    // async changeLang(lang: string) {
    //   this.langLabel = lang.toUpperCase()
    //   i18n.setLocale(lang)
    //   await axios.post('/api/auth/lang', { lang })
    //   this.$emit('changeLangForce')
    //   localStorage.setItem('lang', lang)
    // },
    async readOne(notificationId: number) {
      await axios.post('/api/notifications/' + notificationId)
      this.notifications = (await axios.get('/api/notifications')).data
    },
    async readAll() {
      await axios.post('/api/notifications/all')
      this.notifications = (await axios.get('/api/notifications')).data
    },
    copy(id: string) {
      const element = document.createElement('textarea')
      document.body.appendChild(element)
      element.value = id
      element.select()
      document.execCommand('copy')
      document.body.removeChild(element)
      showSuccessSnackBar(i18n.t('copied'))
    },
    getFormattedValue(value: number) {
      return this.moneyFormatter.from(
        Number(formatValue(Number(value), 2, true)),
        {},
      )
    },
    async onRequest() {
      this.affiliate = (await axios.get('/api/profile')).data
    },
    closeMenu() {
      this.closeNotificationsMenu = true
      setTimeout(() => {
        this.closeNotificationsMenu = false
      }, 400)
    },
  },
})
