
import axios from 'axios'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AddDomainDialog',
  props: ['open', 'close', 'refresh'],
  computed: {
    showDialog(): boolean {
      return this.open
    },
  },
  data: function() {
    return {
      name: null,
      errors: {},
      loading: false,
    }
  },
  methods: {
    addRedirectDomain() {
      this.loading = true
      axios
        .post('/api/redirect-domains', { name: this.name })
        .then(() => {
          this.onClose()
          this.$emit('refresh')
        })
        .catch(error => {
          this.errors = error.response.data.errors
        })
        .finally(() => {
          this.loading = false
        })
    },
    onClose() {
      this.name = null
      this.errors = {}
      this.$emit('close')
    },
  },
})
