
import { defineComponent } from 'vue'
import {
  getTableProperties,
  request,
  Table,
  TableRequestProps,
} from '@/utils/request'
import { getRevShareColumns } from '@/pages/payments/config/columns'
import { getAffiliateBalances } from '@/utils'
import { BalanceType } from '@/enums/BalanceType'
import { Balance } from '@/store/types'

export default defineComponent({
  name: 'RevShare',
  async created() {
    await this.onRequest({ pagination: this.pagination })
  },
  data: function() {
    return {
      ...getTableProperties('offerId'),
      columns: getRevShareColumns(),
      balanceTypes: BalanceType,
    }
  },
  methods: {
    async onRequest(props: TableRequestProps) {
      await request(props, this as Table, '/api/balances/revshare')
    },
    getAffBalancesRaw(balances: Balance[]) {
      return getAffiliateBalances(balances, this.balanceTypes.Hold)
    },
  },
})
