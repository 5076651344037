
import { defineComponent } from 'vue'
import { heroOutline24ChevronDown } from 'quasar-extras-svg-icons/hero-icons-v2'
import { mapActions, mapState } from 'vuex'

export default defineComponent({
  name: 'HeaderProfile',
  setup() {
    return {
      heroOutline24ChevronDown,
    }
  },
  data() {
    return {
      storageUrl: process.env.CDN_STORAGE_URL,
      affiliate: { image: '' },
    }
  },
  computed: {
    ...mapState('authModule', ['user']),
    profileImagePath(): string {
      return this.user?.data?.image
        ? `img:${this.storageUrl + '/' + this.user.data.image}`
        : 'account_circle'
    },
  },
  methods: {
    ...mapActions('authModule', ['logout']),
  },
})
