
import Header from '../components/header/Header.vue'
// import Drawer from '../components/drawer/Drawer.vue'
import Footer from '../components/footer/Footer.vue'
import MobileMenu from '../components/header/MobileMenu.vue'
import { defineComponent } from 'vue'
import axios from 'axios'
import { mapMutations } from 'vuex'

export default defineComponent({
  name: 'DashboardView',
  components: { Footer, Header, MobileMenu },
  data: function() {
    return {
      miniState: false,
      drawer: false,
      mobileMenuActive: false,
    }
  },
  watch: {
    $route() {
      this.mobileMenuActive = false
    },
  },
  mounted() {
    axios
      .get('/api/profile')
      .then(() => {
        if (this.$route.path === '/') {
          this.$router.push('/dashboard')
        }
      })
      .catch(err => {
        if (err.response.status === 401 || err.response.status === 403) {
          this.logout()
        }
      })
  },
  methods: {
    ...mapMutations('authModule', ['logout']),
    changeLang() {
      this.$forceUpdate()
    },
    changeMiniState() {
      this.miniState = !this.miniState
    },
    openDrawer() {
      this.drawer = !this.drawer
    },
    toggleMenu() {
      this.mobileMenuActive = !this.mobileMenuActive
    },
  },
})
