import { getData } from '@/utils/getData'
import { sub, format } from 'date-fns'
import { RewardType } from '@/enums/RewardType'
import { Vertical } from '@/enums/Vertical'
import { CampaignStatus } from '@/enums/CampaignStatus'
import { getIncludeOptions } from '@/utils/filters'
import { useStorage } from 'vue3-storage'

export type ReadOptions = {
  filters: FiltersType
  groupBy?: GroupBy
  orderBy?: OrderBy
  limit?: number
  offset?: number
}

export type GroupByOptions = Array<{
  id: string
  name: string
  field: string
}>

export type FiltersType = {
  offerId?: number
  campaignId?: number
  landingId?: number
  preLandingId?: number
  geoId?: number
  status?: CampaignStatus
  markupType?: number
  dateFrom?: number | string
  dateTo?: number | string
  groupBy?: string
}

type GroupBy = string[]
type OrderBy = { [key: string]: string }
export type Row = { [key: string]: any }
export type Column = { [key: string]: any }

type Pagination = {
  page: number
  rowsNumber: number
  rowsPerPage: number
  sortBy: string
  descending: boolean
}
export type Table = {
  loading: boolean
  rows: string[]
  pagination: Pagination
  filters?: FiltersType
  onRequest: Function
}

export type TableRequestProps = {
  pagination: Pagination
  row?: Row
}

export async function request(
  props: TableRequestProps,
  ref: Table,
  url: string,
) {
  const { page, rowsPerPage, sortBy, descending } = props.pagination
  ref.loading = true
  const limit = rowsPerPage === 0 ? ref.pagination.rowsNumber : rowsPerPage
  const offset = (page - 1) * rowsPerPage
  const orderBy = {
    [sortBy !== 'groupByField'
      ? sortBy
      : String(ref.filters?.groupBy)]: descending ? 'DESC' : 'ASC',
  }
  ref.rows = await getData(
    { filters: ref.filters || {}, limit, offset, orderBy },
    url,
  )
  ref.pagination.rowsNumber = (ref.rows.length
    ? ((ref.rows[0] as unknown) as Row).total
    : ref.pagination.rowsNumber) as number
  ref.pagination.page = page
  ref.pagination.rowsPerPage = rowsPerPage
  ref.pagination.sortBy = sortBy
  ref.pagination.descending = descending
  ref.loading = false
}

export function getPagination(sortBy: string, rowsPerPage = 10) {
  return {
    sortBy,
    descending: true,
    page: 1,
    rowsPerPage,
    rowsNumber: 100,
  }
}

export function getDefaultFilters() {
  const date = new Date()
  const lastMonth = sub(date, { months: 1 })
  const dateTo = format(date, 'yyyy/MM/dd')
  const dateFrom = format(lastMonth, 'yyyy/MM/dd')
  const timezone = useStorage().getStorageSync('affiliateTimezone')
  return {
    ...getIncludeOptions(),
    factualStats: true,
    groupBy: 'date',
    dateFrom,
    dateTo,
    timezoneOffset:
      timezone !== null && timezone !== undefined
        ? timezone
        : -(new Date().getTimezoneOffset() / 60),
  }
}

export function getDefaultFiltersLast7Days() {
  const date = new Date()
  const lastWeek = sub(date, { weeks: 1 })
  const dateTo = format(date, 'yyyy/MM/dd')
  const dateFrom = format(lastWeek, 'yyyy/MM/dd')
  const timezone = useStorage().getStorageSync('affiliateTimezone')
  return {
    ...getIncludeOptions(),
    factualStats: true,
    groupBy: 'date',
    dateFrom,
    dateTo,
    timezoneOffset:
      timezone !== null && timezone !== undefined
        ? timezone
        : -(new Date().getTimezoneOffset() / 60),
  }
}

export const getTableProperties = (sortBy: string, rowsPerPage = 10) => ({
  rows: [],
  loading: true,
  pagination: getPagination(sortBy, rowsPerPage),
})

export function getGroupByField(
  key: 'id' | 'name' | 'field',
  row: Row,
  groupByOptions: GroupByOptions,
  filters: FiltersType,
): string | undefined {
  for (const option of groupByOptions) {
    if (option.id !== filters.groupBy) {
      continue
    }
    const fieldName = option[key]
    if (key === 'field') {
      switch (fieldName) {
        case 'rewardType':
          return RewardType[Number(row[fieldName])]
        case 'vertical':
          return Vertical[Number(row[fieldName])]
        default:
          return row[fieldName]
      }
    }
    return fieldName
  }
}
