import CampaignsList from '@/pages/campaigns/CampaignsList.vue'
import OffersList from '@/pages/offers/OffersList.vue'
import Offer from '@/pages/offers/Offer.vue'
import Login from '@/pages/login/Login.vue'
import SignUp from '@/pages/signup/SignUp.vue'
import Statistics from '@/pages/statistics/Statistics.vue'
import DashboardView from '@/views/DashboardView.vue'
import MainLanding from '@/views/MainLanding.vue'
import Conversions from '@/pages/conversions/Conversions.vue'
import Payments from '@/pages/payments/Payments.vue'
import GlobalPostbacks from '@/pages/global-postbacks/GlobalPostbacks.vue'
import Profile from '@/pages/profile/Profile.vue'
import RedirectDomains from '@/pages/redirect-domains/RedirectDomains.vue'
import ApiIntegration from '@/pages/api-integration/ApiIntegration.vue'
import SupportTickets from '@/pages/support/SupportTickets.vue'
import BeginResetPassword from '@/pages/login/BeginResetPassword.vue'
import EndResetPassword from '@/pages/login/EndResetPassword.vue'
import TicketMessage from '@/pages/support/TicketMessages.vue'
import MobileAppsList from '@/pages/mobile-apps/MobileAppsList.vue'
import Transactions from '@/pages/transactions/Transactions.vue'
import PushNotificationsList from '@/pages/push-notifications/PushNotificationsList.vue'
import PushNotification from '@/pages/push-notifications/PushNotification.vue'
import AppSharing from '@/pages/sharing/AppSharing.vue'
import AppsStatistics from '@/pages/apps-statistics/Statistics.vue'
import AppsCampaignsList from '@/pages/apps-campaigns/CampaignsList.vue'
import AppsCampaignEdit from '@/pages/apps-campaigns/CampaignEdit.vue'
import Logs from '@/pages/request-logs/Logs.vue'
import ReferralProgram from '@/pages/referrals/ReferralProgram.vue'
import Dashboard from '@/pages/dashboard/Dashboard.vue'
import ComingSoon from '@/views/ComingSoon.vue'

const routes = [
  { path: '/', component: MainLanding, meta: { requiresAuth: false } },
  { path: '/login', component: Login },
  { path: '/reset-password', component: BeginResetPassword },
  { path: '/reset-password/:key', component: EndResetPassword },
  { path: '/sign-up', component: SignUp },
  {
    path: '/',
    component: DashboardView,
    name: 'basic',
    meta: { requiresAuth: true },
    children: [
      { path: 'campaigns', component: CampaignsList },
      { path: 'offers', component: OffersList },
      { path: 'offers/:id', component: Offer, name: 'offer' },
      { path: 'statistics', component: Statistics, name: 'statistics' },
      { path: 'conversions', component: Conversions, name: 'conversion' },
      { path: 'payments', component: Payments },
      { path: 'postbacks', component: GlobalPostbacks },
      { path: 'profile', component: Profile },
      { path: 'redirect-domains', component: RedirectDomains },
      { path: 'api-integration', component: ApiIntegration },
      { path: 'referrals', component: ComingSoon },

      { path: 'apps-campaigns', component: ComingSoon },
      { path: 'apps-campaigns/new', component: ComingSoon },
      {
        path: 'apps-campaigns/:id',
        component: ComingSoon,
        name: 'app-campaign',
      },
      { path: 'apps', component: ComingSoon },
      { path: 'apps-statistics', component: ComingSoon },
      { path: 'apps-transactions', component: ComingSoon },
      { path: 'push', component: ComingSoon },
      { path: 'push/new', component: ComingSoon },
      { path: 'push/:id', component: ComingSoon, name: 'push' },
      { path: 'sharing', component: ComingSoon },

      { path: 'logs', component: Logs },
      { path: 'tickets', component: SupportTickets },
      { path: 'tickets/:id', component: TicketMessage, name: 'ticket' },

      { path: 'dashboard', component: Dashboard, name: 'dashboard' },
    ],
  },
]

export default routes
