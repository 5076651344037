import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_q_input, {
        outlined: "",
        dense: "",
        label: _ctx.$t('cur_password'),
        "stack-label": "",
        type: _ctx.hidePassword ? 'password' : 'text',
        modelValue: _ctx.password,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
        error: !!_ctx.errors?.message,
        "error-message": _ctx.errors?.message,
        class: "q-mb-sm"
      }, {
        append: _withCtx(() => [
          _createVNode(_component_q_icon, {
            name: _ctx.hidePassword ? _ctx.heroOutline24EyeSlash : _ctx.heroOutline24Eye,
            class: "cursor-pointer",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hidePassword = !_ctx.hidePassword)),
            size: "14px"
          }, null, 8, ["name"])
        ]),
        _: 1
      }, 8, ["label", "type", "modelValue", "error", "error-message"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_q_input, {
        outlined: "",
        dense: "",
        label: _ctx.$t('new_password'),
        "stack-label": "",
        type: _ctx.hideNewPassword ? 'password' : 'text',
        modelValue: _ctx.newPassword,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newPassword) = $event)),
        error: !!_ctx.errors?.message,
        "error-message": _ctx.errors?.message
      }, {
        append: _withCtx(() => [
          _createVNode(_component_q_icon, {
            name: _ctx.hideNewPassword ? _ctx.heroOutline24EyeSlash : _ctx.heroOutline24Eye,
            class: "cursor-pointer",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.hideNewPassword = !_ctx.hideNewPassword)),
            size: "14px"
          }, null, 8, ["name"])
        ]),
        _: 1
      }, 8, ["label", "type", "modelValue", "error", "error-message"])
    ]),
    _createVNode(_component_q_btn, {
      "no-caps": "",
      label: _ctx.$t('save'),
      type: "submit",
      color: "primary",
      unelevated: "",
      class: "q-mt-md text-weight-bold q-btn__main-size full-width",
      onClick: _ctx.changePassword
    }, null, 8, ["label", "onClick"])
  ]))
}