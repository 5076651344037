import { formatValue, isDefined, RowValue } from '@/utils'
import i18n from '@/locales/i18n'

export function getGeneralColumns() {
  return [
    {
      name: 'groupByField',
      label: i18n.t('date'),
      field: 'groupByField',
      align: 'left',
      sortable: true,
    },
    {
      name: 'clicks',
      label: i18n.t('clicks'),
      field: 'clicks',
      align: 'left',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'uniqueClicks',
      label: i18n.t('uniqs'),
      field: 'uniqueClicks',
      align: 'left',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'epc',
      label: 'EPC',
      field: 'epc',
      align: 'left',
      format: (val: RowValue) => formatValue(val),
    },
    {
      name: 'cr',
      label: 'CR, %',
      field: 'cr',
      align: 'left',
      format: (val: RowValue) => formatValue(val),
    },
    {
      name: 'approvedLeads',
      label: i18n.t('approved'),
      field: 'approvedLeads',
      align: 'left',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'holdLeads',
      label: i18n.t('hold'),
      field: 'holdLeads',
      align: 'left',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'rejectedLeads',
      label: i18n.t('rejects'),
      field: 'rejectedLeads',
      align: 'left',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'trashLeads',
      label: i18n.t('trash'),
      field: 'trashLeads',
      align: 'left',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'approvedSum',
      label: i18n.t('approved'),
      field: 'approvedSum',
      align: 'left',
      format: (val: RowValue) => formatValue(val),
    },
    {
      name: 'holdSum',
      label: i18n.t('hold'),
      field: 'holdSum',
      align: 'left',
      format: (val: RowValue) => formatValue(val),
    },
    {
      name: 'rejectedSum',
      label: i18n.t('rejects'),
      field: 'rejectedSum',
      align: 'left',
      format: (val: RowValue) => formatValue(val),
    },
    {
      name: 'revShareRevenueSum',
      label: i18n.t('income'),
      field: 'revShareRevenueSum',
      align: 'left',
      format: (val: RowValue) => formatValue(val),
    },
  ]
}

export function getGamblingColumns() {
  return [
    {
      name: 'groupByField',
      label: i18n.t('date'),
      field: 'groupByField',
      align: 'left',
      sortable: true,
    },
    {
      name: 'clicks',
      label: i18n.t('clicks'),
      field: 'clicks',
      align: 'right',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'uniqueClicks',
      label: i18n.t('uniqs'),
      field: 'uniqueClicks',
      align: 'right',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'registrationCount',
      label: 'Количество, шт',
      field: 'registrationCount',
      align: 'right',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'firstDepositCount',
      label: 'Количество, шт',
      field: 'firstDepositCount',
      align: 'right',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'firstDepositSum',
      label: i18n.t('sum'),
      field: 'firstDepositSum',
      align: 'right',
      format: (val: RowValue) => formatValue(val),
    },
    // {
    //   name: 'depositCount',
    //   label: 'Количество, шт.',
    //   fullLabel: 'Количество повторных депозитов',
    //   field: 'depositCount',
    //   align: 'right',
    //   format: (val: RowValue) => isDefined(val),
    // },
    // {
    //   name: 'depositSum',
    //   label: 'Сумма, $',
    //   fullLabel: 'Сумма повторных депозитов',
    //   field: 'depositSum',
    //   align: 'right',
    //   format: (val: RowValue) => formatValue(val),
    // },
    // {
    //   name: 'uniqueDepositCount',
    //   label: 'Уникальный, шт.',
    //   fullLabel: 'Уникальные первые повторные депозиты',
    //   field: 'uniqueDepositCount',
    //   align: 'right',
    //   format: (val: RowValue) => isDefined(val),
    // },
    {
      name: 'crReg',
      label: 'Host/Reg',
      field: 'crReg',
      align: 'right',
      format: (val: RowValue) => formatValue(val),
    },
    {
      name: 'crFtd',
      label: 'Host/Dep',
      field: 'crFtd',
      align: 'right',
      format: (val: RowValue) => formatValue(val),
    },
    {
      name: 'crFtdReg',
      label: 'Reg/Dep',
      field: 'crFtdReg',
      align: 'right',
      format: (val: RowValue) => formatValue(val),
    },
    {
      name: 'approvedSum',
      label: i18n.t('approved'),
      field: 'approvedSum',
      align: 'right',
      format: (val: RowValue) => formatValue(val),
    },
    {
      name: 'holdSum',
      label: i18n.t('hold'),
      field: 'holdSum',
      align: 'right',
      format: (val: RowValue) => formatValue(val),
    },
    {
      name: 'rejectedSum',
      label: i18n.t('rejects'),
      field: 'rejectedSum',
      align: 'right',
      format: (val: RowValue) => formatValue(val),
    },
    {
      name: 'revShareRevenueSum',
      label: i18n.t('income'),
      field: 'revShareRevenueSum',
      align: 'right',
      format: (val: RowValue) => formatValue(val),
    },
  ]
}

export function getCryptoColumns() {
  return [
    {
      name: 'groupByField',
      label: i18n.t('date'),
      field: 'groupByField',
      align: 'left',
      sortable: true,
    },
    {
      name: 'clicks',
      label: i18n.t('clicks'),
      field: 'clicks',
      align: 'left',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'uniqueClicks',
      label: i18n.t('uniqs'),
      field: 'uniqueClicks',
      align: 'left',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'epc',
      label: 'EPC',
      field: 'epc',
      align: 'left',
      format: (val: RowValue) => formatValue(val),
    },
    {
      name: 'cr',
      label: 'CR, %',
      field: 'cr',
      align: 'left',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'approvedRegs',
      label: i18n.t('approved'),
      field: 'approvedRegs',
      align: 'left',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'holdRegs',
      label: i18n.t('hold'),
      field: 'holdRegs',
      align: 'left',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'rejectedRegs',
      label: i18n.t('rejects'),
      field: 'rejectedRegs',
      align: 'left',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'trashRegs',
      label: i18n.t('trash'),
      field: 'trashRegs',
      align: 'left',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'approvedDeps',
      label: i18n.t('approved'),
      field: 'approvedDeps',
      align: 'left',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'holdDeps',
      label: i18n.t('hold'),
      field: 'holdDeps',
      align: 'left',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'rejectedDeps',
      label: i18n.t('rejects'),
      field: 'rejectedDeps',
      align: 'left',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'approvedSum',
      label: i18n.t('approved'),
      field: 'approvedSum',
      align: 'left',
      format: (val: RowValue) => formatValue(val),
    },
    {
      name: 'holdSum',
      label: i18n.t('hold'),
      field: 'holdSum',
      align: 'left',
      format: (val: RowValue) => formatValue(val),
    },
    {
      name: 'rejectedSum',
      label: i18n.t('rejects'),
      field: 'rejectedSum',
      align: 'left',
      format: (val: RowValue) => formatValue(val),
    },
  ]
}

export function getCPIColumns() {
  return [
    {
      name: 'groupByField',
      label: i18n.t('date'),
      field: 'groupByField',
      align: 'left',
      sortable: true,
    },
    {
      name: 'clicks',
      label: i18n.t('clicks'),
      field: 'clicks',
      align: 'left',
    },
    {
      name: 'uniqueClicks',
      label: i18n.t('uniqs'),
      field: 'uniqueClicks',
      align: 'left',
    },
    { name: 'epc', label: 'EPC', field: 'epc', align: 'left' },
    { name: 'cr', label: 'CR, %', field: 'cr', align: 'left' },
    {
      name: 'approvedInstalls',
      label: 'Принятые',
      field: 'approvedInstalls',
      align: 'left',
    },
    {
      name: 'rejectedInstalls',
      label: 'Отмененные',
      field: 'rejectedInstalls',
      align: 'left',
    },
    {
      name: 'sumInstalls',
      label: i18n.t('sum'),
      field: 'sumInstalls',
      align: 'left',
    },
    {
      name: 'trashInstalls',
      label: i18n.t('trash'),
      field: 'trashInstalls',
      align: 'left',
    },
    {
      name: 'approvedSum',
      label: i18n.t('approved'),
      field: 'approvedSum',
      align: 'left',
    },
    {
      name: 'rejectedSum',
      label: i18n.t('rejects'),
      field: 'rejectedSum',
      align: 'left',
    },
  ]
}
