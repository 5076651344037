import axios from 'axios'
import { ReadOptions, FiltersType } from '@/utils/request'
import { Row } from '@/utils/request'
import { formatValue } from '@/utils'

export async function getData(options: ReadOptions, url: string) {
  const params: ReadOptions = { ...options, filters: { ...options.filters } }
  for (const [key, value] of Object.entries(params.filters)) {
    if (value === null || value === undefined) {
      delete params.filters[key as keyof FiltersType]
    }
  }
  if (options.filters.dateFrom) {
    params.filters.dateFrom = options.filters.dateFrom
  }
  if (options.filters.dateTo) {
    params.filters.dateTo = options.filters.dateTo
  }
  if (options.filters.groupBy) {
    params.groupBy = [options.filters.groupBy]
  }
  if (options.orderBy) {
    params.orderBy = options.orderBy
  }
  if (options.limit) {
    params.limit = options.limit
  }
  if (options.offset) {
    params.offset = options.offset
  }

  return (await axios.get(url, { params })).data
}

export function getTotalValues(
  rows: Row[],
  field: string,
  operation: 'sum' | 'avg',
): number | string {
  let total = 0
  if (operation === 'sum') {
    for (const row of rows) {
      const val = Number(row[field])
      if (val) total += val
    }
  } else {
    let length = 0
    for (const row of rows) {
      const val = Number(row[field])
      if (val) {
        total += val
        length++
      }
    }
    if (length) total /= length
  }
  return formatValue(total) as number
}
