
import { defineComponent } from 'vue'
import i18n from '@/locales/i18n'
import axios from 'axios'

export default defineComponent({
  name: 'SignUpBackground',
  async beforeMount() {
    let queryLang = this.$router.currentRoute.value?.query?.lang as any
    if (queryLang) {
      if (
        !['RU', 'UA', 'EN', 'PL', 'DE', 'FR', 'ES'].includes(
          queryLang.toUpperCase(),
        )
      ) {
        queryLang = 'EN'
      }
      await this.changeLang(queryLang.toLowerCase())
      return
    }
    if (!localStorage.getItem('lang_gmbt')) {
      const lang = (await axios.post('/api/auth/ip-lang')).data
      await this.changeLang(lang.toLowerCase())
    }
    this.langLabel =
      localStorage.getItem('lang_gmbt')?.toUpperCase() || this.langLabel
  },
  data: function() {
    return {
      langLabel: 'RU',
    }
  },
  methods: {
    async changeLang(lang: string) {
      this.langLabel = lang.toUpperCase()
      i18n.setLocale(lang.toLowerCase())
      await axios.post('/api/auth/lang', { lang })
      localStorage.setItem('lang_gmbt', lang)
    },
  },
})
