import {
  CampaignData,
  Offer,
  Promo,
  RedirectDomain,
  TrafficSource,
  Geo,
} from '@/store/types'
import axios from 'axios'

export interface DefaultOptions {
  promos: Promo[]
  trafficSources: TrafficSource[]
  redirectDomains: RedirectDomain[]
  offers: Offer[]
  geos: Geo[]
}

export async function setOptionsByOfferId(
  campaign: CampaignData,
  options: DefaultOptions,
) {
  options.offers = (
    await axios.get('/api/offers/list/', {
      params: { ids: [campaign.offerId] },
    })
  ).data

  const promos = (
    await axios.get('/api/promos', { params: { offerId: campaign.offerId } })
  ).data
  options.promos = promos.map((promo: Promo) => ({
    ...promo,
    isActive: campaign.targetPromoIds.includes(promo.id),
  }))

  options.trafficSources = (
    await axios.get('/api/traffic-sources', {
      params: { offerId: campaign.offerId },
    })
  ).data

  options.geos = (
    await axios.get('/api/geos', { params: { offerId: campaign.offerId } })
  ).data

  return options
}

export async function getInitialOptions(
  campaign: CampaignData,
): Promise<DefaultOptions> {
  const defaultOptions: DefaultOptions = {
    promos: [],
    trafficSources: [],
    redirectDomains: [],
    offers: [],
    geos: [],
  }

  defaultOptions.redirectDomains = (
    await axios.get('/api/redirect-domains')
  ).data

  if (campaign.offerId) {
    await setOptionsByOfferId(campaign, defaultOptions)
  }

  return defaultOptions
}
