import { formatDate } from '@/utils'
import i18n from '@/locales/i18n'

export default function getColumns() {
  return [
    {
      name: 'createdAt',
      label: i18n.t('date'),
      field: 'createdAt',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'title',
      label: i18n.t('theme'),
      field: 'title',
      align: 'left',
      classes: 'text-weight-bold',
    },
    {
      name: 'status',
      label: i18n.t('status'),
      field: 'status',
      align: 'left',
    },
    {
      name: 'actions',
      label: i18n.t('actions'),
      field: 'actions',
      align: 'left',
      headerStyle: 'width: 180px',
    },
  ]
}
