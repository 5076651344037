import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_MobileMenu = _resolveComponent("MobileMenu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_q_page_container, { class: "page-container" }, {
      default: _withCtx(() => [
        _createVNode(_component_Header, {
          onOpenDrawer: _ctx.openDrawer,
          onToggleMenu: _ctx.toggleMenu,
          onChangeLangForce: _ctx.changeLang
        }, null, 8, ["onOpenDrawer", "onToggleMenu", "onChangeLangForce"]),
        (_ctx.mobileMenuActive)
          ? (_openBlock(), _createBlock(_component_MobileMenu, {
              key: 0,
              onToggleMenu: _ctx.toggleMenu
            }, null, 8, ["onToggleMenu"]))
          : _createCommentVNode("", true),
        _createVNode(_component_router_view)
      ]),
      _: 1
    }),
    _createVNode(_component_Footer)
  ], 64))
}