import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header__payment flex" }
const _hoisted_2 = { class: "q-mr-xs" }
const _hoisted_3 = { class: "text-weight-bold" }
const _hoisted_4 = { class: "q-mr-xs" }
const _hoisted_5 = { class: "text-weight-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_btn, {
      to: "/payments",
      "no-caps": "",
      outline: "",
      color: "primary",
      class: "header__payment-btn q-mr-sm"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('balance')) + ":", 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(`${_ctx.getFormattedValue(
            _ctx.affiliate.baseApprovedAmount ? _ctx.affiliate.baseApprovedAmount : 0,
          )} ${_ctx.currencies[_ctx.affiliate.baseCurrency ? _ctx.affiliate.baseCurrency : 0]}`), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_q_btn, {
      to: "/payments",
      "no-caps": "",
      outline: "",
      color: "gray",
      class: "header__payment-btn"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('hold')) + ":", 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(`${_ctx.getFormattedValue(
            _ctx.affiliate.baseHoldAmount ? _ctx.affiliate.baseHoldAmount : 0,
          )} ${_ctx.currencies[_ctx.affiliate.baseCurrency ? _ctx.affiliate.baseCurrency : 0]}`), 1)
      ]),
      _: 1
    })
  ]))
}