
import { defineComponent } from 'vue'
import {
  FiltersType,
  getTableProperties,
  request,
  Table,
  TableRequestProps,
} from '@/utils/request'
import { getTransactionsColumns } from '@/pages/payments/config/columns'
import { Currency } from '@/enums/Currency'
import { RewardType } from '@/enums/RewardType'
import { TransactionReason } from '@/enums/TransactionReason'
import { formatDate, formatValue } from '@/utils'
import Filters from '@/components/filters/Filters.vue'
import { exportToCsv } from '@/utils/export'
import { getIncludeOptions } from '@/utils/filters'

export default defineComponent({
  name: 'Transactions',
  components: { Filters },
  async created() {
    await this.onRequest({ pagination: this.pagination })
  },
  data: function() {
    return {
      filters: getIncludeOptions(),
      ...getTableProperties('datetime'),
      columns: getTransactionsColumns(),
      currencies: Currency,
      rewardTypes: RewardType,
      transactionReasons: TransactionReason,
    }
  },
  watch: {
    async filters() {
      await this.onRequest({ pagination: this.pagination })
    },
  },
  methods: {
    async onRequest(props: TableRequestProps) {
      await request(props, this as Table, '/api/transactions/')
    },
    getFormattedValue(amount: number | string) {
      return formatValue(amount, 3)
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters } as any
    },
    resetFilters() {
      this.pagination.page = 1
      this.filters = getIncludeOptions()
    },
    exportCsv() {
      exportToCsv(this.rows, this.columns, undefined, undefined, 'transactions')
    },
    format(val: string) {
      return formatDate(val)
    },
  },
})
