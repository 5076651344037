
import { defineComponent } from 'vue'
import Filters from '@/components/filters/Filters.vue'
import {
  FiltersType,
  getDefaultFilters,
  getTableProperties,
} from '@/utils/request'
import getColumns from './getColumns'
import { request, Table, TableRequestProps } from '@/utils/request'
import { formatDate } from '@/utils'
export default defineComponent({
  name: 'PostbackLogs',
  components: { Filters },
  mounted() {
    this.onRequest({ pagination: this.pagination })
  },
  watch: {
    async filters() {
      await this.onRequest({ pagination: this.pagination })
    },
  },
  data: function() {
    return {
      ...getTableProperties('createdAt'),
      filters: getDefaultFilters(),
      columns: getColumns(),
    }
  },
  methods: {
    async onRequest(
      props: TableRequestProps | Omit<TableRequestProps, 'pagination'>,
    ) {
      const options = { pagination: this.pagination, ...props }
      await request(options, this as Table, '/api/logs/affiliate')
    },
    onChangeFilters(field: string, value?: string) {
      if (value === undefined) {
        return
      }
      this.filters = {
        ...this.filters,
        [field]: value,
      }
    },
    changeAll(filters: FiltersType) {
      this.filters = { ...filters } as any
    },
    resetFilters() {
      this.pagination.page = 1
      this.filters = getDefaultFilters()
    },
    format(val: string) {
      return formatDate(val)
    },
  },
})
