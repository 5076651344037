
import axios from 'axios'
import { defineComponent } from 'vue'
import { TdsRule } from '@/store/types'
import {
  heroOutline24Trash,
  heroOutline24Plus,
} from 'quasar-extras-svg-icons/hero-icons-v2'

export default defineComponent({
  name: 'Naming',
  setup() {
    return {
      heroOutline24Trash,
      heroOutline24Plus,
    }
  },
  async created() {
    this.tdsRules = (await axios.get('/api/profile/rules/')).data
    if (!this.tdsRules?.length) {
      this.addRule()
    }
    this.loading = false
  },
  data: function() {
    return {
      loading: true,
      errors: {},
      tdsRules: [],
    }
  },
  methods: {
    save() {
      axios
        .put('/api/profile/rules', { tdsRules: this.tdsRules })
        .then(() => {
          this.resetErrors()
        })
        .catch(({ response }) => {
          this.errors = response?.data.errors
        })
    },
    addRule() {
      this.tdsRules.push({} as never)
    },
    deleteRule(ruleIndex: number) {
      const rules = this.tdsRules.filter(
        (rule: TdsRule, index: number) => index !== ruleIndex,
      )
      this.tdsRules = [...rules]
    },
    getFullNaming(rule: TdsRule, index: number) {
      if (rule.naming) {
        return rule.naming
      }
      const affiliateId = JSON.parse(localStorage.user).data?.id
      rule.naming =
        affiliateId +
        '-' +
        Math.random()
          .toString(16)
          .substr(2, 8) +
        '_sub1_sub2_sub3_sub4_sub5_sub6_sub7'
      this.updateField(rule, index)
    },
    updateField(rule: TdsRule, index: number) {
      this.tdsRules[index] = rule as never
    },
    resetErrors() {
      this.errors = {}
    },
  },
})
