import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_btn, {
    class: "language-btn q-px-lg self-stretch",
    "no-caps": "",
    flat: "",
    label: _ctx.langLabel
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_icon, {
        class: "q-ml-sm",
        name: _ctx.heroOutline24ChevronDown,
        size: "12px"
      }, null, 8, ["name"]),
      _createVNode(_component_q_menu, { class: "language-btn-menu-wrapper" }, {
        default: _withCtx(() => [
          _createVNode(_component_q_list, { class: "language-btn-menu" }, {
            default: _withCtx(() => [
              _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                clickable: "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeLang('ua')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      _createTextVNode("UA")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })), [
                [_directive_close_popup]
              ]),
              _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                clickable: "",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeLang('en')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      _createTextVNode("EN")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })), [
                [_directive_close_popup]
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["label"]))
}