
import { defineComponent } from 'vue'
import axios from 'axios'
import { enumToArray, getTimezones, payoutCurrencies } from '@/utils'
import { Messengers } from '@/enums/Messengers'
import { lookupGeos } from '@/utils/lookups'
import { Currency } from '@/enums/Currency'
import { Timezone } from '@/enums/Timezone'

export default defineComponent({
  name: 'ProfileEditDialog',
  props: ['open', 'close', 'refresh'],
  computed: {
    showDialog(): boolean {
      return this.open
    },
  },
  async created(): Promise<void> {
    this.affiliate = (await axios.get('/api/profile')).data
    this.geos = (await axios.get('/api/geos')).data
    this.ready = true
  },
  data() {
    return {
      affiliate: {
        baseCurrency: 0,
        customTimezone: Timezone.Kyiv,
        credentials: {},
        contacts: { telegram: null },
        image: '',
      },
      geos: [],
      ready: false,
      loading: false,
      errors: {},
      messengers: enumToArray(Messengers),
      selectedMessenger: Messengers.Telegram,
      currencies: payoutCurrencies,
      timezones: getTimezones(),
    }
  },
  methods: {
    onSubmit: async function() {
      const user = JSON.parse(localStorage.getItem('user') as string) || {}
      const updatedUser = {
        ...user,
        data: {
          ...user.data,
          baseCurrency: this.affiliate.baseCurrency || Currency.USD,
          customTimezone: this.affiliate.customTimezone,
        },
      }
      localStorage.setItem('user', JSON.stringify(updatedUser))
      const timezone = getTimezones().find(
        (tz: any) => tz.id === this.affiliate.customTimezone,
      )?.value
      this.$storage.setStorageSync('affiliateTimezone', timezone)
      this.loading = true
      try {
        await axios.put('/api/profile', this.affiliate)
        this.errors = {}
        this.$emit('refresh')
        this.$emit('close')
      } catch (error) {
        this.errors = (error as any).response?.data.errors
      }
      this.loading = false
    },
    async getGeos(value: string, update: Function) {
      await lookupGeos(this, value, update)
    },
  },
})
