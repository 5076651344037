export enum TransactionReason {
  LeadHold = 1,
  LeadApprove = 2,
  LeadReject = 3,
  ReferralLeadHold = 4,
  ReferralLeadApprove = 5,
  ReferralLeadReject = 6,
  RefBonusPaymentHold,
  RefBonusPaymentApprove,
  Other,
}
