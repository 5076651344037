
import { defineComponent } from 'vue'
import GeneralInfo from './tabs/GeneralInfo.vue'
import Passwords from './tabs/Passwords.vue'
import PaymentSystemAccounts from '@/pages/payments/tabs/PaymentSystemAccounts.vue'
import Naming from '@/pages/profile/tabs/Naming.vue'
import AddPaymentSystemDialog from '@/components/dialogs/AddPaymentSystemDialog.vue'
import { heroOutline24Plus } from 'quasar-extras-svg-icons/hero-icons-v2'

export default defineComponent({
  name: 'Profile',
  components: {
    Naming,
    GeneralInfo,
    Passwords,
    PaymentSystemAccounts,
    AddPaymentSystemDialog,
  },
  setup() {
    return {
      heroOutline24Plus,
    }
  },
  data: function() {
    return {
      profileTab: 'general',
      showAddPaymentSystemDialog: false,
    }
  },
})
