
import { defineComponent } from 'vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import SignUpContentHeader from '@/components/sign-up/SignUpContentHeader.vue'
import SignUpHeader from '@/components/sign-up/SignUpHeader.vue'

export default defineComponent({
  name: 'PasswordReset',
  components: { SignUpHeader, SignUpContentHeader },
  computed: mapState('authModule', ['passwordResetError']),
  created: function() {
    this.resetErrors()
  },
  data: function() {
    return {
      email: null,
      password: null,
      telegram: null,
      loading: false,
      emailSent: false,
    }
  },
  methods: {
    ...mapActions('authModule', ['startPasswordReset']),
    ...mapMutations('authModule', ['resetErrors']),
    async submit() {
      this.loading = true
      try {
        await this.startPasswordReset({
          email: this.email,
        })
        this.emailSent = true
      } finally {
        this.loading = false
      }
    },
  },
})
