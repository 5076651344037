
import { defineComponent } from 'vue'
import axios from 'axios'
import CampaignEditDialog from '@/components/dialogs/CampaignEditDialog.vue'
import { Promo, Reward, Offer } from '@/store/types'
import { MarkupType } from '@/enums/MarkupType'
import { Vertical } from '@/enums/Vertical'
import { Currency } from '@/enums/Currency'
import { RewardType } from '@/enums/RewardType'
import { AffiliateAccessStatus } from '@/enums/AffiliateAccessStatus'
import { downloadPromo } from '@/utils'
import i18n from '@/locales/i18n'
import OfferRates from '@/pages/offers/components/OfferRates.vue'
import OfferLandings from '@/pages/offers/components/OfferLandings.vue'

export default defineComponent({
  name: 'Offer',
  components: { OfferLandings, OfferRates, CampaignEditDialog },
  computed: {
    promos(): Promo[] {
      return this.offer.promos.filter((p: Promo) => p.showForOffers)
    },
    ratesData(): any[] {
      return [
        ...this.offer.commonRewards,
        ...this.offer.affiliateRewards,
      ].filter((r: Reward) => !r.withoutValue)
    },
  },
  async created() {
    await this.getOffer()
    this.loading = false
  },
  data() {
    return {
      storageUrl: process.env.CDN_STORAGE_URL,
      offer: {
        id: null,
        offersAccess: null,
        promos: [],
        commonRewards: [],
        affiliateRewards: [],
      },
      // offer: Offer,
      loading: true,
      markupTypes: MarkupType,
      currencies: Currency,
      rewardTypes: RewardType,
      verticals: Vertical,
      statuses: AffiliateAccessStatus,
      showCampaignEditDialog: false,
    }
  },
  methods: {
    async getAccess() {
      await axios.get('/api/offers/access/' + this.offer.id)
      await this.getOffer()
    },
    async getOffer() {
      this.offer = (
        await axios.get(
          '/api/offers/offer/' + this.$route.params.id + '?' + i18n.getLocale(),
        )
      ).data
    },
    hasAccess(status: AffiliateAccessStatus) {
      return status === AffiliateAccessStatus.Opened
    },
    getGeos(reward: Reward) {
      return reward.geos.map(geo => geo.isoCode.toUpperCase()).join(', ')
    },
    getGeoNames(reward: Reward) {
      return reward.geos.map(geo => geo.name).join(', ')
    },
    hasOfferAccess() {
      return !(
        !this.offer.offersAccess ||
        !((this.offer.offersAccess as unknown) as {
          status: AffiliateAccessStatus
        })?.status
      )
    },
    landingRedirect(landingUrl: string) {
      if (!this.hasOfferAccess()) {
        return
      }
      window.open(landingUrl)
    },
    async download(id: string) {
      if (!this.hasOfferAccess()) {
        return
      }
      await downloadPromo(id)
    },
  },
})
