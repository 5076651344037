import { isDefined, RowValue, formatDate } from '@/utils'
import i18n from '@/locales/i18n'

export function getRedirectEventsColumns() {
  return [
    {
      name: 'createdAt',
      label: i18n.t('datetime'),
      field: 'createdAt',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'id',
      label: i18n.t('click_id'),
      field: 'id',
      align: 'left',
    },
    {
      name: 'ip',
      label: 'IP',
      field: 'ip',
      align: 'left',
    },
    {
      name: 'geoName',
      label: i18n.t('geo'),
      field: 'geoName',
      align: 'left',
    },
    {
      name: 'offerName',
      label: i18n.t('offer'),
      field: 'offerName',
      align: 'left',
      sortable: true,
    },
    {
      name: 'campaignName',
      label: i18n.t('campaign'),
      field: 'campaignName',
      align: 'left',
      sortable: true,
    },
    {
      name: 'os',
      label: 'OS',
      field: 'os',
      align: 'left',
      format: (val: any) => isDefined(val?.trim() || null),
    },
    {
      name: 'device',
      label: i18n.t('device'),
      field: 'device',
      align: 'left',
      format: (val: any) => isDefined(val?.trim() || null),
    },
    {
      name: 'browser',
      label: i18n.t('browser'),
      field: 'browser',
      align: 'left',
      format: (val: any) => isDefined(val?.trim() || null),
    },
    {
      name: 'campaignUrl',
      label: i18n.t('campaign_url'),
      field: 'campaignUrl',
      align: 'left',
    },
    // {
    //   name: 'targetUrl',
    //   label: i18n.t('offer_redirect'),
    //   field: 'targetUrl',
    //   align: 'left',
    // },
    // {
    //   name: 'tdsRedirectEventId',
    //   label: i18n.t('tds_click_id'),
    //   field: 'tdsRedirectEventId',
    //   align: 'left',
    // },
    // {
    //   name: 'request',
    //   label: i18n.t('request_to_tracker'),
    //   field: 'request',
    //   align: 'left',
    //   format: (val: RowValue) => JSON.stringify(val),
    // },
  ]
}

export function getAffiliatePostbackColumns() {
  return [
    {
      name: 'createdAt',
      label: i18n.t('datetime'),
      field: 'createdAt',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    { name: 'url', label: i18n.t('link'), field: 'url', align: 'left' },
    {
      name: 'status',
      label: i18n.t('status'),
      field: 'status',
      align: 'left',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'eventType',
      label: i18n.t('event'),
      field: 'eventType',
      align: 'left',
      sortable: true,
    },
    {
      name: 'campaignName',
      label: i18n.t('campaign'),
      field: 'campaignName',
      align: 'left',
      sortable: true,
    },
    {
      name: 'offerName',
      label: i18n.t('offer'),
      field: 'offerName',
      align: 'left',
      sortable: true,
    },
  ]
}

export function getClicksChartColumns() {
  return [
    {
      name: 'groupByField',
      label: i18n.t('date'),
      fullLabel: i18n.t('date'),
      field: 'groupByField',
      align: 'left',
      sortable: true,
    },
    {
      name: 'clicks',
      label: i18n.t('clicks'),
      fullLabel: i18n.t('clicks'),
      field: 'clicks',
      align: 'left',
      sortable: true,
      format: (val: RowValue) => isDefined(val),
    },
  ]
}
