import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between section-container background-gray q-py-lg" }
const _hoisted_2 = { class: "page-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_RedirectEvents = _resolveComponent("RedirectEvents")!
  const _component_q_tab_panel = _resolveComponent("q-tab-panel")!
  const _component_AffiliateLogs = _resolveComponent("AffiliateLogs")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-mb-lg" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('logs')), 1),
        _createVNode(_component_q_btn, {
          "no-caps": "",
          "icon-right": _ctx.heroOutline24ArrowDownTray,
          unelevated: "",
          class: "q-btn__main-size text-weight-bold",
          label: "Export",
          color: "primary",
          outline: "",
          onClick: _ctx.exportChartData
        }, null, 8, ["icon-right", "onClick"])
      ]),
      _createVNode(_component_q_tabs, {
        modelValue: _ctx.tab,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
        "inline-label": "",
        breakpoint: 0,
        align: "justify",
        class: "section-container q-pt-lg",
        "no-caps": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_tab, {
            name: "clicks",
            label: _ctx.$t('clicks')
          }, null, 8, ["label"]),
          _createVNode(_component_q_tab, {
            name: "affiliate-logs",
            label: _ctx.$t('sended_postbacks')
          }, null, 8, ["label"])
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_q_tab_panels, {
        modelValue: _ctx.tab,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tab) = $event)),
        animated: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_tab_panel, {
            name: "clicks",
            class: "q-pa-none"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_RedirectEvents)
            ]),
            _: 1
          }),
          _createVNode(_component_q_tab_panel, {
            name: "affiliate-logs",
            class: "q-pa-none"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AffiliateLogs)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}