
import { defineComponent } from 'vue'
import Payouts from './tabs/Payouts.vue'
import Transactions from './tabs/Transactions.vue'
import PaymentSystemAccounts from './tabs/PaymentSystemAccounts.vue'
import RevShare from './tabs/RevShare.vue'
import CreatePayoutDialog from '@/components/dialogs/CreatePayoutDialog.vue'
import AddPaymentSystemDialog from '@/components/dialogs/AddPaymentSystemDialog.vue'

export default defineComponent({
  name: 'Payments',
  components: {
    Payouts,
    Transactions,
    PaymentSystemAccounts,
    RevShare,
    CreatePayoutDialog,
    AddPaymentSystemDialog,
  },
  data: function() {
    return {
      paymentsTab: 'payouts',
      showCreatePayoutDialog: false,
      isPayoutDisabled: true,
      showAddPaymentSystemDialog: false,
      payoutsComponentKey: 0,
    }
  },
  methods: {
    refreshPayouts() {
      this.payoutsComponentKey++
    },
    onPayoutDisabledStatusChanged(isPayoutDisabled: boolean) {
      this.isPayoutDisabled = isPayoutDisabled
    },
  },
  computed: {},
})
