<template>
  <div class="flex cursor-pointer relative-position">
    <div
      :style="Number(id) === 1 ? 'height: 50px !important;' : ''"
      class="row items-center cursor-pointer"
      type="text"
      :id="'daterange' + id"
    >
      <i class="fas fa-calendar q-mr-sm" style="width: 16px; height: 15px;"></i
      >&nbsp; <span></span>
      <div class="q-ml-sm q-mr-md q-icon">
        <svg viewBox="0 0 24 24">
          <path
            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            style="fill: none; stroke-width: 1.5; stroke: currentcolor; stroke-linecap: round; stroke-linejoin: round;"
          ></path>
        </svg>
      </div>
      <!--      <div class="daterange-label">{{ $t('date') }}</div>-->
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import {
  endOfMonth,
  endOfWeek,
  isMonday,
  previousMonday,
  startOfMonth,
  startOfYear,
  sub,
} from 'date-fns'
import moment from 'moment'
import i18n from '@/locales/i18n'
import { heroOutline24ChevronDown } from 'quasar-extras-svg-icons/hero-icons-v2'

export default defineComponent({
  name: 'DatePicker',
  props: ['from', 'to', 'changeDate', 'id', 'label'],
  setup() {
    return {
      heroOutline24ChevronDown,
    }
  },
  mounted() {
    const ref = this
    window.$(
      (function() {
        function cb(start, end) {
          ref.dateValue = {
            startDate: start.format('YYYY/MM/DD'),
            endDate: end.format('YYYY/MM/DD'),
          }
          window
            .$(`#daterange${ref.id} span`)
            .html(
              start.format('DD MMMM YYYY') + ' - ' + end.format('DD MMMM YYYY'),
            )
        }

        window.$('#daterange' + ref.id).daterangepicker(
          {
            dark: true,
            darkMode: true,
            showDropdowns: true,
            minYear: 2021,
            maxYear: 2040,
            showISOWeekNumbers: true,
            locale: {
              format: 'DD MMMM YYYY',
              separator: ' — ',
              firstDay: 1,
              applyLabel: i18n.t('apply'),
              cancelLabel: i18n.t('cancel'),
              daysOfWeek: [
                i18n.t('sunday'),
                i18n.t('monday'),
                i18n.t('tuesday'),
                i18n.t('wednesday'),
                i18n.t('thursday'),
                i18n.t('friday'),
                i18n.t('saturday'),
              ],
              monthNames: [
                i18n.t('january'),
                i18n.t('february'),
                i18n.t('march'),
                i18n.t('april'),
                i18n.t('may'),
                i18n.t('june'),
                i18n.t('july'),
                i18n.t('august'),
                i18n.t('september'),
                i18n.t('october'),
                i18n.t('november'),
                i18n.t('december'),
              ],
            },
            showCustomRangeLabel: false,
            linkedCalendars: false,
            alwaysShowCalendars: true,
            startDate: moment(ref.dateValue.startDate).format('DD MMMM YYYY'),
            endDate: moment(ref.dateValue.endDate).format('DD MMMM YYYY'),
            drops: 'auto',
            applyButtonClasses:
              'daterange-apply-button bg-primary text-white q-btn q-btn__main-size q-btn--no-uppercase q-btn--unelevated q-btn--rectangle',
            cancelButtonClasses:
              'daterange-cancel-button q-btn  q-btn__main-size text-white q-btn--no-uppercase q-btn--unelevated q-btn--rectangle',
            ranges: {
              [i18n.t('today')]: [new Date(), new Date()],
              [i18n.t('yesterday')]: (function() {
                const date = sub(new Date(), { days: 1 })
                return [date, date]
              })(),
              [i18n.t('current_week')]: (function() {
                const date = new Date()
                if (isMonday(date)) {
                  return [date, date]
                }
                return [previousMonday(date), date]
              })(),
              [i18n.t('last_week')]: (function() {
                const date = new Date()
                if (isMonday(date)) {
                  return [sub(date, { days: 7 }), sub(date, { days: 1 })]
                }
                const dayOnPrevWeek = sub(date, { weeks: 1 })
                if (isMonday(dayOnPrevWeek)) {
                  return [
                    dayOnPrevWeek,
                    endOfWeek(dayOnPrevWeek, { weekStartsOn: 1 }),
                  ]
                }
                return [
                  previousMonday(dayOnPrevWeek),
                  endOfWeek(dayOnPrevWeek, { weekStartsOn: 1 }),
                ]
              })(),
              [i18n.t('current_month')]: (function() {
                const date = new Date()
                return [startOfMonth(date), date]
              })(),
              [i18n.t('last_month')]: (function() {
                const date = sub(new Date(), { months: 1 })
                return [startOfMonth(date), endOfMonth(date)]
              })(),
              [i18n.t('current_year')]: (function() {
                const date = new Date()
                return [startOfYear(date), date]
              })(),
              // 'Прошлый год': (function() {
              //   const date = sub(new Date(), { years: 1 })
              //   return [startOfYear(date), endOfYear(date)]
              // })(),
              [i18n.t('all_time')]: [new Date('2021-02-01'), new Date()],
            },
          },
          cb,
        )
        window
          .$('#daterange' + ref.id)
          .data('daterangepicker')
          .setStartDate(moment(ref.from).format('DD MMMM YYYY'))
        window
          .$('#daterange' + ref.id)
          .data('daterangepicker')
          .setEndDate(moment(ref.to).format('DD MMMM YYYY'))

        cb(moment(ref.dateValue.startDate), moment(ref.dateValue.endDate))
      })(),
    )
  },
  data: function() {
    window.$('#daterange' + this.id).remove()
    const dateValue = {
      startDate: this.from || new Date('2021/02/01'),
      endDate: this.to || new Date(),
    }
    return {
      dateValue,
    }
  },
  watch: {
    from() {
      this.dateValue.startDate = this.from
      const start = moment(this.from)
      const end = moment(this.to)
      window
        .$('#daterange' + this.id)
        .data('daterangepicker')
        .setStartDate(moment(this.from).format('DD MMMM YYYY'))
      window
        .$(`#daterange${this.id} span`)
        .html(start.format('DD MMMM YYYY') + ' — ' + end.format('DD MMMM YYYY'))
    },
    to() {
      this.dateValue.endDate = this.to
      const start = moment(this.from)
      const end = moment(this.to)
      window
        .$('#daterange' + this.id)
        .data('daterangepicker')
        .setEndDate(moment(this.to).format('DD MMMM YYYY'))
      window
        .$(`#daterange${this.id} span`)
        .html(start.format('DD MMMM YYYY') + ' — ' + end.format('DD MMMM YYYY'))
    },
    dateValue() {
      this.$emit('changeDate', this.dateValue)
    },
  },
})
</script>

<style lang="scss">
.daterangepicker {
  font-family: Nunito, sans-serif;
  .ranges {
    li {
      font-size: 14px;
      padding: 8px 16px;
    }
  }
  .calendar-table {
    th,
    td {
      height: 32px;
    }
    th {
      color: #718096;
    }
    td {
      &:not(.off) {
        color: #2d3748;
      }
    }
  }
  .drp-buttons {
    border-top: unset;
  }
  td.active,
  td.active:hover {
    background: #d9ebff;
    color: #2d3748;
  }
  td {
    &.start-date {
      border-top-left-radius: 100%;
      border-bottom-left-radius: 100%;
    }
    &.end-date {
      border-top-right-radius: 100%;
      border-bottom-right-radius: 100%;
    }
    &.start-date.end-date {
      border-radius: 100%;
    }
  }
}
.daterangepicker .daterange-label {
  color: #6f757b;
  font-family: Nunito;
  transform: translateY(-50%);
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  position: absolute;
  left: 11px;
  top: 0;
  background: #fff;
  padding: 0 8px;
  line-height: normal;
}
.daterangepicker .drp-buttons .btn {
  padding: 10px 16px;
  font-size: 14px;
  cursor: pointer;
}
.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  font-size: 14px;
  font-weight: 500;
  background: transparent;
  border: unset;
  color: #2d3748;
}
</style>
