import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "col-12 q-py-lg q-px-md",
  style: {"border-top":"1px solid var(--border-color)","background":"#FBFBFB"}
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12 q-mb-md" }
const _hoisted_4 = { class: "additional-text q-mr-sm" }
const _hoisted_5 = { class: "col-12 q-mb-md" }
const _hoisted_6 = { class: "additional-text q-mr-sm" }
const _hoisted_7 = { class: "col-6 q-mb-md" }
const _hoisted_8 = { class: "additional-text q-mr-sm" }
const _hoisted_9 = { class: "col-6 q-mb-md" }
const _hoisted_10 = { class: "additional-text q-mr-sm" }
const _hoisted_11 = { class: "col-6 q-mb-md" }
const _hoisted_12 = { class: "additional-text q-mr-sm" }
const _hoisted_13 = { class: "col-6 q-mb-md" }
const _hoisted_14 = { class: "additional-text q-mr-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Filters = _resolveComponent("Filters")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Filters, {
      onExport: _ctx.onExport,
      onReset: _ctx.resetFilters,
      onChange: _ctx.onChangeFilters,
      onChangeAll: _ctx.changeAll,
      "current-filters": _ctx.filters
    }, null, 8, ["onExport", "onReset", "onChange", "onChangeAll", "current-filters"]),
    _createElementVNode("div", null, [
      _createVNode(_component_q_table, {
        rows: _ctx.rows,
        columns: _ctx.columns,
        "row-key": "id",
        pagination: _ctx.pagination,
        "onUpdate:pagination": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination) = $event)),
        loading: _ctx.loading,
        onRequest: _ctx.onRequest,
        "binary-state-sort": "",
        flat: "",
        style: {"padding-top":"32px"},
        grid: _ctx.$q.screen.width <= 1199
      }, _createSlots({ _: 2 }, [
        (_ctx.$q.screen.width <= 1199)
          ? {
              name: "item",
              fn: _withCtx((props) => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('datetime')) + ":", 1),
                      _createTextVNode(" " + _toDisplayString(_ctx.format(props.row.createdAt)), 1)
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('link')) + ":", 1),
                      _createElementVNode("div", null, _toDisplayString(props.row.url), 1)
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('status')) + ":", 1),
                      _createElementVNode("div", null, _toDisplayString(props.row.status), 1)
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('event')) + ":", 1),
                      _createElementVNode("div", null, _toDisplayString(props.row.eventType), 1)
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('campaign')) + ":", 1),
                      _createElementVNode("div", null, _toDisplayString(props.row.campaignName), 1)
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('offer')) + ":", 1),
                      _createElementVNode("div", null, _toDisplayString(props.row.offerName), 1)
                    ])
                  ])
                ])
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["rows", "columns", "pagination", "loading", "onRequest", "grid"])
    ])
  ], 64))
}