import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "add-domain" }
const _hoisted_2 = { class: "textarea" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_q_input, {
          outlined: "",
          label: "Domain name",
          "stack-label": "",
          type: "textarea",
          modelValue: _ctx.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
          placeholder: "Enter the domain name you want to add",
          autofocus: "",
          error: !!_ctx.errors?.name,
          "error-message": _ctx.errors?.name
        }, null, 8, ["modelValue", "error", "error-message"])
      ])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_q_btn, {
        disable: _ctx.loading,
        loading: _ctx.loading,
        "no-caps": "",
        class: "q-btn__main-size text-weight-bold",
        "icon-right": "add",
        unelevated: "",
        style: {"width":"100%"},
        color: "primary",
        label: "Add domain",
        onClick: _ctx.addRedirectDomain
      }, null, 8, ["disable", "loading", "onClick"])
    ])
  ], 64))
}