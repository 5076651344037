
import { defineComponent } from 'vue'
import { formatValue } from '@/utils'
import { FormatMoney } from 'format-money-js'
import { Currency } from '@/enums/Currency'
import axios from 'axios'

export default defineComponent({
  name: 'HeaderPayment',
  data: function() {
    const moneyFormatter = new FormatMoney({
      decimals: 2,
      separator: ' ',
    })
    return {
      moneyFormatter,
      currencies: Currency,
      affiliate: { image: '' },
    }
  },
  async created(): Promise<void> {
    await this.onRequest()
  },
  methods: {
    getFormattedValue(value: number) {
      return this.moneyFormatter.from(
        Number(formatValue(Number(value), 2, true)),
        {},
      )
    },
    async onRequest() {
      this.affiliate = (await axios.get('/api/profile')).data
    },
  },
})
