import getDefaultData from '../../pages/payments/config/getDefaultData'
import { Errors, PayoutData, PayoutState } from '@/store/types'

const payoutLeadsModule = {
  state: getDefaultData(),
  mutations: {
    update(state: PayoutState, value: PayoutData): void {
      state.payoutLeads = { ...state.payoutLeads, ...value }
      state.errors = {}
    },
    reset(state: PayoutState): void {
      const { payoutLeads, errors } = getDefaultData()
      state.payoutLeads = payoutLeads
      state.errors = errors
    },
    setErrors(state: PayoutState, value: Errors): void {
      state.errors = value
    },
  },
  namespaced: true,
}

export default payoutLeadsModule
