import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "section-container background-gray q-py-lg" }
const _hoisted_2 = { class: "page-title" }
const _hoisted_3 = { class: "section-container q-pt-lg" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-md-9 col-12 q-mb-lg" }
const _hoisted_6 = { class: "ticket-messages" }
const _hoisted_7 = { class: "row justify-center q-my-md" }
const _hoisted_8 = { style: {"width":"100%"} }
const _hoisted_9 = { class: "chat-message__head" }
const _hoisted_10 = { class: "chat-message__date" }
const _hoisted_11 = { class: "chat-message__user" }
const _hoisted_12 = { class: "chat-message__name text-weight-bold" }
const _hoisted_13 = { class: "chat-message__content" }
const _hoisted_14 = { class: "q-mb-md" }
const _hoisted_15 = { class: "col-md-3 col-12 ticket-add-message" }
const _hoisted_16 = { class: "textarea q-mb-lg" }
const _hoisted_17 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_infinite_scroll = _resolveComponent("q-infinite-scroll")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_file = _resolveComponent("q-file")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-pb-lg flex column" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/tickets'))),
            class: "cursor-pointer"
          }, [
            _createVNode(_component_q_icon, {
              name: "chevron_left",
              class: "q-mr-md"
            }),
            _createElementVNode("span", null, _toDisplayString(_ctx.messages[0]?.title), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_q_infinite_scroll, {
                onLoad: _ctx.onLoad,
                reverse: "",
                id: "messages-scroll-area"
              }, _createSlots({
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_8, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (row, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass(["chat-message", { 'chat-message_from': row.affiliateEmail }]),
                        key: index
                      }, [
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.format(row.createdAt)), 1),
                          _createElementVNode("div", _hoisted_11, [
                            _createElementVNode("div", _hoisted_12, _toDisplayString(row.affiliateEmail || row.adminName || row.adminEmail), 1),
                            (row.affiliateEmail)
                              ? (_openBlock(), _createBlock(_component_q_icon, {
                                  key: 0,
                                  name: _ctx.profileImagePath,
                                  class: "chat-message__img"
                                }, null, 8, ["name"]))
                              : (_openBlock(), _createBlock(_component_q_icon, {
                                  key: 1,
                                  name: "account_circle",
                                  class: "chat-message__img"
                                }))
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_13, [
                          _createElementVNode("div", _hoisted_14, _toDisplayString(row.text), 1),
                          (row.file)
                            ? (_openBlock(), _createBlock(_component_q_btn, {
                                key: 0,
                                onClick: ($event: any) => (_ctx.downloadURI(row.file)),
                                color: "primary",
                                "no-caps": "",
                                class: "cursor-pointer",
                                "icon-right": _ctx.heroOutline24ArrowDownTray,
                                label: "Attachment",
                                unelevated: ""
                              }, null, 8, ["onClick", "icon-right"]))
                            : _createCommentVNode("", true)
                        ])
                      ], 2))
                    }), 128))
                  ])
                ]),
                _: 2
              }, [
                (_ctx.loading)
                  ? {
                      name: "loading",
                      fn: _withCtx(() => [
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode(_component_q_spinner, {
                            color: "primary",
                            name: "dots",
                            size: "40px"
                          })
                        ])
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1032, ["onLoad"])
            ])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_16, [
                _createVNode(_component_q_input, {
                  dense: "",
                  label: _ctx.$t('new_message'),
                  "stack-label": "",
                  outlined: "",
                  type: "textarea",
                  modelValue: _ctx.message,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.message) = $event)),
                  placeholder: _ctx.$t('enter_message_text'),
                  autofocus: ""
                }, null, 8, ["label", "modelValue", "placeholder"])
              ]),
              _createElementVNode("div", _hoisted_17, [
                _createVNode(_component_q_file, {
                  modelValue: _ctx.file,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.file) = $event)),
                  rounded: "",
                  outlined: "",
                  dense: "",
                  clearable: "",
                  "clear-icon": "close",
                  class: "attach-file full-width q-mb-sm",
                  label: _ctx.$t('attach_file'),
                  "stack-label": ""
                }, {
                  label: _withCtx(() => [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('attach_file')), 1),
                    _createVNode(_component_q_icon, {
                      name: "add",
                      class: "on-right",
                      size: "16px"
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue", "label"]),
                _createVNode(_component_q_btn, {
                  "no-caps": "",
                  unelevated: "",
                  rounded: "",
                  loading: _ctx.isSaving,
                  disable: _ctx.isSaving,
                  class: "q-btn__main-size text-weight-bold full-width",
                  label: _ctx.$t('send'),
                  color: "primary",
                  onClick: _ctx.sendMessage
                }, null, 8, ["loading", "disable", "label", "onClick"])
              ])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}