
import { defineComponent } from 'vue'
import {
  heroOutline24ChartBar,
  heroOutline24Bolt,
  heroOutline24DevicePhoneMobile,
  heroOutline24CreditCard,
  heroOutline24Squares2x2,
  heroOutline24Share,
  heroOutline24Wrench,
  heroOutline24Users,
  heroOutline24CircleStack,
  heroOutline24DocumentText,
  heroOutline24ChatBubbleLeftRight,
  heroOutline24ChevronDown,
} from 'quasar-extras-svg-icons/hero-icons-v2'
import HeaderPayment from '@/components/header/HeaderPayment.vue'
import HeaderProfile from '@/components/header/HeaderProfile.vue'
import ChangeLang from '@/components/header/ChangeLang.vue'
import HeaderNotifications from '@/components/header/HeaderNotifications.vue'

export default defineComponent({
  name: 'MobileMenu',
  components: { HeaderNotifications, ChangeLang, HeaderProfile, HeaderPayment },
  setup() {
    return {
      heroOutline24ChartBar,
      heroOutline24Bolt,
      heroOutline24DevicePhoneMobile,
      heroOutline24CreditCard,
      heroOutline24Squares2x2,
      heroOutline24Share,
      heroOutline24Wrench,
      heroOutline24Users,
      heroOutline24CircleStack,
      heroOutline24DocumentText,
      heroOutline24ChatBubbleLeftRight,
      heroOutline24ChevronDown,
    }
  },
})
