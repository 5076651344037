import { PostbackType } from '@/enums/PostbackType'
import i18n from '@/locales/i18n'

export function getPostbackTypes() {
  return [
    { label: i18n.t('approved_lead'), value: PostbackType.Approved },
    { label: i18n.t('reg'), value: PostbackType.Pending },
    { label: i18n.t('dep'), value: PostbackType.Hold },
    { label: i18n.t('revshare'), value: PostbackType.RevShare },
    { label: i18n.t('rejected'), value: PostbackType.Rejected },
    { label: i18n.t('trash'), value: PostbackType.Trash },
  ]
}
