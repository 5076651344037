
import { defineComponent } from 'vue'
import axios from 'axios'

export default defineComponent({
  name: 'AddDomain',
  props: ['refresh'],
  data: function() {
    return {
      name: null,
      errors: {},
      loading: false,
    }
  },
  methods: {
    addRedirectDomain() {
      this.loading = true
      axios
        .post('/api/redirect-domains', { name: this.name })
        .then(() => {
          this.$emit('refresh')
        })
        .catch(error => {
          this.errors = error.response.data.errors
        })
        .finally(() => {
          this.name = null
          this.loading = false
        })
    },
  },
})
