import { GroupByOptions } from '@/utils/request'
import i18n from '@/locales/i18n'

export function groupByOptions(): GroupByOptions {
  return [
    {
      id: 'date',
      name: i18n.t('days'),
      field: 'date',
    },
    {
      id: 'week',
      name: i18n.t('weeks'),
      field: 'week',
    },
    {
      id: 'offerId',
      name: i18n.t('offer'),
      field: 'offerName',
    },
    {
      id: 'campaignId',
      name: i18n.t('campaign'),
      field: 'campaignName',
    },
    {
      id: 'geoId',
      name: i18n.t('geo'),
      field: 'geoName',
    },
    {
      id: 'landingId',
      name: i18n.t('landing'),
      field: 'promoName',
    },
    {
      id: 'sid1',
      name: 'Sub 1',
      field: 'sid1',
    },
    {
      id: 'sid2',
      name: 'Sub 2',
      field: 'sid2',
    },
    {
      id: 'sid3',
      name: 'Sub 3',
      field: 'sid3',
    },
    {
      id: 'sid4',
      name: 'Sub 4',
      field: 'sid4',
    },
    {
      id: 'sid5',
      name: 'Sub 5',
      field: 'sid5',
    },
  ]
}
