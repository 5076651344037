
import { defineComponent } from 'vue'
import router from '@/router'
import { mapState } from 'vuex'
import { lookupGeos } from '@/utils/lookups'
import { getTableProperties, request, TableRequestProps } from '@/utils/request'
import { Offer, Reward } from '@/store/types'
import { getActualRewards, getAmountsByRewards } from '@/utils/rewards'
import axios from 'axios'
import { getData } from '@/utils/getData'
import i18n from '@/locales/i18n'
import { omit } from 'lodash'

export default defineComponent({
  name: 'MainLanding',
  computed: mapState('authModule', ['status']),
  async beforeMount() {
    const params = this.$router.currentRoute.value?.query
    if (
      params &&
      Object.keys(params)?.length &&
      !this.$storage.getStorageSync('referrerParams')
    ) {
      this.$storage.setStorageSync('referrerParams', {
        referrer: document.referrer || null,
        ...omit(params, 'lang'),
      })
    }
    let queryLang = params?.lang as any
    if (queryLang) {
      if (
        !['RU', 'UA', 'EN', 'PL', 'DE', 'FR', 'ES'].includes(
          queryLang.toUpperCase(),
        )
      ) {
        queryLang = 'EN'
      }
      await this.changeLang(queryLang.toLowerCase())
      return
    }
    if (!localStorage.getItem('lang_gmbt')) {
      const lang = (await axios.post('/api/auth/ip-lang')).data
      await this.changeLang(lang.toLowerCase())
    }
    this.langLabel =
      localStorage.getItem('lang_gmbt')?.toUpperCase() || this.langLabel
  },
  async created() {
    this.offers = (await axios.get('/api/offers/images')).data
    await this.onRequest({ pagination: this.pagination })
  },
  data: function() {
    return {
      ...getTableProperties('createdAt', 100),
      storageUrl: process.env.CDN_STORAGE_URL,
      langLabel: 'RU',
      filters: { isSuperOffer: true, geoId: null },
      geos: [],
      offers: [],
    }
  },
  watch: {
    async filters() {
      this.pagination.page = 1
      await this.onRequest({ pagination: this.pagination })
    },
  },
  methods: {
    async onRequest(props: TableRequestProps) {
      await request(props, this as any, '/api/offers/main-landing')
      if (this.rows.length < 9) {
        if (this.filters.isSuperOffer) {
          const otherOffers = await getData(
            {
              filters: { isSuperOffer: false, withTraffic: true } as any,
              limit: 40,
              offset: 0,
              orderBy: { createdAt: 'DESC' },
            },
            '/api/offers/main-landing',
          )
          if (otherOffers.length + this.rows.length >= 9) {
            while (this.rows.length < 9) {
              const randomOffer = (otherOffers as any)[
                Math.floor(Math.random() * otherOffers.length)
              ]
              const isAlreadyExist = this.rows.find(
                (r: Offer) => r.name === randomOffer.name,
              )
              if (!isAlreadyExist) {
                this.rows.push(randomOffer as never)
              }
            }
          }
        } else {
          const fullLength = 9 - this.rows.length
          for (let i = 0; i < fullLength; i++) {
            this.rows.push({
              ...(this.rows as any)[
                Math.floor(Math.random() * this.rows.length)
              ],
              image: (this.offers as any)[
                Math.floor(Math.random() * this.offers.length)
              ].image,
              hidden: true,
            } as never)
          }
        }
      }
    },
    async enterRedirect() {
      if (this.status.loggedIn) {
        await router.push('/offers')
      } else {
        await router.push('/login')
      }
    },
    async enterSignupRedirect() {
      if (this.status.loggedIn) {
        await router.push('/offers')
      } else {
        await router.push('/sign-up')
      }
    },
    async detail(id: number) {
      if (this.status.loggedIn) {
        if (id) {
          await router.push('/offers/' + id)
        } else {
          await router.push('/offers/')
        }
      } else {
        await router.push('/sign-up')
      }
    },
    onChangeFilters(field: string, value?: string) {
      if (!value) {
        this.filters = { isSuperOffer: true } as any
      } else {
        this.filters = { [field]: value } as any
      }
    },
    async getGeos(value: string, update: Function) {
      await lookupGeos(this, value, update, false, true, true)
    },
    getRewardAmounts(rewards: Reward[]): string {
      return getAmountsByRewards(rewards)
    },
    getRewards(rewards: Reward[]) {
      return getActualRewards(rewards)
    },
    getMindep(rewards: any) {
      const r = rewards.find((r: any) => r.minDepComment)
      return r?.minDepComment
    },
    getBaseline(rewards: any) {
      const r = rewards.find((r: any) => r.baselineComment)
      return r?.baselineComment
    },
    async changeLang(lang: string) {
      i18n.setLocale(lang.toLowerCase())
      this.langLabel = lang.toUpperCase()
      await axios.post('/api/auth/lang', { lang })
      localStorage.setItem('lang_gmbt', lang)
    },
  },
})
