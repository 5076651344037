import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "column" }
const _hoisted_2 = { class: "flex column" }
const _hoisted_3 = { class: "flex column" }
const _hoisted_4 = { class: "flex column" }
const _hoisted_5 = { class: "flex column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_q_input, {
        outlined: "",
        label: _ctx.$t('name'),
        "stack-label": "",
        class: "q-mb-sm",
        dense: "",
        placeholder: _ctx.$t('campaign_name'),
        "model-value": _ctx.campaign.name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = name => _ctx.update({ name })),
        error: !!_ctx.errors?.name,
        "error-message": _ctx.$t(_ctx.errors?.name)
      }, null, 8, ["label", "placeholder", "model-value", "error", "error-message"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_q_select, {
        "model-value": _ctx.campaign.offerId,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = offerId => _ctx.update({ offerId })),
        onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('select'))),
        outlined: "",
        class: "q-mb-sm",
        dense: "",
        label: _ctx.$t('offer'),
        "stack-label": "",
        options: _ctx.offers,
        onFilter: _ctx.getOffers,
        "option-value": "id",
        "option-label": "name",
        "emit-value": "",
        "map-options": "",
        "use-input": "",
        error: !!_ctx.errors?.offerId,
        "error-message": _ctx.$t(_ctx.errors?.offerId)
      }, null, 8, ["model-value", "label", "options", "onFilter", "error", "error-message"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_q_select, {
        "model-value": _ctx.campaign.trafficSourceId,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = trafficSourceId => _ctx.update({ trafficSourceId })),
        outlined: "",
        class: "q-mb-sm",
        dense: "",
        label: _ctx.$t('source'),
        "stack-label": "",
        disable: !_ctx.campaign.offerId,
        options: _ctx.trafficSources,
        onFilter: _ctx.getTrafficSources,
        "option-value": "id",
        "option-label": "name",
        "emit-value": "",
        "map-options": "",
        "use-input": "",
        error: !!_ctx.errors?.trafficSourceId,
        "error-message": _ctx.$t(_ctx.errors?.trafficSourceId)
      }, null, 8, ["model-value", "label", "disable", "options", "onFilter", "error", "error-message"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_q_select, {
        outlined: "",
        dense: "",
        label: _ctx.$t('geo'),
        "stack-label": "",
        disable: !_ctx.campaign.offerId,
        "model-value": _ctx.campaign.geoId,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = geoId => _ctx.update({ geoId })),
        onFilter: _ctx.getGeos,
        options: _ctx.geos,
        "option-value": "id",
        "option-label": "name",
        class: "q-mb-sm",
        "emit-value": "",
        "map-options": "",
        "use-input": "",
        "error-message": _ctx.$t(_ctx.errors?.geoId),
        error: !!_ctx.errors?.geoId
      }, null, 8, ["label", "disable", "model-value", "onFilter", "options", "error-message", "error"])
    ])
  ]))
}