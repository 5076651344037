
import { defineComponent } from 'vue'
import i18n from '../../locales/i18n'
import axios from 'axios'
import { heroOutline24ChevronDown } from 'quasar-extras-svg-icons/hero-icons-v2'
export default defineComponent({
  name: 'ChangeLang',
  data: () => {
    return {
      langLabel: 'RU',
    }
  },
  setup() {
    return {
      heroOutline24ChevronDown,
    }
  },
  async created(): Promise<void> {
    const lang = localStorage.getItem('lang_gmbt') || 'en'
    await this.changeLang(lang)
  },
  methods: {
    async changeLang(lang: string) {
      this.langLabel = lang.toUpperCase()
      i18n.setLocale(lang)
      await axios.post('/api/auth/lang', { lang })
      this.$emit('changeLangForce')
      localStorage.setItem('lang_gmbt', lang)
    },
  },
})
