
import { defineComponent } from 'vue'
import { OfferRate, Reward } from '@/store/types'
import { RewardType } from '@/enums/RewardType'
import { Currency } from '@/enums/Currency'
import i18n from '@/locales/i18n'

const columns = [
  {
    name: 'geo',
    label: i18n.t('geo'),
    field: 'geo',
    align: 'left',
  },
  {
    name: 'source',
    label: i18n.t('source'),
    field: 'source',
    align: 'left',
  },
  {
    name: 'condition',
    label: i18n.t('condition'),
    field: 'condition',
    align: 'left',
  },
  {
    name: 'payout',
    label: i18n.t('payout'),
    field: 'payout',
    align: 'left',
  },
]

export default defineComponent({
  name: 'OfferRates',
  props: ['rates'],
  data() {
    return {
      rows: [] as OfferRate[],
      rewardTypes: RewardType,
      currencies: Currency,
    }
  },
  setup() {
    columns
  },
  computed: {
    columns(): any {
      return columns
    },
    initialPagination() {
      return {
        sortBy: 'desc',
        descending: false,
        page: 1,
        rowsPerPage: 3000,
      }
    },
  },
  created() {
    this.prepareData()
  },
  methods: {
    prepareData() {
      const newArr: OfferRate[] = []

      this.rates.forEach((reward: Reward) => {
        reward.geos.forEach(geo => {
          newArr.push({
            geo: geo.isoCode,
            geoName: geo.name,
            reward,
          })
        })
      })

      this.rows = newArr
    },
    getGeoNames(reward: Reward) {
      return reward.geos.map(geo => geo.name).join(', ')
    },
  },
})
